import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useScrollTrigger } from "@mui/material";
import {
  base_url,
  checkProjects,
  verifyOtp,
  verifyOtp_common,
} from "../utils/utils";
import axios from "axios";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MuiHeaderDialogue from "./MuiHeaderDialogue";
import NavItem from "rsuite/esm/Nav/NavItem";

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 6 : 0,
  });
}

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const project = sessionStorage.getItem("projectid");
  const tokenData = sessionStorage.getItem("token");
  const [accessProjects, setAccessProjects] = React.useState([]);
  const [isLoading, setisLoading] = React.useState(true);
  const [isErr, setIsErr] = React.useState(false);
  //menu project
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  //dialogue
  const [open3, setOpen3] = React.useState(false);
  const [fullWidth3, setFullWidth3] = React.useState(true);
  const [maxWidth3, setMaxWidth3] = React.useState("sm");
  const [switchProj, setSwitchProj] = React.useState({});

  const handleClickOpen3 = (item) => {
    setSwitchProj(item);
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  // menu
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const token = sessionStorage.getItem("token");
  const getProjectsData = (dataToken) => {
    axios
      .get(checkProjects, dataToken)
      .then((res) => {
        setAccessProjects(res.data.data);
        setisLoading(false);
        console.log(res.data.data, "checkprojres");
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setisLoading(false);
      });
  };

  const switchProjects = (item) => {
    console.log(item, "tesingonit");
    let bodyData = {
      mobile_number: item.mobile_number,
      project_id: item.project_id,
      otp: item.otp,
      // email: item.email_id,
    };
    console.log(bodyData, item, "checit");
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    let api = base_url + verifyOtp;
    axios
      .post(api, bodyData, dataToken)
      .then((res) => {
        console.log(res.data, "finaltoken");

        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("admin", res.data.admin);
        sessionStorage.setItem("fullName", res.data.full_name);
        sessionStorage.setItem("projectid", res.data.project_id);

        navigate("/");
        // window.location.reload();
      })
      .catch((err) => console.log(err));
  };
  React.useEffect(() => {
    let api = checkProjects;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    getProjectsData(dataToken);
  }, []);
  return isLoading ? (
    <>...loading</>
  ) : (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <ElevationScroll>
          <AppBar
            position="fixed"
            sx={{
              backgroundColor:
                project == "286"
                  ? "#131B2F"
                  : project == "326"
                  ? "#131B2F"
                  : "#272950",
              // boxShadow:
              //   project == "286" ? "0px 4px 6px rgba(0, 0, 0, 0.3)" : "none",
            }}
          >
            <Toolbar>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100vw",
                  padding: "0 75px 0 82px",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={
                      project == "286" || project == "326"
                        ? "/nammaYatri_logo.svg"
                        : "./growthfalcons.png"
                    }
                    alt="taskmo"
                    width={
                      project == "286" || (project == "326") == "286"
                        ? 100
                        : 150
                    }
                    height={50}
                  />
                </div>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {accessProjects.length > 0 && (
                    <div>
                      <Button variant="contained" onClick={handleClick2}>
                        switch Projects
                      </Button>
                    </div>
                  )}
                  <div>
                    {" "}
                    <Avatar
                      alt="user"
                      onClick={handleClick}
                      style={{ cursor: "pointer", textTransform: "capitalize" }}
                    >
                      {sessionStorage?.getItem("fullName") &&
                        sessionStorage?.getItem("fullName")[0]}
                    </Avatar>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "#f9f9f9",
                        textTransform: "capitalize",
                      }}
                    >
                      {sessionStorage?.getItem("fullName") ?? ""}
                    </div>
                    <div
                      style={{
                        fontSize: "10px",
                        color: "#f9f9f9",
                        textTransform: "capitalize",
                      }}
                    >
                      {sessionStorage?.getItem("admin")}
                    </div>
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Toolbar></Toolbar>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            // vertical: "top",
            // horizontal: "left",
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("admin");
              sessionStorage.removeItem("fullName");
              sessionStorage.removeItem("projectid");
              navigate("/");
            }}
          >
            Logout
          </MenuItem>
        </Menu>

        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl2}
          open={open2}
          onClose={handleClose2}
          anchorOrigin={{
            // vertical: "top",
            // horizontal: "left",
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {accessProjects.map((item) => (
            <MenuItem
              sx={{ fontSize: "12px", width: "fit-content" }}
              onClick={() => {
                handleClickOpen3(item);

                switchProjects(item);

                handleClose2();
                // handleClose();
                // sessionStorage.removeItem('token');
                // sessionStorage.removeItem('admin');
                // sessionStorage.removeItem('fullName');
                // sessionStorage.removeItem('projectid');
                // navigate('/');
              }}
            >
              {` ${item.project_title}`}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {/* <MuiHeaderDialogue
        open={open3}
        setOpen={setOpen3}
        fullWidth={fullWidth3}
        maxWidth={maxWidth3}
        handleClickOpen={handleClickOpen3}
        handleClose={handleClose3}
        switchProj={switchProj}
      /> */}
    </div>
  );
};

export default Header;
