import React, { useEffect, useState } from "react";
import { upload_baseurl, image_display, farming_url } from "../utils/utils";
import axios from "axios";
import {
  sleepwell_reject_lead,
  sleepwell_table_data,
} from "../utils/sleepwellUtils";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Sleepdetails from "./sleepdetails";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink, CSVDownload } from "react-csv";
import "react-data-table-component-extensions/dist/index.css";
// import ImageModal from "./ImageModal";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
      border: "none",
      backgroundColor: "#24304B",
    },
  },
  headCells: {
    style: {
      padding: 15,
      color: "#FFFFFF",
      backgroundColor: "#141D33",
      justifyContent: "center",
    },
  },
  cells: {
    style: {
      backgroundColor: "#24304B",
      color: "#ffff",
      justifyContent: "center",
      fill: "white",
    },
  },
  table: {
    style: {
      minHeight: "400px",
    },
  },
  pagination: {
    style: {
      backgroundColor: "#24304B",
      color: "#ffff",
    },
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#24304B",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 4,
};
function SleepTable() {
  const [sleeptable, setSleeptable] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [maxWidth, setMaxWidth] = useState("xl");
  const [dataEach, setDataEach] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [flag, setFlag] = useState(false);
  const open = Boolean(anchorEl);
  const [openM, setOpenM] = React.useState(false);
  const [rejectClicked, setRejectClicked] = useState(false);

  const handleCloseR = () => setRejectClicked(false);
  const [remark, setRemark] = useState("");

  const handleOpenM = () => {
    setOpenM(true);
    // setSelectedData(d?.main_lead_id);
  };
  const handleOpenR = (d) => {
    setRejectClicked(true);
    setSelectedData(d?.main_lead_id);
    console.log(d, "data");
  };
  const handleCloseM = () => setOpenM(false);

  const [openSnack, setOpenSnack] = React.useState(false);
  const [statusSnack, setStatusSnack] = React.useState("success");

  const handleClickSnack = (val) => {
    setStatusSnack(val);
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClickOpenView = (d) => {
    console.log(d, "checkd");
    setDataEach(d);
    setOpen1(true);
  };

  const tokenData = sessionStorage.getItem("token");
  const dataToken = {
    headers: {
      "x-access-token": `${tokenData}`,
    },
  };
  const tabledata = () => {
    const link = upload_baseurl + sleepwell_table_data;
    axios
      .get(link, dataToken)
      .then((res) => {
        setSleeptable(res?.data?.project_data);
      })
      .catch((err) => console.log(err));
  };
  // const sendStatusData = () => {
  //   let apiData = farming_url + image_display;
  //   let body = {
  //     lead_id: postdata,
  //     distance: "100",
  //   };
  //   axios
  //     .post(apiData, body)
  //     .then((res) => {
  //       if (res.data.error) {
  //         console.log("error");
  //       } else {
  //         console.log(res.data.data, "post");
  //         setImgdata(res.data.data);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  useEffect(() => {
    tabledata();
  }, [flag]);

  const columns = [
    {
      name: "Main Lead Id",
      selector: (row) => row.main_lead_id,
      width: "150px",
      sortable: true,
      cell: (d) => (
        <div style={{ cursor: "pointer" }} className="text-info">
          <u>
            <span style={{ padding: 5, fontSize: 12 }}>{d.main_lead_id}</span>
          </u>
        </div>
      ),
    },
    {
      name: "Date",
      selector: (row) => row.main_lead_id,
      width: "250px",
      sortable: true,
      cell: (d) => (
        <div style={{ cursor: "pointer" }} className="text-info">
          <u>
            <span style={{ padding: 5, fontSize: 12 }}>{d.date}</span>
          </u>
        </div>
      ),
    },

    {
      name: "Merchant Name",
      selector: (row) => row.main_lead_id,
      width: "150px",
      sortable: true,
      cell: (d) => <span style={{ fontSize: 10 }}>{d?.merchant_name}</span>,
    },

    // {
    //   name: "Merchant Number",
    //   selector: (row) => row.main_lead_id,
    //   width: "180px",
    //   sortable: true,
    //   cell: (d) => <span style={{ fontSize: 15 }}> {d?.merchant_number}</span>,
    // },
    {
      name: "Shop Name",
      selector: (row) => row.main_lead_id,
      width: "250px",
      sortable: true,
      cell: (d) => (
        <div style={{ cursor: "pointer" }} className="text-info">
          <u>
            <span style={{ padding: 5, fontSize: 12 }}>{d.shop_name}</span>
          </u>
        </div>
      ),
    },

    {
      name: "City",
      selector: (row) => row.main_lead_id,
      width: "150px",
      sortable: true,
      cell: (d) => <p>{d?.city}</p>,
    },
    {
      name: "State",
      selector: (row) => row.main_lead_id,
      width: "150px",
      sortable: true,
      cell: (d) => <p>{d?.state}</p>,
    },
    {
      name: "QC",
      selector: (row) => row.final_qc,
      width: "150px",
      sortable: true,
      cell: (d) => (
        <div style={{ textTransform: "capitalize" }}>{d?.final_qc}</div>
      ),
    },

    {
      name: "View Details",
      cell: (d) => (
        <div>
          <FileOpenIcon onClick={() => handleClickOpenView(d)} />
        </div>
      ),
    },
    {
      name: "Edited By",
      selector: (row) => row.merchant_name,
      width: "150px",
      sortable: true,
      cell: (d) =>
        !d.full_name ? (
          <>
            <p>---</p>{" "}
          </>
        ) : (
          <span>{d?.full_name}</span>
        ),
    },
    // {
    //   name: "View",
    //   selector: (row) => row.merchant_name,
    //   width: "150px",
    //   sortable: true,
    //   cell: (d) => (
    //     <span
    //       style={{ cursor: "pointer" }}
    //       className="text-info"
    //       onClick={() => {
    //         sendStatusData();
    //         handleImgOpen(d);
    //       }}
    //     >
    //       Image View
    //     </span>
    //   ),
    // },
    {
      name: "Action",
      selector: (row) => row.merchant_name,
      width: "150px",
      sortable: true,
      cell: (d) =>
        d.qc_status == 0 ? (
          <>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleOpenR(d);
              }}
            >
              Reject
            </Button>
          </>
        ) : (
          <>
            <p>----</p>{" "}
          </>
        ),
    },
  ];
  let tableData = {
    data: sleeptable,
    columns,
  };
  const onSumbit = (e) => {
    const link = upload_baseurl + sleepwell_reject_lead;
    const reject_lead = {
      qc_remark: remark?.length > 0 ? remark : "reject",
      main_lead_id: selectedData,
    };
    axios
      .patch(link, reject_lead, dataToken)
      .then((res) => {
        if (res.data.error) {
          handleClickSnack("error");
        } else {
          handleClickSnack("success");
          setFlag(!flag);
          handleCloseM();
        }
      })
      .catch((err) => {
        handleClickSnack("error");
      });
    console.log(e);
  };

  return (
    <>
      <Box>
        <Card
          variant="outlined"
          style={{
            backgroundColor: "#24304B",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              visibility: sleeptable?.length == 0 || sleeptable == undefined,
              // sleeptable?.length == 0 ? "hidden" : "show",
            }}
          >
            <CSVLink filename="Report.csv" data={sleeptable ?? []}>
              <IconButton variant="text">
                <DownloadIcon style={{ color: "white" }} />
              </IconButton>
            </CSVLink>
          </div>
          <CardContent style={{ padding: "0" }}>
            <DataTableExtensions
              {...tableData}
              export={false}
              print={false}
              exportHeaders
              filterPlaceholder={`Search`}
              className="filter_text"
            >
              <DataTable
                columns={columns}
                data={sleeptable}
                customStyles={customStyles}
                highlightOnHover={true}
                paginationRowsPerPageOptions={[10, 30, 50, 100]}
                fixedHeader={true}
                fixedHeaderScrollHeight={"430px"}
                pagination
              />
            </DataTableExtensions>
            <Dialog
              fullScreen
              maxWidth={maxWidth}
              open={open1}
              onClose={handleClose1}
            >
              <DialogTitle
                style={{
                  // boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                  backgroundColor: "#24304B",
                  color: "white",
                  width: "100%",
                  // borderRadius: "8px",
                  //   color: "white",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>View Details</div>
                  <div onClick={handleClose1}>
                    <HighlightOffOutlinedIcon style={{ cursor: "pointer" }} />
                  </div>
                </div>
              </DialogTitle>

              <DialogContent
                style={{
                  // boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                  backgroundColor: "#24304B",
                  // borderRadius: "8px",
                  //   color: "white",
                }}
              >
                <Sleepdetails
                  data={dataEach}
                  closeMainModal={handleClose1}
                  setFlag={setFlag}
                  flag={flag}
                  handleClose1={handleClose1}
                  handleClickSnack={handleClickSnack}
                />
              </DialogContent>
            </Dialog>

            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={openSnack}
              autoHideDuration={2000}
              onClose={handleCloseSnack}
            >
              <Alert
                onClose={handleCloseSnack}
                severity={statusSnack}
                sx={{ width: "100%" }}
              >
                {statusSnack === "success"
                  ? "Success!"
                  : "Remark already Exists"}
              </Alert>
            </Snackbar>
          </CardContent>
        </Card>

        <Modal
          open={openM}
          onClose={handleCloseM}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color="#ffff"
              // textAlign="center"
            >
              Are you sure ?
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                sx={{ mx: 1, mt: 1 }}
                onClick={(e) => {
                  onSumbit(e);
                  handleCloseM();
                }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                sx={{ mx: 1, mt: 1 }}
                onClick={handleCloseM}
              >
                No
              </Button>
            </div>
          </Box>
        </Modal>
      </Box>
      <Modal
        open={rejectClicked}
        onClose={handleCloseR}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <div>
            <label
              style={{
                color: "#ffff ",
                fontWeight: 600,
                fontSize: 15,
                marginLeft: 10,
              }}
            >
              Remark
            </label>
            <textarea
              className="form-control"
              placeholder="Enter Remark"
              id="des-info-description-input"
              rows="5"
              style={{
                width: 500,
                margin: 10,
                padding: 10,
                borderRadius: 8,
                backgroundColor: "#e3ecff",
                border: "none",
                color: "#00000",
                outline: "none",
              }}
              onChange={(e) => {
                setRemark(e.target.value);
              }}
            ></textarea>
          </div>
          <Button
            variant="contained"
            sx={{
              mr: 3,
              mt: 2,
              width: 100,
            }}
            onClick={() => {
              setRejectClicked(false);
              handleOpenM(true);
            }}
            color="success"
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default SleepTable;
