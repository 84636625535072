import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, TextField } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { baseUrl, proposal } from "../utils/utils";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CheckData = () => {
  document.body.style.backgroundColor = "#151038";
  const [dataEnt, setDataEnt] = React.useState({});

  const [open, setOpen] = React.useState(false);
  const [stat, setStat] = React.useState("success");
  const [loading, setIsLoading] = React.useState(false);
  const [err, setIsErr] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getDataApi();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataEnt({ ...dataEnt, [name]: value });
  };

  const getDataApi = () => {
    let apidata = baseUrl + proposal;
    setIsLoading(true);
    axios
      .post(apidata, dataEnt)
      .then((res) => {
        if (res.data.error) {
          setStat("error");
        } else {
          setStat("success");
        }
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        handleClick();
        setIsLoading(false);
      });
  };
  return loading ? (
    <>
      {" "}
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    </>
  ) : (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      style={{ height: "100vh" }}
    >
      <Grid item>
        <Box sx={{ minWidth: 300 }}>
          <Card
            variant="outlined"
            style={{ background: "#272950", borderRadius: "10px" }}
          >
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  direction="column"
                  spacing={3}
                  style={{ color: "#fff" }}
                >
                  <Grid item>
                    <Grid container direction="column" gap="10px">
                      <Grid item>
                        <Grid container>
                          <Grid item>Email</Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <TextField
                          onChange={handleChange}
                          name="email_id"
                          required
                          fullWidth
                          type="email"
                          size={"small"}
                          inputProps={{ style: { color: "white" } }}
                          sx={{
                            "& fieldset": { border: ".5px solid #82829A" },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container direction="column" gap="10px">
                      <Grid item>
                        <Grid container>
                          <Grid item>Mobile Number</Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <TextField
                          name="merchant_number"
                          onChange={handleChange}
                          fullWidth
                          required
                          inputProps={{ style: { color: "white" } }}
                          type="number"
                          size={"small"}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 10);
                          }}
                          sx={{
                            "& fieldset": { border: ".5px solid #82829A" },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Button
                          fullWidth
                          variant="contained"
                          type="submit"
                          style={{
                            textTransform: "capitalize",
                            cursor: "pointer",
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        anchorPosition={{ left: 10, top: 300 }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        {stat == "success" ? (
          <Alert
            onClose={handleClose}
            severity={"success"}
            sx={{ width: "100%" }}
          >
            Success
          </Alert>
        ) : (
          <Alert
            onClose={handleClose}
            severity={"error"}
            sx={{ width: "100%" }}
          >
            Data Not Found
          </Alert>
        )}
      </Snackbar>
    </Grid>
  );
};

export default CheckData;
