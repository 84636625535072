import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MapBang from "./MapBang";
import { Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MapFalcon from "./MapFalcon";
const MapCard = ({
  data,
  dataGrow,
  appdatafrom = "nothing",
  startDate,
  endDate,
  mapCity = "",
}) => {
  console.log(data, "testingdata");
  const [singleAreaData, setSingleAreaData] = React.useState({});
  console.log(singleAreaData,'ddd')
  console.log(singleAreaData?.interested ?? 0,'ddd')
  console.log(  dataGrow ,'dg'    )
  console.log(appdatafrom, "testingarea", singleAreaData);
console.log(data,'sss')
  return (
    <Card
      style={{
        boxShadow: "0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
        backgroundColor: appdatafrom == "nothing" ? "#24314B" : "#33355F",
        borderRadius: "16px",
        height: "680px",
        padding: "10px",
      }}
    >
      <CardContent>
        <Grid container direction={"row"}>
          <Grid item xs={4}>
            {appdatafrom == "falcon" ? (
              <Grid
                container
                direction="column"
                spacing={4}
                justifyContent={"center"}
                alignItems={"center"}
                style={{ marginTop: "50px" }}
              >
                <Grid item>
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "22px",
                      fontWeight: 600,
                    }}
                  >
                    {singleAreaData?.name ?? "-"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Card
                            style={{
                              background: "#212E46",
                              color: "#fff",
                              width: "200px",
                              boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                            }}
                          >
                            <CardContent>
                              <Grid
                                container
                                direction="column"
                                alignItems={"center"}
                              >
                                <Grid
                                  item
                                  style={{ fontSize: "24px", fontWeight: 600 }}
                                >
                                  {singleAreaData?.total_onboarded ?? 0}
                                </Grid>
                                <Grid item style={{ fontSize: "12px" }}>
                                  Surveys
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item>
                          <Card
                            style={{
                              background: "#212E46",
                              color: "#fff",
                              width: "200px",
                              boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                            }}
                          >
                            <CardContent>
                              <Grid
                                container
                                direction="column"
                                alignItems={"center"}
                              >
                                <Grid
                                  item
                                  style={{ fontSize: "24px", fontWeight: 600 }}
                                >
                                  {singleAreaData?.interested ?? 0}
                                </Grid>
                                <Grid item style={{ fontSize: "12px" }}>
                                  Interested
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Card
                            style={{
                              background: "#212E46",
                              color: "#fff",
                              width: "200px",
                              boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                            }}
                          >
                            <CardContent>
                              <Grid
                                container
                                direction="column"
                                alignItems={"center"}
                              >
                                <Grid
                                  item
                                  style={{ fontSize: "24px", fontWeight: 600 }}
                                >
                                  {singleAreaData?.grow?.proposal ?? 0}
                                </Grid>
                                <Grid item style={{ fontSize: "12px" }}>
                                  Proposal Sent
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item>
                          <Card
                            style={{
                              background: "#212E46",
                              color: "#fff",
                              width: "200px",
                              boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                            }}
                          >
                            <CardContent>
                              <Grid
                                container
                                direction="column"
                                alignItems={"center"}
                              >
                                <Grid
                                  item
                                  style={{ fontSize: "24px", fontWeight: 600 }}
                                >
                                  {singleAreaData?.grow?.live ?? 0}
                                </Grid>
                                <Grid item style={{ fontSize: "12px" }}>
                                  Restaurant Live
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                direction="column"
                spacing={4}
                justifyContent={"center"}
                alignItems={"center"}
                style={{ marginTop: "50px" }}
              >
                <Grid item>
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "22px",
                      fontWeight: 600,
                    }}
                  >
                    {singleAreaData?.name ?? "-"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Card
                    style={{
                      background: "#212E46",
                      color: "#fff",
                      width: "200px",
                      boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                    }}
                  >
                    <CardContent>
                      <Grid container direction="column" alignItems={"center"}>
                        <Grid
                          item
                          style={{ fontSize: "24px", fontWeight: 600 }}
                        >
                          {singleAreaData?.total_onboarded ?? 0}
                        </Grid>
                        <Grid item style={{ fontSize: "12px" }}>
                          OnBoarded
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  {appdatafrom == "nothing" ? (
                    <Card
                      style={{
                        background: "#212E46",
                        color: "#fff",
                        width: "200px",
                        boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          direction="column"
                          alignItems={"center"}
                        >
                          <Grid
                            item
                            style={{ fontSize: "24px", fontWeight: 600 }}
                          >
                            {singleAreaData?.one_ride_count ?? 0}
                          </Grid>
                          <Grid item style={{ fontSize: "12px" }}>
                            One Ride Count
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item>
                  {appdatafrom == "nothing" ? (
                    <Card
                      style={{
                        background: "#212E46",
                        color: "#fff",
                        width: "200px",
                        boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          direction="column"
                          alignItems={"center"}
                        >
                          <Grid
                            item
                            style={{ fontSize: "24px", fontWeight: 600 }}
                          >
                            {singleAreaData?.three_ride_count ?? 0}
                        
                          </Grid>
                          <Grid item style={{ fontSize: "12px" }}>
                            Three Ride Count
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={8}>
            {appdatafrom == "falcon" ? (
              <MapFalcon
                data2={data}
                dataGrow={dataGrow}
                mapCity={mapCity}
                singleAreaData={singleAreaData}
                setSingleAreaData={setSingleAreaData}
              />
            ) : (
              <MapBang
                data2={data}
                singleAreaData={singleAreaData}
                setSingleAreaData={setSingleAreaData}
                startDate={startDate}
                endDate={endDate}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MapCard;
