import React, { useEffect, useState } from "react";
import Header from "../components2/Header";
import { Box, MenuItem, TextField, colors } from "@mui/material";
import Grid from "@mui/material/Grid";
import "./N_Stroke.css";
import LineChart from "../components2/ChartLine";
import PieCard from "../components2/PieCard";
import MapDATA from "../components2/MapData";
import DTable from "../components2/Table";
import das from "../components2/SVG/noun-verified.svg";
import eye from "../components2/SVG/Eye.svg";
import vector from "../components2/SVG/Vector.svg";
import group from "../components2/SVG/Group.svg";
import Bengaluru from "../components2/map/bengaluru";
import {
  nStore_base_url,
  card_data,
  map_data,
  nstore_city_list,
  nstore_table_data,
  nstore_overall_chart,
  nstore_pie_chart,
} from "../utils/NstoreUtils";
import axios from "axios";
import Pune from "../components2/map/pune";
import Chennai from "../components2/map/Chennai";

function N_Stoke() {
  document.body.style.backgroundColor = "#15192C";
  const MontlyData = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "05",
      label: "May",
    },
    {
      value: "06",
      label: "June",
    },

    {
      value: "07",
      label: "July",
    },
    {
      value: "08",
      label: "August",
    },
    {
      value: "09",
      label: "September",
    },
    {
      value: "10",
      label: "October",
    },
    {
      value: "11",
      label: "November",
    },
    {
      value: "12",
      label: "December",
    },
  ];

  //  const currentMonth = new Date().getMonth();
  const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, "0");
  const [monthWiseData, setMonthWiseData] = useState("all");
  const [cityList, setlist] = useState([]);
  const [mapCity, setMapCity] = React.useState("all");
  const token = sessionStorage.getItem("token");
  const [cardData, setCardData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [singleAreaData, setSingleAreaData] = React.useState({});
  const [flag, setFlag] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [pgraphData, setPGraphData] = useState([]);
  // console.log(currentMonth, monthWiseData, "mon");
  const getCardData = () => {
    let apiData = nStore_base_url + card_data;
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        month: monthWiseData,
        year: "2023",
        city: mapCity,
      },
    };
    axios
      .get(apiData, dataToken)
      .then((res) => {
        setCardData(res?.data?.card_data[0]);
      })
      .catch((err) => console.log(err, "err"));
  };

  const getaMapData = () => {
    let apiData = nStore_base_url + map_data;
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        month: monthWiseData,
        year: "2023",
        city: mapCity,
      },
    };
    axios
      .get(apiData, dataToken)
      .then((res) => {
        setMapData(res?.data?.map_data);
      })
      .catch((err) => console.log(err, "err"));
  };
  const getList = () => {
    let apiData = nStore_base_url + nstore_city_list;
    axios
      .get(apiData)
      .then((res) => {
        let ot = res?.data?.cities;
        let temp = [...ot, { city: "all" }];
        let temp2 = temp.map((item) => ({
          label: item.city,
          value: item.city,
        }));
        setlist(temp2);
      })
      .catch((err) => console.log(err, "err"));
  };

  const getTableData = () => {
    let apiData = nStore_base_url + nstore_table_data;
    const dataToken = {
      params: {
        month: monthWiseData,
        year: "2023",
        city: mapCity,
      },
    };
    axios
      .get(apiData, dataToken)
      .then((res) => {
        setTableData(res?.data?.leads);
      })
      .catch((err) => console.log(err, "err"));
  };

  const getchartDatas = () => {
    let apiData = nStore_base_url + nstore_overall_chart;
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        city: mapCity,
      },
    };
    axios
      .get(apiData, dataToken)
      .then((res) => {
        setGraphData(res?.data);
      })
      .catch((err) => console.log(err, "err"));
  };
  const getGraphs = () => {
    let apiData = nStore_base_url + nstore_pie_chart;
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        city: mapCity,
      },
    };
    axios
      .get(apiData, dataToken)
      .then((res) => {
        setPGraphData(res?.data);
      })
      .catch((err) => console.log(err, "err"));
  };

  useEffect(() => {
    getCardData();
    getaMapData();
    getList();
    getTableData();
    getchartDatas();
    getGraphs();
  }, [monthWiseData, mapCity, flag]);
  // console.log(cardData, monthWiseData, cityList, "card");
  // console.log(mapData, "card1");
  // console.log(tableData, currentMonth, flag, "card7");

  //for reload
  useEffect(() => {
    if (sessionStorage.getItem("projectid") !== "296") {
      window.location.reload();
    }
  }, []);
  return (
    <div classhname="App">
      <Header />
      <br />
      <div style={{ padding: "20px 92px" }}>
        <div>
          {
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                color: "white",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ paddingLeft: "10px", color: "white" }}>
                      Month Filter
                    </div>
                    <TextField
                      style={{
                        // backgroundColor: "#f0f0f0",

                        backgroundColor: "#24304B",

                        fontFamily: "Nunito",
                        border: "none",
                        boxShadow: "none",

                        borderRadius: "4px",
                      }}
                      color="primary"
                      id="outlined-select-currency"
                      select
                      value={monthWiseData}
                      size="small"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      onChange={(e) => setMonthWiseData(e.target.value)}
                    >
                      {MontlyData.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "10px",
                      color: "white",
                    }}
                  >
                    City Filter
                    <TextField
                      style={{
                        // backgroundColor: "#f0f0f0",
                        backgroundColor: "#24304B",
                        fontFamily: "Nunito",
                        border: "none",
                        boxShadow: "none",
                      }}
                      color="primary"
                      id="outlined-select-currency"
                      select
                      value={mapCity}
                      size="small"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      onChange={(e) => {
                        setMapCity(e.target.value);
                      }}
                    >
                      {cityList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </div>
            </Box>
          }
          <br />
        </div>
        <div direction="col">
          <h3 style={{ color: "#fff" }}>Taskmo</h3>
          <br />
          <Grid container direction="column">
            <Grid item xs={12}>
              <Grid container direction="row" spacing={3}>
                <Grid item lg={6} md={6} xs={12}>
                  <MapDATA
                    hname="Registred"
                    Cardvalue={cardData.registered}
                    percentage={cardData.registered_today}
                    svg={eye}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <MapDATA
                    hname="Catolog Done"
                    Cardvalue={cardData.catalog_done}
                    percentage={cardData.catalog_done_today}
                    svg={vector}
                  />
                </Grid>
                {/* <Grid item lg={4} md={6} xs={12}>
                  <MapDATA
                    hname="Catolog Verified"
                    Cardvalue={cardData.catalog_verified}
                    percentage={cardData.catalog_verified_today}
                    svg={group}
                  />
                </Grid> */}
              </Grid>

              <h3 style={{ color: "#fff", marginTop: "50px" }}>
                nStore Conversion
              </h3>
              <br />

              <Grid container direction="row" spacing={3}>
                <Grid item lg={3} md={6} xs={12}>
                  <MapDATA
                    hname="KYC Passed"
                    Cardvalue={cardData.kyc_passed}
                    percentage={cardData.kyc_passed_today}
                    svg={das}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                  <MapDATA
                    hname="Catologing passed"
                    Cardvalue={cardData.catalog_passed}
                    percentage={cardData.catalog_passed_today}
                    svg={das}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                  <MapDATA
                    hname="Training Passed"
                    Cardvalue={cardData.training_passed}
                    percentage={cardData.training_passed_today}
                    svg={das}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                  <MapDATA
                    hname="T&C Passed"
                    Cardvalue={cardData.terms_passed}
                    percentage={cardData.terms_passed_today}
                    svg={das}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <h3 style={{ color: "#fff" }}>Category Data</h3>
          <br />
          <Grid item xs={12}>
            <Grid
              container
              spacing={4}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item lg={6} xs={12}>
                <LineChart graphData={graphData} />
              </Grid>

              <Grid item lg={6} xs={12}>
                <PieCard pgraphData={pgraphData} />
              </Grid>
            </Grid>
          </Grid>
        </div>
        {/* {console.log(mapCity !== "all", "li")}
        {mapCity !== "all" ? (
          <>
            <h3 style={{ color: "#fff", marginTop: "50px" }}>Map Data</h3>
            <Grid
              container
              direction="row"
              item
              xs={12}
              md={12}
              spacing={5}
              style={{ marginTop: "1px" }}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Grid item xs={3}>
                <Grid container direction="column" spacing={4}>
                  <Grid item lg={3} md={6} xs={12}>
                    <MapDATA
                      hname="Registred"
                      Cardvalue={singleAreaData?.registered ?? 0}
                      percentage={singleAreaData?.registered_today ?? 0}
                      svg={das}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <MapDATA
                      hname="Catolog Done"
                      Cardvalue={singleAreaData?.catalog_done ?? 0}
                      percentage={singleAreaData?.catalog_done_today ?? 0}
                      svg={das}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <MapDATA
                      hname="Cataloging Passed"
                      Cardvalue={singleAreaData?.catalog_passed ?? 0}
                      percentage={singleAreaData?.catalog_passed_today ?? 0}
                      svg={das}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <MapDATA
                      hname="T & C Accepted "
                      Cardvalue={singleAreaData?.terms_passed ?? 0}
                      percentage={singleAreaData?.terms_passed_today ?? 0}
                      svg={das}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container direction="column" spacing={4}>
                  <Grid item lg={3} md={6} xs={12}>
                    <MapDATA
                      hname="Catalog Done "
                      Cardvalue={singleAreaData?.catalog_done ?? 0}
                      percentage={singleAreaData?.catalog_done_today ?? 0}
                      svg={das}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <MapDATA
                      hname="KYC Passed"
                      Cardvalue={singleAreaData?.kyc_passed ?? 0}
                      percentage={singleAreaData?.kyc_passed_today ?? 0}
                      svg={das}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <MapDATA
                      hname="Training Passed"
                      Cardvalue={singleAreaData?.training_passed ?? 0}
                      percentage={singleAreaData?.training_passed_today ?? 0}
                      svg={das}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" lg={6} xs={12}>
                  {mapCity === "Pune" ? (
                    <Pune
                      mapData={mapData}
                      setSingleAreaData={setSingleAreaData}
                      mapCity={mapCity}
                    />
                  ) : mapCity === "Bengaluru" ? (
                    <Bengaluru
                      mapData={mapData}
                      setSingleAreaData={setSingleAreaData}
                      mapCity={mapCity}
                    />
                  ) : mapCity === "Chennai" ? (
                    <Chennai
                      mapData={mapData}
                      setSingleAreaData={setSingleAreaData}
                      mapCity={mapCity}
                    />
                  ) : (
                    <h2 style={{ color: "#ffff", maxWidth: "100%" }}>
                      No map available for {mapCity}
                    </h2>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )} */}
        <Grid item xs={12} md={12} marginTop={10}>
          <DTable data={tableData} setFlag={setFlag} flag={flag} />
        </Grid>{" "}
      </div>
    </div>
  );
}

export default N_Stoke;
