import "./styles.scss"; // Import your Sass styles here
import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { base_url, checkProjects, verifyOtp } from "../utils/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const navigate = useNavigate();
  const [accessProjects, setAccessProjects] = React.useState([]);
  const tokenData = sessionStorage.getItem("token");
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const getProjectsData = (dataToken) => {
    axios
      .get(checkProjects, dataToken)
      .then((res) => {
        setAccessProjects(res.data.data);

        console.log(res.data.data, "checkprojres");
      })
      .catch((err) => console.log(err));
  };
  const switchProjects = (item) => {
    console.log(item, "tesingonit");
    let bodyData = {
      mobile_number: item.mobile_number,
      project_id: item.project_id,
      otp: item.otp,
      // email: item.email_id,
    };
    console.log(bodyData, item, "checit");
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    let api = base_url + verifyOtp;
    axios
      .post(api, bodyData, dataToken)
      .then((res) => {
        console.log(res.data, "finaltoken");

        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("admin", res.data.admin);
        sessionStorage.setItem("fullName", res.data.full_name);
        sessionStorage.setItem("projectid", res.data.project_id);

        navigate("/");
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };
  React.useEffect(() => {
    let api = checkProjects;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    getProjectsData(dataToken);
  }, []);
  return (
    <div className="App">
      <section className="wrapper">
        <div className="container">
          <div id="scene" className="scene" data-hover-only="false">
            <div className="circle" data-depth="1.2"></div>
            <div className="one" data-depth="0.9">
              <div className="content">
                <span className="piece"></span>
                <span className="piece"></span>
                <span className="piece"></span>
              </div>
            </div>
            <div className="two" data-depth="0.60">
              <div className="content">
                <span className="piece"></span>
                <span className="piece"></span>
                <span className="piece"></span>
              </div>
            </div>
            <div className="three" data-depth="0.40">
              <div className="content">
                <span className="piece"></span>
                <span className="piece"></span>
                <span className="piece"></span>
              </div>
            </div>
            <p className="p404" data-depth="0.50">
              TASKMO
            </p>
            <p className="p404" data-depth="0.10">
              TASKMO
            </p>
          </div>
          <div className="text">
            <article>
              <p>
                Uh oh! Looks like you got lost. <br />
                Switch project!
              </p>
              {accessProjects.length > 0 && (
                <button onClick={handleClick2}>SWITCH!</button>
              )}
            </article>
          </div>
        </div>

        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl2}
          open={open2}
          onClose={handleClose2}
          anchorOrigin={{
            // vertical: "top",
            // horizontal: "left",
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {accessProjects.map((item) => (
            <MenuItem
              key={item}
              sx={{ fontSize: "12px", width: "fit-content" }}
              onClick={() => {
                // handleClickOpen3(item);

                switchProjects(item);

                handleClose2();
                // handleClose();
                // sessionStorage.removeItem('token');
                // sessionStorage.removeItem('admin');
                // sessionStorage.removeItem('fullName');
                // sessionStorage.removeItem('projectid');
                // navigate('/');
              }}
            >
              {item.project_title}
            </MenuItem>
          ))}
        </Menu>
      </section>
    </div>
  );
};

export default NotFound;
