import React, { useEffect, useState } from "react";
import { upload_baseurl } from "../utils/utils";
import axios from "axios";
import { cred_table_data } from "../utils/credUtils";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink, CSVDownload } from "react-csv";
import "react-data-table-component-extensions/dist/index.css";
import CredDetails from "./CredDetails";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
      border: "none",
      backgroundColor: "#24304B",
    },
  },
  headCells: {
    style: {
      padding: 15,
      color: "#FFFFFF",
      backgroundColor: "#141D33",
      justifyContent: "center",
    },
  },
  cells: {
    style: {
      backgroundColor: "#24304B",
      color: "#ffff",
      justifyContent: "center",
      fill: "white",
    },
  },
  table: {
    style: {
      minHeight: "400px",
    },
  },
  pagination: {
    style: {
      backgroundColor: "#24304B",
      color: "#ffff",
    },
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#24304B",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 4,
};
function CreadTable() {
  const [sleeptable, setSleeptable] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [maxWidth, setMaxWidth] = useState("xl");
  const [dataEach, setDataEach] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [flag, setFlag] = useState(false);
  const open = Boolean(anchorEl);
  const [openM, setOpenM] = React.useState(false);
  const [rejectClicked, setRejectClicked] = useState(false);

  const handleCloseR = () => setRejectClicked(false);
  const [remark, setRemark] = useState("");

  const handleOpenM = () => {
    setOpenM(true);
    // setSelectedData(d?.main_lead_id);
  };
  const handleOpenR = (d) => {
    setRejectClicked(true);
    setSelectedData(d?.main_lead_id);
  };
  const handleCloseM = () => setOpenM(false);

  const [openSnack, setOpenSnack] = React.useState(false);
  const [statusSnack, setStatusSnack] = React.useState("success");

  const handleClickSnack = (val) => {
    setStatusSnack(val);
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClickOpenView = (d) => {
    console.log(d, "checkd");
    setDataEach(d);
    setOpen1(true);
  };

  const tokenData = sessionStorage.getItem("token");
  const dataToken = {
    headers: {
      "x-access-token": `${tokenData}`,
    },
  };
  console.log(dataToken, "df");
  const tabledata = () => {
    const link = upload_baseurl + cred_table_data;
    axios
      .get(link, dataToken)
      .then((res) => {
        setSleeptable(res?.data?.project_data);
        console.log(res?.data?.project_data, "kk");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    tabledata();
  }, [flag]);

  const columns = [
    {
      name: "Main Lead Id",
      selector: (row) => row.main_lead_id,

      sortable: true,
      cell: (d) => (
        <div style={{ cursor: "pointer" }} className="text-info">
          <u>
            <span style={{ padding: 5, fontSize: 12 }}>{d.main_lead_id}</span>
          </u>
        </div>
      ),
    },
    {
      name: "Date",
      selector: (row) => row.main_lead_id,

      sortable: true,
      cell: (d) => (
        <div style={{ cursor: "pointer" }} className="text-info">
          <u>
            <span style={{ padding: 5, fontSize: 12 }}>{d.date}</span>
          </u>
        </div>
      ),
    },

    {
      name: "Merchant Name",
      selector: (row) => row.main_lead_id,

      sortable: true,
      cell: (d) => <span style={{ fontSize: 10 }}>{d?.merchant_name}</span>,
    },

    {
      name: "Shop Name",
      selector: (row) => row.main_lead_id,

      sortable: true,
      cell: (d) => (
        <div style={{ cursor: "pointer" }} className="text-info">
          <u>
            <span style={{ padding: 5, fontSize: 12 }}>{d.shop_name}</span>
          </u>
        </div>
      ),
    },

    {
      name: "City",
      selector: (row) => row.main_lead_id,

      sortable: true,
      cell: (d) => <p>{d?.city}</p>,
    },
    {
      name: "State",
      selector: (row) => row.main_lead_id,

      sortable: true,
      cell: (d) => <p>{d?.state}</p>,
    },
    {
      name: "QC",
      selector: (row) => row.final_qc,

      sortable: true,
      cell: (d) => (
        <div style={{ textTransform: "capitalize" }}>{d?.final_qc}</div>
      ),
    },
    {
      name: "Client QC",
      selector: (row) => row.client_qc,

      sortable: true,
      cell: (d) => (
        <div style={{ textTransform: "capitalize" }}>{d?.client_qc}</div>
      ),
    },
    {
      name: "QC Remark",
      selector: (row) => row.client_qc_remark,

      sortable: true,
      cell: (d) => (
        <div style={{ textTransform: "capitalize" }}>{d?.client_qc_remark}</div>
      ),
    },

    {
      name: "View Details",
      cell: (d) =>
        d.client_qc == "rejected" || d.client_qc == "approved" ? (
          <>
            <p>----</p>{" "}
          </>
        ) : (
          <>
            <div>
              <FileOpenIcon onClick={() => handleClickOpenView(d)} />
            </div>
          </>
        ),
    },
  ];
  let tableData = {
    data: sleeptable,
    columns,
  };

  return (
    <>
      <Box>
        <Card
          variant="outlined"
          style={{
            backgroundColor: "#24304B",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              visibility: sleeptable?.length == 0 || sleeptable == undefined,
              // sleeptable?.length == 0 ? "hidden" : "show",
            }}
          >
            <CSVLink filename="Report.csv" data={sleeptable ?? []}>
              <IconButton variant="text">
                <DownloadIcon style={{ color: "white" }} />
              </IconButton>
            </CSVLink>
          </div>
          <CardContent style={{ padding: "0" }}>
            <DataTableExtensions
              {...tableData}
              export={false}
              print={false}
              exportHeaders
              filterPlaceholder={`Search`}
              className="filter_text"
            >
              <DataTable
                columns={columns}
                data={sleeptable}
                customStyles={customStyles}
                highlightOnHover={true}
                paginationRowsPerPageOptions={[10, 30, 50, 100]}
                fixedHeader={true}
                fixedHeaderScrollHeight={"430px"}
                pagination
              />
            </DataTableExtensions>
            <Dialog
              fullScreen
              maxWidth={maxWidth}
              open={open1}
              onClose={handleClose1}
            >
              <DialogTitle
                style={{
                  // boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                  backgroundColor: "#24304B",
                  color: "white",

                  // borderRadius: "8px",
                  //   color: "white",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>View Details</div>
                  <div onClick={handleClose1}>
                    <HighlightOffOutlinedIcon style={{ cursor: "pointer" }} />
                  </div>
                </div>
              </DialogTitle>

              <DialogContent
                style={{
                  // boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                  backgroundColor: "#24304B",
                  // borderRadius: "8px",
                  //   color: "white",
                }}
              >
                <CredDetails
                  data={dataEach}
                  closeMainModal={handleClose1}
                  setFlag={setFlag}
                  flag={flag}
                  dataToken={dataToken}
                  handleClose1={handleClose1}
                  handleClickSnack={handleClickSnack}
                />
              </DialogContent>
            </Dialog>

            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={openSnack}
              autoHideDuration={2000}
              onClose={handleCloseSnack}
            >
              <Alert
                onClose={handleCloseSnack}
                severity={statusSnack}
                sx={{ width: "100%" }}
              >
                {statusSnack === "success"
                  ? "Success!"
                  : "Remark already Exists"}
              </Alert>
            </Snackbar>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default CreadTable;
