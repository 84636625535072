import React, { useEffect, useState } from "react";
import CardsOne from "./CardsOne";
import ApexGraph from "../components/ApexGraph";
import LineChart from "../components/LineChart";
import MuiCardC from "./MuiCardC";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./PageNammayatri.css";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import CalendarUi from "./CalendarUi";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Graph from "./Graph";
import TableCard from "../components/TableCard";
import Fade from "@mui/material/Fade";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  card_data,
  map_pincode_namma,
  namma_base_url,
  one_ride_chart,
  search_city,
} from "../utils/namayatriUtils";
import axios from "axios";
import Header from "../components/Header";
import Cookies from "js-cookie";
import MuiCard from "./MuiCard";
import MapCard from "./MapCard";
import TestCard from "./TestCard";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { base_url, map_pincode } from "../utils/utils";

// date range picker package
import { DateRangePicker } from "rsuite";
import NammaYatriModalData from "./NammaYatriModalData";

const PageNammayatri = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [defaults, setDefaults] = useState(true);

  const [dateChanged, setDateChanged] = useState(false);
  //  daterange picker
  const [selectedDateRange, setSelectedDateRange] = useState([
    new Date(),
    new Date(),
  ]);

  const handleDateRangeChange = (value) => {
    setSelectedDateRange(value);
  };

  //   city selection
  const [selectedCity, setSelectedCity] = React.useState("all");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingGraph, setIsLoadingGraph] = React.useState(false);
  const [isErr, setIsErr] = React.useState(false);
  const [cityData, setCityData] = React.useState([]);
  const [cardData, setCardData] = React.useState([]);
  const [oneRideData, setOneRideData] = React.useState([]);
  const [threeRideData, setThreeRideData] = React.useState([]);
  const [loading3, setLoading3] = React.useState(false);
  const [mapData, setMapData] = React.useState([]);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const token = sessionStorage.getItem("token");
  const [mapCity, setMapCity] = React.useState("all");
  const [citiesData, setcitiesData] = React.useState([]);

  const getMapData = () => {
    let apiData = base_url + map_pincode_namma;
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        from: moment(selectedDateRange[0]).format("YYYY-MM-DD"),
        to: moment(selectedDateRange[1]).format("YYYY-MM-DD"),
      },
    };
    setLoading3(true);
    axios
      .get(apiData, dataToken)
      .then((res) => {
        setLoading3(false);

        setMapData(res.data?.city_data);
      })
      .catch((err) => console.log(err, "err"));
  };
  document.body.style.backgroundColor = "#131B2F";

  //menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setCitymenu = (city) => {
    if (typeof city == Object) {
      return "";
    }
    setSelectedCity(city);
  };

  useEffect(() => {
    if (sessionStorage.getItem("projectid") !== "286") {
      window.location.reload();
    }
  }, []);

  const getCityData = () => {
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    let apiDatacity = namma_base_url + search_city;
    setIsLoading(true);
    axios
      .get(apiDatacity, dataToken)
      .then((res) => {
        setIsLoading(false);

        let ot = res.data.city_list.map((item) => ({
          label: item,
          value: item,
        }));

        setCityData(res.data.city_list);
        setcitiesData(ot);
      })
      .catch((err) => setIsErr(true))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCardData = () => {
    const tokenData = token;

    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        city: mapCity,
        from:
          startDate == null
            ? "all"
            : moment(selectedDateRange[0]).format("YYYY-MM-DD"),
        to:
          endDate == null
            ? "all"
            : moment(selectedDateRange[1]).format("YYYY-MM-DD"),
        // month: defaults ? "all" : moment(date).format("MM"),
        // year: "2023",
      },
    };
    let apiData = namma_base_url + card_data;
    setIsLoading(true);
    axios
      .get(apiData, dataToken)
      .then((res) => {
        setIsLoading(false);

        setCardData(res?.data?.cards_data);
      })
      .catch((err) => setIsErr(true))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getOneRideChartData = (type) => {
    const tokenData = token;

    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        city: mapCity,
        from:
          startDate == null
            ? "all"
            : moment(selectedDateRange[0]).format("YYYY-MM-DD"),
        to:
          endDate == null
            ? "all"
            : moment(selectedDateRange[1]).format("YYYY-MM-DD"),
        city: selectedCity,
        type,
      },
    };
    console.log(mapCity, "mapCity");

    let apiData = namma_base_url + one_ride_chart;
    setIsLoadingGraph(true);
    axios
      .get(apiData, dataToken)
      .then((res) => {
        setIsLoadingGraph(false);

        if (type == "one") {
          setOneRideData(res?.data);
        } else {
          setThreeRideData(res?.data);
        }
      })
      .catch((err) => setIsErr(true))
      .finally(() => {
        setIsLoadingGraph(false);
      });
  };

  React.useEffect(() => {
    if (selectedDateRange?.length == 2) {
      getCardData();
      getOneRideChartData("one");
      getOneRideChartData("three");
    }
  }, [selectedCity, date, endDate, startDate, selectedDateRange, mapCity]);

  React.useEffect(() => {
    getCityData();
    getMapData();
  }, []);

  React.useEffect(() => {
    if (selectedDateRange?.length == 2) {
      getMapData();
    }
  }, [startDate, endDate, selectedDateRange]);

  return isLoading || isLoadingGraph || loading3 ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Header />
      <div style={{ padding: "7%", paddingTop: "3%" }}>
        <Grid container direction="column" gap="40px">
          <Grid item>
            <Grid
              container
              direction={"row"}
              spacing={4}
              // justifyContent={"flex-end"}
              alignItems={"center"}
              style={{ color: "#ffffff" }}
            >
              <Grid item display={"flex"}>
                <Grid>
                  <DateRangePicker
                    appearance="subtle"
                    placeholder="Date Range Picker"
                    value={selectedDateRange}
                    onChange={handleDateRangeChange}
                    style={{
                      textTransform: "unset",
                      boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                      backgroundColor: "#24304B",
                      borderRadius: "16px",
                      paddingLeft: "0px",
                      width: 230,
                    }}
                  />
                </Grid>

                <Grid mx={1}>
                  {" "}
                  <TextField
                    style={{
                      // backgroundColor: "#f0f0f0",
                      backgroundColor: "#24314B",
                      fontFamily: "Nunito",
                      border: "none",
                      boxShadow: "none",
                      borderRadius: "4px",
                    }}
                    color="primary"
                    id="outlined-select-currency"
                    select
                    value={mapCity}
                    size="small"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    onChange={(e) => setMapCity(e.target.value)}
                  >
                    {citiesData.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
                  disableElevation
                  disableRipple
                  id="fade-button"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="contained"
                  style={{
                    textTransform: "unset",
                    boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                    width: "182px",
                    backgroundColor: "#24304B",
                    borderRadius: "16px",
                    paddingLeft: "0px",
                    fontSize: "16px",
                    padding: "4px 0px",
                    color: "#8E8E93",
                    visibility: "hidden",
                  }}
                  endIcon={<img src={"/menu.svg"} alt="" />}
                  onClick={handleClick}
                >
                  {selectedCity == "all" ? "Select city" : selectedCity}
                </Button>
              </Grid>
            </Grid>
            <br />
            <Grid item xs={12}>
              <Grid container direction="row" spacing={12}>
                <Grid item xs={4}>
                  <MuiCard name={1} data={cardData} />
                </Grid>
                <Grid item xs={4}>
                  <MuiCard name={2} data={cardData} />
                </Grid>
                <Grid item xs={4}>
                  <MuiCard name={3} data={cardData} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column" spacing={3}>
              <Grid
                item
                style={{ color: "#fff", fontSize: "30px", fontWeight: 600 }}
              >
                One Ride Chart
              </Grid>
              <Grid item>
                <Card
                  style={{
                    boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                    backgroundColor: "#24304B",
                    borderRadius: "8px",
                  }}
                >
                  <CardContent>
                    <Graph gdata={oneRideData} dataType={"one"} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="column" spacing={3}>
              <Grid
                item
                style={{ color: "#fff", fontSize: "30px", fontWeight: 600 }}
              >
                Three Ride Chart
              </Grid>
              <Grid item>
                {" "}
                <Card
                  style={{
                    boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                    backgroundColor: "#24304B",
                    borderRadius: "8px",
                  }}
                >
                  <CardContent>
                    <Graph gdata={threeRideData} dataType={"three"} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="column" spacing={3}>
              <Grid
                item
                style={{ color: "#fff", fontSize: "30px", fontWeight: 600 }}
              >
                Onboarding Details
              </Grid>
              <Grid item>
                <Grid container justifyContent={"space-between"} spacing={8}>
                  <Grid item xs={12}>
                    <MapCard
                      data={mapData}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TestCard
                      name="nammayatri"
                      dates={selectedDateRange}
                      city={mapCity}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Menu
          sx={{
            "& .MuiMenu-paper": {
              backgroundColor: "#24304B",
              color: "#ffffff",
              width: "160px",
              marginTop: "10px",
              boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
              borderRadius: "8px",
            },
          }}
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {(cityData || []).map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                handleClose();
                setCitymenu(item);
              }}
            >
              {item}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>
  );
};

export default PageNammayatri;
