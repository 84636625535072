import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../components/Login";
import App from "../App";
import { localRoute } from "./localRoutes";
import LoginOtp from "../components/LoginOtp";
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import { PrivateRoute2 } from "../PrivateRoute/PrivateRoute2";
import { PrivateRoute3 } from "../PrivateRoute/PrivateRoute3";
import CheckData from "../components/CheckData";
import PageNammayatri from "../pages/PageNammayatri";
import { PrivateRoute4 } from "../PrivateRoute/PrivateRoute4";
import N_Store from "../Pages2/N_Store";
import Cookies from "js-cookie";
import SleepWell from "../Pages2/SleepWell";
import Cred from "../Pages2/Cred";
import { Client1 } from "../Pages2/Client1";
import NotFound from "../components/NotFound";
import NammaYatriStrickers from "../Pages2/NammaYatriStrickers";
const RouterComponent = () => {
  const project = sessionStorage.getItem("projectid");

  console.log(project, "project");

  return (
    <div>
      <Routes>
        <Route path={localRoute.login} element={<PrivateRoute2 />}>
          <Route path={localRoute.login} element={<Login />} />
        </Route>

        <Route path="/" element={<Navigate to="/login" />} />
        <Route path={`${localRoute.dashboard}/error`} element={<NotFound />} />
        {/* <Route path={localRoute.dashboard} element={<PrivateRoute />}>
          <Route path={localRoute.dashboard} element={<App />} />
        </Route> */}
        <Route path={localRoute.otp} element={<PrivateRoute3 />}>
          <Route path={localRoute.otp} element={<LoginOtp />} />
        </Route>
        <Route path={localRoute.checkData} element={<CheckData />} />

        {project == "259" ? (
          <Route path={localRoute.dashboard} element={<PrivateRoute />}>
            <Route path={localRoute.dashboard} element={<App />} />
          </Route>
        ) : project == "296" ? (
          <Route path={localRoute.dashboard} element={<PrivateRoute />}>
            <Route path={localRoute.dashboard} element={<N_Store />} />
          </Route>
        ) : project == "303" ? (
          <Route path={localRoute.dashboard} element={<PrivateRoute />}>
            <Route path={localRoute.dashboard} element={<SleepWell />} />
          </Route>
        ) : project === "253" ? (
          <Route path={localRoute.dashboard} element={<PrivateRoute />}>
            <Route path={localRoute.dashboard} element={<Cred />} />
          </Route>
        ) : project === "320" ? (
          <Route path={localRoute.dashboard} element={<PrivateRoute />}>
            <Route path={localRoute.dashboard} element={<Client1 />} />
          </Route>
        ) : project === "326" ? (
          <Route path={localRoute.dashboard} element={<PrivateRoute />}>
            <Route
              path={localRoute.dashboard}
              element={<NammaYatriStrickers />}
            />
          </Route>
        ) : project === "286" ? (
          <Route path={localRoute.dashboard} element={<PrivateRoute />}>
            <Route path={localRoute.dashboard} element={<PageNammayatri />} />
          </Route>
        ) : (
          <Route path={localRoute.dashboard} element={<PrivateRoute />}>
            <Route path={localRoute.dashboard} element={<NotFound />} />
          </Route>
          // <Route
          //   path={`${localRoute.dashboard}/error`}
          //   element={<NotFound />}
          // />
        )}
      </Routes>
    </div>
  );
};

export default RouterComponent;
