const base_url = 'https://projectsbackend.taskmo.co';

const today_chart = '/ondc/today_chart';
const table_data = '/ondc/table_data';

const overall_chart = '/ondc/overall_chart';

const ondc_cards = '/ondc/ondc_cards';

const city_chart = '/ondc/city_chart';

const pos_type_chart = '/ondc/pos_type_chart';

const getOtp = '/common/getOtp_client';

const verifyOtp = '/common/verifyOtp_client';

const baseUrl = 'https://spapp.backend.taskmo.co';

const proposal = '/agreement/sendGrowthfalconProposal';

const map_pincode = '/namma_yatri/map_pincode';

const ondc_pincode = '/ondc/map_pincode';

const ondc_map_data_2 = '/ondc/map_pincode_grow';

const citydatas = '/ondc/search_city';

const upload_baseurl = 'https://projectsbackend.taskmo.co';
const upload_url = '/aws_image_upload/image_upload';
const image_display = '/project/distance_cal';
const farming_url = 'https://farming-backend.taskmo.in';

const checkProjects = `${base_url}/common/project_lists_client_data `;
const verifyOtp_common = `${base_url}/common/verifyOtp_common`;
export {
  base_url,
  today_chart,
  table_data,
  overall_chart,
  ondc_cards,
  city_chart,
  getOtp,
  verifyOtp,
  pos_type_chart,
  baseUrl,
  proposal,
  map_pincode,
  ondc_pincode,
  ondc_map_data_2,
  citydatas,
  upload_baseurl,
  upload_url,
  image_display,
  farming_url,
  checkProjects,
  verifyOtp_common,
};
