import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { scaleQuantile } from "d3-scale";
import ReactTooltip from "react-tooltip";
import testPin from "./csvjson.json";
import LinearGradient from "./LinearGradient.js";

import "./Maps.css";

const INDIA_TOPO_JSON = require("./blrrr.json");
// const INDIA_TOPO_JSON = require("./Blr.json");

const PROJECTION_CONFIG = {
  //   scale: 399850,
  //   //   scale: 136850,
  //   // scale: 5,
  //   center: [77.5929, 12.9637], // always in [East Latitude, North Longitude]
  scale: 47850,
  center: [77.5929, 12.8737],
};

// console.log(testPin, "pincodetest");

// Red Variants
const COLOR_RANGE = [
  //   "#ffedea",
  //   "#ffcec5",
  //   "#ffad9f",
  //   "#ff8a75",
  //   "#ff5533",
  //   "#e2492d",
  //   "#be3d26",
  //   "#9a311f",
  //   "#782618",
  "#95d5b2",
  "#74c69d",
  "#40916c",

  //   "#d8f3dc",
];

const DEFAULT_COLOR = "#b7e4c7";

const getRandomInt = () => {
  return parseInt(Math.random() * 100);
};

const geographyStyle = {
  default: {
    // fill: "D6D6DA",
    outline: "none",
  },
  hover: {
    fill: "#ccc",
    transition: "all 250ms",
    outline: "black",
  },
  pressed: {
    outline: "none",
  },
};

// will generate random heatmap data on every call
const getHeatMapData = () => {
  return [
    { id: "750", state: "Telangana", value: getRandomInt() },
    { id: "AR", state: "Arunachal Pradesh", value: getRandomInt() },
    { id: "AS", state: "Assam", value: getRandomInt() },
    { id: "BR", state: "Bihar", value: getRandomInt() },
    { id: "CT", state: "Chhattisgarh", value: getRandomInt() },
    { id: "GA", state: "Goa", value: 21 },
    { id: "GJ", state: "Gujarat", value: 22 },
    { id: "HR", state: "Haryana", value: getRandomInt() },
    { id: "HP", state: "Himachal Pradesh", value: 24 },
    { id: "JH", state: "Jharkhand", value: 26 },
    { id: "KA", state: "Karnataka", value: 27 },
    { id: "KL", state: "Kerala", value: getRandomInt() },
    { id: "MP", state: "Madhya Pradesh", value: getRandomInt() },
    { id: "MH", state: "Maharashtra", value: getRandomInt() },
    { id: "MN", state: "Manipur", value: getRandomInt() },
    { id: "ML", state: "Meghalaya", value: 59 },
    { id: "MZ", state: "Mizoram", value: getRandomInt() },
    { id: "NL", state: "Nagaland", value: 59 },
    { id: "OR", state: "Odisha", value: 59 },
    { id: "PB", state: "Punjab", value: getRandomInt() },
    { id: "RJ", state: "Rajasthan", value: getRandomInt() },
    { id: "SK", state: "Sikkim", value: getRandomInt() },
    { id: "TN", state: "Tamil Nadu", value: getRandomInt() },
    { id: "TG", state: "Telangana", value: getRandomInt() },
    { id: "TR", state: "Tripura", value: 14 },
    { id: "UT", state: "Uttarakhand", value: getRandomInt() },
    { id: "UP", state: "Uttar Pradesh", value: 15 },
    { id: "WB", state: "West Bengal", value: 17 },
    { id: "WB", state: "West Bengal", value: 17 },
    { id: "AN", state: "Andaman and Nicobar Islands", value: getRandomInt() },
    { id: "CH", state: "Chandigarh", value: getRandomInt() },
    { id: "DN", state: "Dadra and Nagar Haveli", value: 19 },
    { id: "DD", state: "Daman and Diu", value: 20 },
    { id: "DL", state: "Delhi", value: 59 },
    { id: "JK", state: "Jammu and Kashmir", value: 25 },
    { id: "LA", state: "Ladakh", value: getRandomInt() },
    { id: "LD", state: "Lakshadweep", value: getRandomInt() },
    { id: "PY", state: "Puducherry", value: getRandomInt() },
  ];
};

function MapBang({
  data2,
  singleAreaData,
  setSingleAreaData,
  startDate,
  endDate,
}) {
  console.log(data2, "testingondatacheck");
  const [tooltipContent, setTooltipContent] = useState("");
  const [data, setData] = useState(getHeatMapData());

  const gradientData = {
    fromColor: COLOR_RANGE[0],
    toColor: COLOR_RANGE[COLOR_RANGE.length - 1],
    min: 0,
    max: data2.reduce(
      (max, item) => (item.total_onboarded > max ? item.total_onboarded : max),
      0
    ),
  };

  const colorScale = scaleQuantile()
    .domain(data.map((d) => d.value))
    .range(COLOR_RANGE);

  const onMouseEnter = (
    geo,
    current = {
      total_onboarded: "0",
      one_ride_count: "0",
      three_ride_count: "0",
    }
  ) => {
    // setSingleAreaData(curre)

    return () => {
      // setTooltipContent(`${geo.properties.area_name}: ${current.lead_id ?? 0}`);
      //   setTooltipContent(
      //     `${geo.properties.tags.name}: ${current.lead_id ?? 222}`
      //   );
      //   console.log(geo, current, "checkononon");
      let obj = current;
      obj.name = geo.properties.officename;
      setSingleAreaData(obj);
      setTooltipContent(`${geo.properties.officename}`);
    };
  };

  const onMouseLeave = () => {
    setTooltipContent("");
  };

  return (
    <div>
      <LinearGradient data={gradientData} />
      <br />
      <ReactTooltip>{tooltipContent}</ReactTooltip>
      <ComposableMap
        projectionConfig={PROJECTION_CONFIG}
        projection="geoMercator"
        // width={5500}
        // height={900}
        // height={5500}
        data-tip=""
      >
        <Geographies
          geography={INDIA_TOPO_JSON}
          stroke="#fff"
          strokeWidth="0.015"
        >
          {({ geographies }) =>
            geographies.map((geo) => {
              console.log(geo, "geoid");
              const current = data2.find(
                (s) => s.pincode === geo.properties.pincode
              );
              //   console.log("fasak", geo, current);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={
                    current
                      ? colorScale(current.total_onboarded)
                      : DEFAULT_COLOR
                  }
                  style={geographyStyle}
                  onMouseEnter={onMouseEnter(geo, current)}
                  onMouseLeave={onMouseLeave}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
}

export default MapBang;
