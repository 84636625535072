import React from "react";
import Chart from "react-apexcharts";

const LineChart = ({ chart, name }) => {
  const data = {
    series: [
      {
        name: "Survey",
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
      },
      {
        name: "Onboarding",
        data: [20, 45, 25, 57, 39, 65, 82, 71, 145],
      },
    ],
    options: {
      colors: ["#b4ff69", "#beb5f4", "#f9b754"],
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 3,
        dashArray: 0,
      },

      legend: {
        position: "bottom",
        horizontalAlign: "right",
        // offsetX: 0,
        // offsetY: 10,
        labels: {
          colors: "#fff",
        },
      },
      tooltip: {
        enabled: true,
      },
      title: {
        text: name == "today" ? "Today's Chart" : "Overall Chart",
        style: {
          color: "#fff",
        },
      },

      chart: {
        toolbar: {
          show: false,
        },

        id: "line-chart",
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
      },
      xaxis: {
        categories: chart.months,
        labels: {
          style: {
            colors: "#fff",
          },
        },
      },

      yaxis: {
        title: {
          // text: "Sales (in USD)",
          style: {
            color: "#fff", // Set the color of the Y-axis title
          },
        },
        labels: {
          style: {
            colors: "#fff", // Set the color of the Y-axis labels
          },
        },
      },
    },
  };

  return (
    <Chart
      options={data.options}
      series={chart.series}
      type="line"
      height={400}
    />
  );
};

export default LineChart;
