const search_tier = "/mystore/search_tier";
const card_data = "/mystore/card_data";
const table_data = "/mystore/table_data";
const categories = "/mystore/mystore_categories";
const Graphdata = "/mystore/category_filter";
const Graphdata2 = "/mystore/category_count";
export {
  search_tier,
  card_data,
  table_data,
  categories,
  Graphdata2,
  Graphdata,
};
