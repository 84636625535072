import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useRef, useEffect, useState } from "react";
import geojson from "../JSON/pune.json";
import "./map.css";
mapboxgl.accessToken =
  "pk.eyJ1Ijoia2FydGlra3NoZXR0YXIiLCJhIjoiY2xpMWNqdWFkMDd1eDNsbzNwM2MyemczaCJ9.1NnoucNrjFINkPyLPcpf8g";
function Pune({ mapData, setSingleAreaData, mapCity }) {
  const mapContainerRef = useRef(null);
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const popup = new mapboxgl.Popup({
    closeButton: false,
  });

  const COLOR_RANGE_1 = ["#6987C9", "#AEC0E8", "#CDDBFA"];
  const COLOR_RANGE_2 = ["#AEC0E8", "#6987C9", "#CDDBFA"];
  const COLOR_RANGE_3 = ["#CDDBFA", "#AEC0E8", "#6987C9"];

  let current;

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [74.2, 18.68],
      zoom: 7.6,
      dragPan: false,
      doubleClickZoom: false,
      attributionControl: false, // disable the default attribution control
    });

    map.on("load", () => {
      const maxLead = Math.max(
        ...mapData.map((item) => parseInt(item.registered))
      );
      const minLead = Math.min(
        ...mapData.map((item) => parseInt(item.registered))
      );

      const leadRange = (maxLead - minLead) / 3;

      const set1 = mapData.filter(
        (item) =>
          parseInt(item.registered) >= minLead &&
          parseInt(item.registered) < minLead + leadRange
      );
      const set2 = mapData.filter(
        (item) =>
          parseInt(item.registered) >= minLead + leadRange &&
          parseInt(item.registered) < minLead + leadRange * 2
      );
      const set3 = mapData.filter(
        (item) => parseInt(item.registered) >= minLead + leadRange * 2
      );

      var style = map.getStyle();
      map.addLayer(
        {
          id: "background-layer",
          type: "background",
          paint: {
            "background-color": "#24304B",
          },
        },
        "waterway-label"
      );
      map.addSource("my-data", {
        type: "geojson",
        data: geojson,
      });

      map.addLayer({
        id: "heatmap-layer",
        type: "fill",
        source: "my-data",
        paint: {
          "fill-color": [
            "case",
            [
              "in",
              ["get", "pincode"],
              ["literal", set1.map((item) => item.pincode)],
            ],
            [
              "interpolate",
              ["linear"],
              ["to-number", ["get", "lead"]],
              minLead,
              COLOR_RANGE_1[0],
              minLead + leadRange,
              COLOR_RANGE_1[1],
              minLead + leadRange * 2,
              COLOR_RANGE_1[2],
            ],
            [
              "in",
              ["get", "pincode"],
              ["literal", set2.map((item) => item.pincode)],
            ],
            [
              "interpolate",
              ["linear"],
              ["to-number", ["get", "lead"]],
              minLead + leadRange,
              COLOR_RANGE_2[0],
              minLead + leadRange * 2,
              COLOR_RANGE_2[1],
              maxLead,
              COLOR_RANGE_2[2],
            ],
            [
              "in",
              ["get", "pincode"],
              ["literal", set3.map((item) => item.pincode)],
            ],
            [
              "interpolate",
              ["linear"],
              ["to-number", ["get", "lead"]],
              minLead + leadRange * 2,
              COLOR_RANGE_3[0],
              maxLead,
              COLOR_RANGE_3[1],
            ],
            "#2D64A8",
          ],
          "fill-opacity": 1,
          "fill-outline-color": "#a9a9a9",
          //"fill-outline-width": 2,
        },
      });

      map.on("mousemove", "heatmap-layer", (e) => {
        let obj = { ...current };
        setSingleAreaData(obj);

        current = mapData.find(
          (s) => s.pincode == e.features[0].properties.pincode
        );

        map.getCanvas().style.cursor = "pointer";
        if (e.features.length > 0) {
          if (hoveredFeature) {
            map.setFeatureState(
              { source: "my-data", id: hoveredFeature },
              { hover: false }
            );
          }
          setHoveredFeature(e.features[0].properties.pincode);
          console.log(e.features[0], "pin");
          map.setFeatureState(
            { source: "my-data", id: hoveredFeature },
            { hover: true }
          );
        }

        const coordinates = e.features[0].geometry.coordinates.slice();
        const lngLat = [coordinates[0][1][0], coordinates[0][1][1]];
        const name = e.features[0].properties.officename;
        console.log(lngLat, name, "la");
        popup.setLngLat(lngLat).setHTML(`<p >Area : ${name}</p>`).addTo(map);
      });

      map.on("mouseleave", "heatmap-layer", () => {
        if (hoveredFeature) {
          map.setFeatureState(
            { source: "my-data", id: hoveredFeature },
            { hover: false }
          );
        }
        setHoveredFeature(null);
        map.setPaintProperty("heatmap-layer", "fill-color", [
          "case",
          [
            "in",
            ["get", "pincode"],
            ["literal", set1.map((item) => item.pincode)],
          ],
          [
            "interpolate",
            ["linear"],
            ["to-number", ["get", "lead"]],
            minLead,
            COLOR_RANGE_1[0],
            minLead + leadRange,
            COLOR_RANGE_1[1],
            minLead + leadRange * 2,
            COLOR_RANGE_1[2],
          ],
          [
            "in",
            ["get", "pincode"],
            ["literal", set2.map((item) => item.pincode)],
          ],
          [
            "interpolate",
            ["linear"],
            ["to-number", ["get", "lead"]],
            minLead + leadRange,
            COLOR_RANGE_2[0],
            minLead + leadRange * 2,
            COLOR_RANGE_2[1],
            maxLead,
            COLOR_RANGE_2[2],
          ],
          [
            "in",
            ["get", "pincode"],
            ["literal", set3.map((item) => item.pincode)],
          ],
          [
            "interpolate",
            ["linear"],
            ["to-number", ["get", "lead"]],
            minLead + leadRange * 2,
            COLOR_RANGE_3[0],
            maxLead,
            COLOR_RANGE_3[1],
          ],
          "#2D64A8",
        ]);
        map.getCanvas().style.cursor = "";
        popup.remove();
      });

      // Find all layers that are labels
      style.layers.forEach(function (layer) {
        // Check if the layer is a label layer
        if (layer.type === "symbol" && layer.layout["text-field"]) {
          // Hide the label layer
          map.setLayoutProperty(layer.id, "visibility", "none");
        }
      });
    });

    map.scrollZoom.disable(); // disable zoom on scroll

    return () => {
      map.remove();
    };
  }, [mapCity, mapData]);

  return (
    <>
      <div
        ref={mapContainerRef}
        style={{
          width: 560,
          height: 600,
          offset: " 0px , 3px rgba(0, 0, 0, 0.19)",
        }}
      />
    </>
  );
}

export default Pune;
