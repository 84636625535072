import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Box, TextField, TableRow } from '@mui/material';
import { upload_baseurl } from '../utils/utils';
import { search_tier } from '../utils/myStoreutils';
import axios from 'axios';
import { useCategory } from './CategoryContext';

function Filters({ token, setParentcityFilter, setParentMonthFilter }) {
  const [cityList, setCityList] = useState([]);
  const { mapCity, setMapCity } = useCategory();
  const [visibleD, setVisible] = useState(false);
  const { selectedCategory } = useCategory();
  const { monthWiseData, setMonthWiseData } = useCategory();
  const [mapTier, setMapTier] = useState('all');
  const [cityTier, setCityTier] = useState([]);

  const MontlyData = [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: '01',
      label: 'January',
    },
    {
      value: '02',
      label: 'February',
    },
    {
      value: '03',
      label: 'April',
    },
    {
      value: '04',
      label: 'March',
    },
    {
      value: '05',
      label: 'May',
    },
    {
      value: '06',
      label: 'June',
    },

    {
      value: '07',
      label: 'July',
    },
    {
      value: '08',
      label: 'August',
    },
    {
      value: '09',
      label: 'September',
    },
    {
      value: '10',
      label: 'October',
    },
    {
      value: '11',
      label: 'November',
    },
    {
      value: '12',
      label: 'December',
    },
  ];
  const cityTiers = ['all', '1', '2', '3'];
  const temp1 = cityTiers.map((item) => ({
    label: item,
    value: item,
  }));

  const tierData = () => {
    const tierUrl = upload_baseurl + search_tier;
    const tokenData = token;
    const dataToken = {
      headers: {
        'x-access-token': `${tokenData}`,
      },
      params: {
        tier: mapTier,
      },
    };
    axios
      .get(tierUrl, dataToken)
      .then((res) => {
        setCityList(
          res?.data?.city_list.map((item) => ({
            label: item,
            value: item,
          }))
        );
        setVisible(true);
      })
      .catch((err) => console.log(err, 'err'));
  };

  useEffect(() => {
    tierData();
    setCityTier(temp1);
  }, [selectedCategory, mapTier]);

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '20ch' },
      }}
      noValidate
      autoComplete="off"
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        color: 'white',
      }}
    >
      <TableRow>
        <div style={{ display: 'flex' }}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '10px',
                color: 'white',
              }}
            >
              Tier Filter
              <TextField
                style={{
                  backgroundColor: '#24304B',
                }}
                color="primary"
                id="outlined-select-currency"
                select
                value={mapTier}
                size="small"
                sx={{
                  '& fieldset': { border: 'none' },
                }}
                onChange={(e) => {
                  setMapTier(e.target.value);
                }}
              >
                {cityTier?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label?.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{
              m: 1,
              minWidth: 120,
              visibility: !visibleD ? 'hidden' : 'visible',
            }}
          >
            {console.log(visibleD, 'tr')}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '10px',
                color: 'white',
              }}
            >
              City Filter
              <TextField
                style={{
                  backgroundColor: '#24304B',

                  fontFamily: 'Nunito',
                  border: 'none',
                  boxShadow: 'none',

                  borderRadius: '4px',
                }}
                color="primary"
                id="outlined-select-currency"
                select
                value={mapCity}
                size="small"
                sx={{
                  '& fieldset': { border: 'none' },
                }}
                onChange={(e) => {
                  setParentcityFilter(e.target.value);
                  setMapCity(e.target.value);
                }}
              >
                {cityList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label?.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </FormControl>

          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '10px',
                color: 'white',
              }}
            >
              Month Filter
              <TextField
                style={{
                  backgroundColor: '#24304B',

                  fontFamily: 'Nunito',
                  border: 'none',
                  boxShadow: 'none',

                  borderRadius: '4px',
                }}
                color="primary"
                id="outlined-select-currency"
                select
                value={monthWiseData}
                size="small"
                sx={{
                  '& fieldset': { border: 'none' },
                }}
                onChange={(e) => {
                  setParentMonthFilter(e.target.value);
                  setMonthWiseData(e.target.value);
                }}
              >
                {MontlyData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label?.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </FormControl>
        </div>
      </TableRow>
    </Box>
  );
}

export default Filters;
