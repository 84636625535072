import React, { useState } from "react";

import { Card, Grid, Typography } from "@mui/material";
import MapDATA from "./MapData";
import { upload_baseurl } from "../utils/utils";
import { card_data } from "../utils/myStoreutils";
import axios from "axios";
import { useEffect } from "react";
import { useCategory } from "./CategoryContext";

function Seller({ token }) {
  const [CardData, setCardData] = useState({});
  const { mapCity, monthWiseData } = useCategory();
  const sellerCardData = () => {
    const cardurl = upload_baseurl + card_data;
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        city: mapCity,
        month: monthWiseData,
      },
    };
    axios
      .get(cardurl, dataToken)
      .then((res) => {
        console.log(res?.data?.data);
        setCardData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    sellerCardData();
  }, [mapCity, monthWiseData]);
  return (
    <Card
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        padding: "16px",
        borderRadius: "8px",
        m: 5,
      }}
    >
      <Typography variant="h3" color={"white"} mx={1}>
        Seller
      </Typography>
      <Grid
        container
        direction="row"
        item
        xs={12}
        md={12}
        style={{ marginTop: 10 }}
        margin={1}
      >
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            spacing={6}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-around"}
          >
            <Grid item lg={4} md={7} xs={12}>
              <MapDATA
                hname="Seller Onbording"
                Cardvalue={CardData?.dataTotal}
                svg=""
                percentage={CardData?.dataToday}
              />
            </Grid>

            <Grid item lg={4} md={7} xs={12}>
              <MapDATA
                hname="Seller Live "
                Cardvalue={CardData?.catalogTotal}
                svg=""
                percentage={CardData?.catalogToday}
              />
            </Grid>

            <Grid item lg={4} md={7} xs={12}>
              <MapDATA
                hname="Billable "
                Cardvalue={CardData?.billableTotal}
                svg=""
                percentage={CardData?.billableToday}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default Seller;
