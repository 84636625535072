const sleepwell_table_data = "/sleepwell/table_data";
const sleepwell_reject_lead = "/sleepwell/reject_lead";
const map_pincode = "/sleepwell/map_pincode";
const sleepwell_city_list = "/sleepwell/search_city";
const base_url = "https://projectsbackend.taskmo.co";
const similar_url = "/sleepwell/similar_shops";
export {
  sleepwell_table_data,
  sleepwell_reject_lead,
  map_pincode,
  sleepwell_city_list,
  base_url,
  similar_url,
};
