const nStore_base_url = "https://app.backend.taskmo.co/pa";
const map_data = "/nstore_map_cards";
const card_data = "/nstore_cards";
const nstore_city_list = "/nstore_city_list";
const nstore_table_data = "/nstore_lead_data";
const update_nstore_status = "/update_nstore_status";
const nstore_overall_chart = "/nstore_overall_chart";
const nstore_pie_chart = "/nstore_pie_chart";

const edit_leads = "/common/edit_leads";
export {
  nStore_base_url,
  map_data,
  card_data,
  nstore_city_list,
  nstore_table_data,
  update_nstore_status,
  nstore_overall_chart,
  nstore_pie_chart,
  edit_leads,
};
