import React from "react";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

function ImgRotModal({ openImage, handleCloseImage, imageurl }) {
  return (
    <Modal
      open={openImage}
      onClose={handleCloseImage}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <ReactPanZoom image={imageurl} alt="Image alt text" />
      </Box>
    </Modal>
  );
}

export default ImgRotModal;
