import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Card from "@mui/material/Card";
import {
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { CardBody } from "reactstrap";
import { Graphdata2, Graphdata } from "../utils/myStoreutils";
import { categories } from "../utils/myStoreutils";
import { base_url, upload_baseurl } from "../utils/utils";
import axios from "axios";
import "apexcharts/dist/apexcharts.css";
import { useCategory } from "./CategoryContext";

const SideBar = ({ token }) => {
  const [datas, setData] = useState([]);
  const [values, setValuesData] = useState([]);
  const [datas2, setData2] = useState([]);
  const [values2, setValuesData2] = useState([]);
  const { selectedCategory, mapCity, monthWiseData, setSelectedCategory } =
    useCategory();
  const [cityTier, setCityTier] = useState([]);
  const [subData, setSubData] = useState([]);
  const [subcatValue, setSubCatValue] = useState("all");
  const [issubLoading, setIsSubLoading] = useState(false);
  const [newState, SetnewState] = useState("all");

  const Graph_data = () => {
    const tierUrl = upload_baseurl + Graphdata;
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    const bodyData = {
      tier: "3",
      category: selectedCategory,
      sub_category: newState,
      city: mapCity,
      month: monthWiseData,
    };
    axios
      .post(tierUrl, bodyData, dataToken)
      .then((res) => {
        console.log(res?.data, "gopro");
        setData(res?.data?.data);
        setValuesData(res?.data?.data_name);
      })
      .catch((err) => console.log(err, "err"));
  };
  const Graph_data2 = () => {
    const tierUrl = upload_baseurl + Graphdata2;
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    const bodyData = {
      tier: "3",
      category: selectedCategory,
      city: mapCity,
      sub_category: newState,
      month: monthWiseData,
    };
    axios
      .post(tierUrl, bodyData, dataToken)
      .then((res) => {
        setData2(res?.data?.data);
        setValuesData2(res?.data?.data_name);
      })
      .catch((err) => console.log(err, "err"));
  };

  const category = () => {
    const tierUrl = upload_baseurl + categories;
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        tier: "3",
      },
    };
    axios
      .get(tierUrl, dataToken)
      .then((res) => {
        console.log(res?.data?.category_list, "go");
        let temp1 = res?.data?.category_list?.map((item) => ({
          label: item,
          value: item,
        }));
        setCityTier(temp1);
      })
      .catch((err) => console.log(err, "err"));
  };
  useEffect(() => {
    Graph_data();
    Graph_data2();
    category();
  }, [selectedCategory, mapCity, newState]);

  console.log("city", mapCity);

  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      labels: {
        style: {
          colors: "#fff",
        },
      },
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000,
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        horizontal: true,
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },

    dataLabels: {
      enabled: true,
      offsetX: 32,
    },
    xaxis: {
      categories: values,
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: true,
    },
    yaxis: {
      title: {
        // text: "Sales (in USD)",
        style: {
          color: "#fff", // Set the color of the Y-axis title
        },
      },
      labels: {
        style: {
          colors: "#fff", // Set the color of the Y-axis labels
        },
      },
    },
  };

  const chartSeries = [
    {
      data: datas,
    },
  ];

  const chartOptions2 = {
    chart: {
      type: "bar",
      height: 350,
      labels: {
        style: {
          colors: "#fff",
        },
      },
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000,
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        horizontal: true,
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },

    dataLabels: {
      enabled: true,
      offsetX: 32,
    },
    xaxis: {
      categories: values2,
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: true,
    },
    yaxis: {
      title: {
        // text: "Sales (in USD)",
        style: {
          color: "#fff", // Set the color of the Y-axis title
        },
      },
      labels: {
        style: {
          colors: "#fff", // Set the color of the Y-axis labels
        },
      },
    },
  };

  const chartSeries2 = [
    {
      data: datas2,
    },
  ];

  const callsubapi = (data) => {
    let api = base_url + "/mystore/mystore_sub_categories";
    setIsSubLoading(true);
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    axios
      .post(api, { category: data }, dataToken)
      .then((res) => {
        setSubData(res.data.category_list);
        console.log(res.data.category_list, "resdata");
        setIsSubLoading(false);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsSubLoading(false);
      });
  };
  return issubLoading ? (
    <Typography variant="h4" textAlign={"center"} color={"#ffff"}>
      ...loading
    </Typography>
  ) : (
    <>
      {" "}
      <Grid
        container
        mx={5}
        my={2}
        spacing={2}
        display={"flex"}
        justifyContent={"flex-start"}
      >
        <Grid item>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10px",
                color: "white",
              }}
            >
              <Typography variant="h5" my={1}>
                Category
              </Typography>
              <TextField
                style={{
                  backgroundColor: "#24304B",
                  fontFamily: "Nunito",
                  border: "none",
                  boxShadow: "none",
                }}
                color="primary"
                id="outlined-select-currency"
                select
                value={selectedCategory}
                size="small"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                onChange={(e) => {
                  callsubapi(e.target.value);
                  setSelectedCategory(e.target.value);
                }}
              >
                {cityTier.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label?.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </FormControl>
        </Grid>

        {subData.length != 0 && (
          <Grid item>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "10px",
                  color: "white",
                }}
              >
                <Typography variant="h5" my={1}>
                  Sub Category
                </Typography>
                <TextField
                  style={{
                    backgroundColor: "#24304B",
                    fontFamily: "Nunito",
                    border: "none",
                    boxShadow: "none",
                  }}
                  color="primary"
                  id="outlined-select-currency"
                  select
                  value={newState}
                  size="small"
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  onChange={(e) => {
                    // setSelectedCategory(e.target.value);
                    SetnewState(e.target.value);
                  }}
                >
                  {subData.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option.toUpperCase()}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} display={"flex"} justifyContent={"center"}>
        <Grid item>
          {" "}
          <Card
            xs={5}
            md={5}
            variant="outlined"
            sx={{
              background: "#24304B",
              boxShadow: "none",
              padding: "5px",
              borderRadius: "8px",
              mb: 8,
            }}
          >
            <Typography variant="h4" color={"white"} mx={1}>
              Category
            </Typography>
            <div id="chart">
              <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height={400}
                width={625}
              />
            </div>
          </Card>
        </Grid>
        <Grid item>
          {" "}
          <Card
            xs={5}
            md={5}
            variant="outlined"
            sx={{
              background: "#24304B",
              boxShadow: "none",
              padding: "5px",
              borderRadius: "8px",
              mb: 8,
            }}
          >
            <Typography variant="h4" color={"white"} mx={1}>
              SKU
            </Typography>
            <div id="chart">
              <ReactApexChart
                options={chartOptions2}
                series={chartSeries2}
                type="bar"
                height={400}
                width={625}
              />
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default SideBar;
