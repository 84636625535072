import React, { useEffect, useState } from "react";
import { upload_baseurl } from "../utils/utils";
import axios from "axios";

import "react-medium-image-zoom/dist/styles.css";
import ImgRotModal from "./ImgRotModal";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Alert, Snackbar, Typography } from "@mui/material";

function CredDetails({ dataToken, data, handleClose1, setFlag, flag }) {
  const [part1, setPart1] = useState([]);
  const [keyData, setKeyData] = React.useState([]);
  const [keyData2, setKeyData2] = React.useState([]);
  const [valuesData, setValuesData] = React.useState([]);
  const [valuesData2, setValuesData2] = React.useState([]);
  const [selectedImages, setSelectedImages] = useState();
  const [openImage, setOpenImage] = React.useState(false);
  const [rejectClicked, setRejectClicked] = useState(false);
  const [openM, setOpenM] = React.useState(false);
  const handleCloseM = () => setOpenM(false);
  const [reject1, setReject1] = useState(false);
  const [reject2, setReject2] = useState(false);
  const [app1, setapp1] = useState(false);
  const [app2, setapp2] = useState(false);

  const handleCloseR = () => setRejectClicked(false);
  const [remark, setRemark] = useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [statusSnack, setStatusSnack] = useState("success");

  const handleClickSnack = (val) => {
    setStatusSnack(val);
    setOpenSnack(true);
  };
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const handleOpenM = () => {
    setOpenM(true);
  };
  const handleOpenR = () => {
    setRejectClicked(true);
  };

  const tabledata1 = () => {
    const link = upload_baseurl + `/cred/part_1?lead_id=${data?.lead_id}`;
    axios
      .get(link, dataToken)
      .then((res) => {
        let keys = Object.keys(res?.data?.part_1[0]);
        setKeyData(keys);
        let values = Object.values(res?.data?.part_1[0]);
        setValuesData(values);
        setFlag(!flag);
      })
      .catch((err) => console.log(err));
  };

  const tabledata2 = () => {
    const link = upload_baseurl + `/cred/part_2?lead_id=${data?.lead_id}`;
    axios
      .get(link, dataToken)
      .then((res) => {
        let keys = Object.keys(res?.data?.part_2[0]);
        setKeyData2(keys);
        let values = Object.values(res?.data?.part_2[0]);
        setValuesData2(values);
        setFlag(!flag);
      })
      .catch((err) => console.log(err));
  };
  const Approve = () => {
    const link = upload_baseurl + "/cred/approve_part_1";

    let body = { lead_id: data?.lead_id };
    axios
      .patch(link, body, dataToken)

      .then((res) => {
        if (res.data.error) {
          handleClickSnack("error");
        } else {
          handleClickSnack("success");
        }
        setFlag(!flag);
      })
      .catch((err) => console.log(err));
  };
  const Approve2 = () => {
    const link = upload_baseurl + "/cred/approve_part_2";

    let body = { main_lead_id: data?.main_lead_id };
    axios
      .patch(link, body, dataToken)

      .then((res) => {
        if (res.data.error) {
          handleClickSnack("error");
        } else {
          handleClickSnack("success");
        }
        setFlag(!flag);
      })
      .catch((err) => console.log(err));
  };
  const reject_part1 = () => {
    const link = upload_baseurl + "/cred/reject_part_1";

    let body = { main_lead_id: data?.main_lead_id, remark: remark };
    axios
      .patch(link, body, dataToken)

      .then((res) => {
        if (res.data.error) {
          handleClickSnack("error");
        } else {
          handleClickSnack("success");
        }
        setFlag(!flag);
      })
      .catch((err) => console.log(err));
  };

  const reject_part2 = () => {
    const link = upload_baseurl + "/cred/redo_part_2";

    let body = { main_lead_id: data?.main_lead_id, remark: remark };
    axios
      .patch(link, body, dataToken)

      .then((res) => {
        if (res.data.error) {
          handleClickSnack("error");
        } else {
          handleClickSnack("success");
        }
        setFlag(!flag);
      })
      .catch((err) => console.log(err));
  };

  const Show = () => {
    const link = upload_baseurl + `/cred/show_part_2`;

    let body = { lead_id: data?.lead_id };
    axios
      .patch(link, body, dataToken)

      .then((res) => {
        if (res.data.error) {
          handleClickSnack("error");
        } else {
          handleClickSnack("success");
        }
        setFlag(!flag);
      })
      .catch((err) => console.log(err));
  };

  const handleOpenImage = () => {
    setOpenImage(true);
  };
  const updatedKeyData = keyData.filter((item) => {
    return (
      !String(item)?.includes("stage1_status") &&
      !String(item)?.includes("stage2_status")
    );
  });
  const updatedKeyData2 = keyData2.filter((item) => {
    return (
      !String(item)?.includes("stage1_status") &&
      !String(item)?.includes("stage2_status")
    );
  });

  const handleCloseImage = () => setOpenImage(false);
  useEffect(() => {
    tabledata1();
    tabledata2();
  }, []);

  console.log(updatedKeyData2, "mark");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#24304B",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 4,
    px: 4,
    pb: 4,
  };
  return (
    <>
      <div
        style={{
          display:
            (data?.stage1_status == "0" && data?.stage2_status == "0") ||
            (data?.stage1_status == "1" && data?.stage2_status == "0")
              ? ""
              : "none",
        }}
      >
        {" "}
        {sessionStorage?.getItem("admin") == "client" &&
        data?.stage1_status == "0" &&
        data?.stage2_status == "0" ? (
          <div>
            {" "}
            <Button
              variant="contained"
              sx={{ m: 2, px: 2, float: "right" }}
              color="success"
              onClick={() => {
                handleOpenM(true);
                setapp1(!app1);
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              sx={{ m: 2, px: 2, float: "right" }}
              color="error"
              onClick={() => {
                handleOpenR();
                setReject1(true);
              }}
            >
              Reject
            </Button>
          </div>
        ) : sessionStorage?.getItem("admin") == "admin" &&
          data?.stage1_status == "1" &&
          data?.stage2_status == "0" ? (
          <div>
            <Button
              variant="contained"
              sx={{ m: 2, px: 2, float: "right" }}
              color="secondary"
              onClick={() => {
                Show();
                handleClose1();
              }}
            >
              Show
            </Button>
          </div>
        ) : (
          ""
        )}
        {updatedKeyData?.map((item, i) => (
          <div
            key={i}
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              color: "#fffff",
            }}
          >
            <div
              style={{
                border: "1px solid #a3b5d5",
                borderRight: "0px solid  #a3b5d5",
                borderBottom:
                  i == keyData.length - 1
                    ? "1px solid  #a3b5d5"
                    : "0px solid  #a3b5d5",
                width: "40%",
                height: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "capitalize",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              <p style={{ color: "white" }}>
                {" "}
                {item?.includes("_") ? item.split("_").join(" ") : item}
              </p>
            </div>
            <div
              style={{
                border: "1px solid  #a3b5d5",
                borderBottom:
                  i == keyData.length - 1
                    ? "1px solid  #a3b5d5"
                    : "0px solid  #a3b5d5",
                width: "60%",
                height: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {String(valuesData[i])?.includes("https") &&
              !String(valuesData[i])?.includes(".mp4") ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    role="img"
                    style={{
                      width: "100px",
                      height: "120px",
                      backgroundImage: "url(" + valuesData[i] + ")",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                    }}
                    onClick={() => {
                      handleOpenImage();
                      setSelectedImages(valuesData[i]);
                    }}
                  />
                  <ImgRotModal
                    openImage={openImage}
                    handleCloseImage={handleCloseImage}
                    imageurl={selectedImages}
                  />
                </div>
              ) : String(valuesData[i])?.includes(".mp4") ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <video
                    style={{
                      width: "100px",
                      height: "120px",
                      objectFit: "contain",
                    }}
                    controls
                  >
                    <source src={valuesData[i]} type="video/mp4" />
                  </video>
                </div>
              ) : valuesData[i] == "" ? (
                "N/A"
              ) : (
                <p style={{ color: "white" }}> {valuesData[i]}</p>
              )}
            </div>
          </div>
        ))}
      </div>
      {
        <div
          style={{
            display:
              data?.stage1_status == "1" && data?.stage2_status == "1"
                ? ""
                : "none",
          }}
        >
          {" "}
          {sessionStorage?.getItem("admin") === "client" &&
          data?.stage1_status == "1" &&
          data?.stage2_status == "1" ? (
            <div>
              {" "}
              <Button
                variant="contained"
                sx={{ m: 2, px: 2, float: "right" }}
                color="success"
                //onClick={Approve2}
                onClick={() => {
                  handleOpenM(true);
                  setapp2(!app2);
                }}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                sx={{ m: 2, px: 2, float: "right" }}
                color="warning"
                onClick={() => {
                  handleOpenR();
                  setReject2(true);
                }}
              >
                Rework
              </Button>
            </div>
          ) : (
            ""
          )}
          {updatedKeyData2?.map((item, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                color: "#fffff",
              }}
            >
              <div
                style={{
                  border: "1px solid #a3b5d5",
                  borderRight: "0px solid  #a3b5d5",
                  borderBottom:
                    i == keyData.length - 1
                      ? "1px solid  #a3b5d5"
                      : "0px solid  #a3b5d5",
                  width: "40%",
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "capitalize",
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                <p style={{ color: "white" }}>
                  {" "}
                  {item?.includes("_") ? item.split("_").join(" ") : item}
                </p>
              </div>
              <div
                style={{
                  border: "1px solid  #a3b5d5",
                  borderBottom:
                    i == keyData.length - 1
                      ? "1px solid  #a3b5d5"
                      : "0px solid  #a3b5d5",
                  width: "60%",
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                {String(valuesData2[i])?.includes("https") &&
                !String(valuesData2[i])?.includes(".mp4") ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      role="img"
                      style={{
                        width: "100px",
                        height: "120px",
                        backgroundImage: "url(" + valuesData2[i] + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                      }}
                      onClick={() => {
                        handleOpenImage();
                        setSelectedImages(valuesData2[i]);
                      }}
                    />
                    <ImgRotModal
                      openImage={openImage}
                      handleCloseImage={handleCloseImage}
                      imageurl={selectedImages}
                    />
                  </div>
                ) : String(valuesData2[i])?.includes(".mp4") ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <video
                      style={{
                        width: "100px",
                        height: "120px",
                        objectFit: "contain",
                      }}
                      controls
                    >
                      <source src={valuesData2[i]} type="video/mp4" />
                    </video>
                  </div>
                ) : valuesData2[i] == "" ? (
                  "N/A"
                ) : (
                  <p style={{ color: "white" }}> {valuesData2[i]}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      }
      <Modal
        open={rejectClicked}
        onClose={handleCloseR}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <div>
            <label
              style={{
                color: "#ffff ",
                fontWeight: 600,
                fontSize: 15,
                marginLeft: 10,
              }}
            >
              Remark
            </label>
            <textarea
              className="form-control"
              placeholder="Enter Remark"
              id="des-info-description-input"
              rows="5"
              style={{
                width: 500,
                margin: 10,
                padding: 10,
                borderRadius: 8,
                backgroundColor: "#e3ecff",
                border: "none",
                color: "#00000",
                outline: "none",
              }}
              onChange={(e) => {
                setRemark(e.target.value);
              }}
            ></textarea>
          </div>

          <Button
            variant="contained"
            sx={{
              mr: 3,
              mt: 2,
              width: 100,
            }}
            onClick={() => {
              setRejectClicked(false);

              if (reject1) {
                reject_part1();
              } else if (reject2) {
                reject_part2();
              }
              handleClose1();
            }}
            color="success"
          >
            Submit
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openM}
        onClose={handleCloseM}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color="#ffff"
            // textAlign="center"
          >
            Are you sure ?
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              sx={{ mx: 1, mt: 1 }}
              onClick={(e) => {
                handleCloseM();
                handleClose1();
                if (app1) {
                  Approve();
                } else if (app2) {
                  Approve2();
                }
              }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              sx={{ mx: 1, mt: 1 }}
              onClick={handleCloseM}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={statusSnack}
          sx={{ width: "100%" }}
        >
          {statusSnack === "success"
            ? "Success!"
            : "OOPS...! SOMETHING's wrong"}
        </Alert>
      </Snackbar>
    </>
  );
}

export default CredDetails;
