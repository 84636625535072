import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { colors } from "@mui/material";
import Grid from "@mui/material/Grid";
function MapDATA({ hname, Cardvalue, svg, percentage }) {
  return (
    <div>
      <Box>
        <Card
          variant="outlined"
          sx={{
            backgroundColor: "#24314B",
            // width: 373,
            // width: "90%",
            // height: 122,
            // height: "15.5vh",
            minHeight: 122,
          }}
          style={{
            //background: 'linear-gradient(315deg, #283650, #212d44)',
            boxShadow: "9px 9px 0px #394e74",
          }}
        >
          {" "}
          <CardContent>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ color: "#fff" }}
            >
              <Grid item>
                <Grid
                  container
                  direction="column"
                  alignItems={"start"}
                  justifyContent={"space-between"}
                  gap="10px"
                >
                  <Grid item>
                    <Typography variant="subtitle2" color={"#e6e6e6"}>
                      {hname}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "32px", fontWeight: "600" }}
                    >
                      {Cardvalue}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction={"column"}
                  style={{ alignItems: "flex-end" }}
                >
                  <img src={svg} style={{ padding: 5, margin: 5 }} />

                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "200",
                      padding: "5px 13px",
                      borderRadius: "5px",
                      color: percentage > 0 ? "#46ECBB" : "#FFB738",
                    }}
                  >
                    +{percentage}
                  </Typography>

                  <Grid item>
                    <Typography
                      sx={(colors = "#97A7CA")}
                      style={{ fontSize: 7, fontWeight: 400, padding: 10 }}
                    >
                      Today
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}

export default MapDATA;
