import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import geojson from "../JSON/Jaipur.json";
import MapDATA from "../MapData";
import { Card, Grid } from "@mui/material";

mapboxgl.accessToken =
  "pk.eyJ1Ijoia2FydGlra3NoZXR0YXIiLCJhIjoiY2xpMWNqdWFkMDd1eDNsbzNwM2MyemczaCJ9.1NnoucNrjFINkPyLPcpf8g";

function Jaipur({ mapData, mapCity }) {
  const [singleAreaData, setSingleAreaData] = React.useState({});
  const mapContainerRef = useRef(null);
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const popup = new mapboxgl.Popup({
    closeButton: false,
  });

  const COLOR_RANGE_1 = ["#95d5b2", "#40916c", "#74c69d"];
  const COLOR_RANGE_2 = ["#74c69d", "#95d5b2", "#40916c"];
  const COLOR_RANGE_3 = ["#40916c", "#74c69d", "#95d5b2"];

  let current;

  const Hot = [
    { pincode: 411001, total_leads: "1" },
    { pincode: 411020, total_leads: "30" },
    { pincode: 411022, total_leads: "26" },
    { pincode: 410301, total_leads: "31" },
  ];

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [75.63, 27.171],
      zoom: 7.9,
      dragPan: false,
      doubleClickZoom: false,
      attributionControl: false,
    });

    map.on("load", () => {
      const maxLead = Math.max(
        ...mapData.map((item) => parseInt(item.total_leads))
      );
      const minLead = Math.min(
        ...mapData.map((item) => parseInt(item.total_leads))
      );

      const leadRange = (maxLead - minLead) / 3;

      const set1 = mapData.filter(
        (item) =>
          parseInt(item.total_leads) >= minLead &&
          parseInt(item.total_leads) < minLead + leadRange
      );
      const set2 = mapData.filter(
        (item) =>
          parseInt(item.total_leads) >= minLead + leadRange &&
          parseInt(item.total_leads) < minLead + leadRange * 2
      );
      const set3 = mapData.filter(
        (item) => parseInt(item.total_leads) >= minLead + leadRange * 2
      );
      var style = map.getStyle();
      map.addLayer(
        {
          id: "background-layer",
          type: "background",
          paint: {
            "background-color": "#24304B",
          },
        },
        "waterway-label"
      );
      map.addSource("my-data", {
        type: "geojson",
        data: geojson,
      });

      map.addLayer({
        id: "heatmap-layer",
        type: "fill",
        source: "my-data",
        paint: {
          "fill-color": [
            "case",
            [
              "in",
              ["get", "pincode"],
              ["literal", set1.map((item) => item.pincode)],
            ],
            [
              "interpolate",
              ["linear"],
              ["to-number", ["get", "lead"]],
              minLead,
              COLOR_RANGE_1[0],
              minLead + leadRange,
              COLOR_RANGE_1[1],
              minLead + leadRange * 2,
              COLOR_RANGE_1[2],
            ],
            [
              "in",
              ["get", "pincode"],
              ["literal", set2.map((item) => item.pincode)],
            ],
            [
              "interpolate",
              ["linear"],
              ["to-number", ["get", "lead"]],
              minLead + leadRange,
              COLOR_RANGE_2[0],
              minLead + leadRange * 2,
              COLOR_RANGE_2[1],
              maxLead,
              COLOR_RANGE_2[2],
            ],
            [
              "in",
              ["get", "pincode"],
              ["literal", set3.map((item) => item.pincode)],
            ],
            [
              "interpolate",
              ["linear"],
              ["to-number", ["get", "lead"]],
              minLead + leadRange * 2,
              COLOR_RANGE_3[0],
              maxLead,
              COLOR_RANGE_3[1],
            ],
            "#b7e5c7",
          ],
          "fill-opacity": 1,
        },
      });
      map.addLayer({
        id: "heatmap-layer-line",
        type: "line",
        source: "my-data",
        paint: {
          "line-color": "#a9a9a9",
          "line-width": 0.1, // Modify this value to change the border width
        },
      });
      map.on("mousemove", "heatmap-layer", (e) => {
        let obj = { ...current };
        setSingleAreaData(obj);

        current = mapData.find(
          (s) => s.pincode == e.features[0].properties.pincode
        );

        map.getCanvas().style.cursor = "pointer";
        if (e.features.length > 0) {
          if (hoveredFeature) {
            map.setFeatureState(
              { source: "my-data", id: hoveredFeature },
              { hover: false }
            );
          }
          setHoveredFeature(e.features[0].properties.pincode);
          console.log(e.features[0], "pin");
          map.setFeatureState(
            { source: "my-data", id: hoveredFeature },
            { hover: true }
          );
        }

        const coordinates = e.lngLat;
        const name = e.features[0].properties.officename;
        console.log(coordinates, name, "la");
        popup
          .setLngLat(coordinates)
          .setHTML(`<p >Area : ${name}</p>`)
          .addTo(map);
      });
      map.on("mouseleave", "heatmap-layer", () => {
        if (hoveredFeature) {
          map.setFeatureState(
            { source: "my-data", id: hoveredFeature },
            { hover: false }
          );
        }
        setHoveredFeature(null);

        map.getCanvas().style.cursor = "";
        popup.remove();
      });

      style.layers.forEach(function (layer) {
        // Check if the layer is a label layer
        if (layer.type === "symbol" && layer.layout["text-field"]) {
          // Hide the label layer
          map.setLayoutProperty(layer.id, "visibility", "none");
        }
      });
    });

    map.scrollZoom.disable(); // disable zoom on scroll
    console.log(singleAreaData, mapData, "fd");
    return () => {
      map.remove();
    };
  }, [mapData, mapCity]);

  return (
    <Card sx={{ backgroundColor: "#24314B", p: 2 }}>
      <Grid
        container
        direction="row"
        item
        xs={12}
        md={12}
        spacing={5}
        style={{ marginTop: "1px" }}
        display={"flex"}
        justifyContent={"space-between"}
        margin={1}
      >
        <Grid item xs={3}>
          <Grid container direction="column" spacing={4}>
            <Grid item lg={3} md={6} xs={12}>
              <MapDATA
                hname="Total Leads"
                //Cardvalue="200"
                Cardvalue={singleAreaData?.total_leads ?? 0}
                svg=""
                //percentage="200"
              />
            </Grid>
            {/* <Grid item lg={3} md={6} xs={12}>
              <MapDATA
                hname="Example"
                Cardvalue="200"
                svg=""
                percentage="200"
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <MapDATA
                hname="Example"
                Cardvalue="200"
                svg=""
                percentage="200"
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <MapDATA
                hname="Example"
                Cardvalue="200"
                svg=""
                percentage="200"
              />
            </Grid> */}
          </Grid>
        </Grid>
        {/* <Grid item xs={3}>
          <Grid container direction="column" spacing={4}>
            <Grid item lg={3} md={6} xs={12}>
              <MapDATA
                hname="Example"
                Cardvalue="200"
                svg=""
                percentage="200"
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <MapDATA
                hname="Example"
                Cardvalue="200"
                svg=""
                percentage="200"
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <MapDATA
                hname="Example"
                Cardvalue="200"
                svg=""
                percentage="200"
              />
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item>
          <Grid container direction="column" lg={8} xs={12}>
            <div
              ref={mapContainerRef}
              style={{
                width: "750px",
                height: "600px",
                margin: 10,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default Jaipur;
