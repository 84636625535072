import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { CSVLink, CSVDownload } from "react-csv";
import { Button, Grid } from "rsuite";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { TurnedIn } from "@mui/icons-material";
import { useState } from "react";
import NammaYatriModalData from "../pages/NammaYatriModalData";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
const customStyles = {
  rows: {
    style: {
      minHeight: "42px", // override the row height
    },
  },
  headCells: {
    style: {
      //   paddingLeft: "30px", // override the cell padding for head cells
      // paddingRight: "8px",
      backgroundColor: "#474A86",
      color: "#fff",
    },
  },
  // cells: {
  //   style: {
  //     paddingLeft: "8px", // override the cell padding for data cells
  //     paddingRight: "8px",
  //   },
  // },
  pagination: {
    style: {
      backgroundColor: "#33355F",
      color: "#ffff",
    },
  },
};

const customStyles2 = {
  rows: {
    style: {
      minHeight: "42px",
      backgroundColor: "#24304B", // override the row height
    },
  },
  headCells: {
    style: {
      //   paddingLeft: "30px", // override the cell padding for head cells
      // paddingRight: "8px",
      backgroundColor: "#24304B",
      color: "#fff",
    },
  },
  cells: {
    style: {
      backgroundColor: "#24304B",
    },
  },
  pagination: {
    style: {
      backgroundColor: "#24304B",
      color: "#ffff",
    },
  },
};

const conditionalRowStyles = [
  {
    when: (row) => Number(row.id) % 2 === 0,
    style: {
      background: "#3e4169",
      border: "none",
      color: "white",
      cursor: "pointer",
      fontSize: "12px",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  {
    when: (row) => Number(row.id) % 2 !== 0,
    style: {
      background: "#32355F",
      border: "none",
      color: "white",
      cursor: "pointer",
      fontSize: "12px",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
];

const columns = [
  {
    name: "Date",
    selector: (row) => row.date,
    cell: (d) => <>{String(d.date)}</>,
  },
  {
    name: "Rest Name",
    selector: (row) => row.rest_name,
    cell: (d) => <>{String(d.rest_name)}</>,
  },
  {
    name: "City",
    selector: (row) => row.city,
    cell: (d) => <>{String(d.city)}</>,
  },
  {
    name: "Pincode",
    selector: (row) => row.pincode,

    cell: (d) => <>{d.pincode}</>,
  },
  {
    name: "Mapping Due",
    selector: (row) => row.mapping_due,
    cell: (d) => <>{String(d.mapping_due)}</>,
  },
  {
    name: "Mapping Done",
    selector: (row) => row.mapping_done,
    cell: (d) => <>{String(d.mapping_done)}</>,
  },
  {
    name: "Restaurant Live",
    selector: (row) => row.res_live,
    cell: (d) => <>{String(d.res_live)}</>,
    // cell: (d) => (
    //   <div>{d.res_live == "Approve" ? String("Yes") : String("No")}</div>
    // ),
  },
];

const MainTableData = ({
  data,
  name,
  changeOnUpdateNamma,
  setChangeOnUpdateNamma,
}) => {
  console.log(data, "testinfdata");
  const columns2 = [
    {
      name: "Date",
      selector: (row) => row.date,
      // cellExport: (row) => String(row.date),
      cell: (d) => <>{d.date}</>,
    },
    {
      name: "Name",
      selector: (row) => String(row.merchant_name),
      // cellExport: (row) => String(row.merchant_name),
      cell: (d) => <>{d.merchant_name}</>,
    },
    {
      name: "Vehicle Number",
      selector: (row) => row.rc_number,
      // cellExport: (row) => String(row.rc_number),
      cell: (d) => <>{d.rc_number}</>,
    },
    {
      name: "City",
      selector: (row) => row.city,
      // cellExport: (row) => String(row.city),
      cell: (d) => <>{d.city}</>,
    },

    {
      name: "State",
      selector: (row) => row.state,
      // cellExport: (row) => String(row.state),
      cell: (d) => <>{d.state}</>,
    },
    {
      name: "Count",
      selector: (row) => row.ride_count,
      // cellExport: (row) => String(row.ride_count),
      cell: (d) => <>{d.ride_count}</>,
    },
    {
      name: " Status",
      selector: (row) => row.client_status,
      cell: (d) => <>{d.client_status}</>,
    },
    {
      name: " Qc Remark",
      omit: true,
      selector: (row) => row.client_qc_remark,
      cell: (d) => <>{d.client_qc_remark}</>,
    },
    {
      name: " Approved By",
      omit: false,
      selector: (row) => row.client_name,
      cell: (d) => <>{d.client_name}</>,
    },
    {
      name: "View Details",
      cell: (d) => (
        <div>
          <FileOpenIcon onClick={() => handleClickOpen(d)} />
        </div>
      ),
    },
  ];

  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("xl");
  const [dataEach, setDataEach] = useState({});

  const handleClickOpen = (d) => {
    console.log(d, "checkd");
    setDataEach(d);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tableData = {
    columns: name == "nammayatri" ? columns2 : columns,
    data,
  };
  return (
    <div>
      <div
        // style={{  }}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          visibility:
            data?.length == 0 || data == undefined ? "hidden" : "show",
        }}
      >
        <CSVLink filename="Report.csv" data={data ?? []}>
          <IconButton variant="text">
            <DownloadIcon style={{ color: "white" }} />
          </IconButton>
        </CSVLink>
      </div>
      <DataTableExtensions
        // {...tableData}
        columns={name == "nammayatri" ? columns2 : columns}
        data={data}
        export={false}
        print={false}
        exportHeaders
        fileName={name != "nammyatri" ? "Onboarding Report" : ""}
        filterPlaceholder={"Search Data"}
      >
        <DataTable
          columns={name == "nammayatri" ? columns2 : columns}
          data={data}
          paginationRowsPerPageOptions={[10, 30, 50, 100]}
          fixedHeader={true}
          fixedHeaderScrollHeight={"430px"}
          pagination
          conditionalRowStyles={conditionalRowStyles}
          customStyles={name == "nammayatri" ? customStyles2 : customStyles}
        />
      </DataTableExtensions>

      <Dialog fullScreen maxWidth={maxWidth} open={open} onClose={handleClose}>
        <DialogTitle
          style={{
            // boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
            backgroundColor: "#24304B",
            color: "white",
            width: "100%",
            // borderRadius: "8px",
            //   color: "white",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>View Details</div>
            <div onClick={handleClose}>
              <HighlightOffOutlinedIcon style={{ cursor: "pointer" }} />
            </div>
          </div>
        </DialogTitle>

        <DialogContent
          style={{
            // boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
            backgroundColor: "#24304B",
            // borderRadius: "8px",
            //   color: "white",
          }}
        >
          <NammaYatriModalData
            data={dataEach}
            closeMainModal={handleClose}
            changeOnUpdateNamma={changeOnUpdateNamma}
            setChangeOnUpdateNamma={setChangeOnUpdateNamma}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MainTableData;
