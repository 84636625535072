import React, { useState } from "react";
import { upload_baseurl, upload_url } from "../utils/utils";
import axios from "axios";

function Imagesup({ valuesData, item, setAll, all, data, resurl, setResurl }) {
  const [selectedImages, setSelectedImages] = useState({});

  const handleImageChange = (e, item) => {
    if (e && e.target) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const updatedImages = {
          ...selectedImages,
          [item]: {
            name: file.name,
            file: file,
            url: reader.result,
          },
        };
        setSelectedImages(updatedImages);

        const updatedAll = {
          ...all,
          [item]: reader.result,
        };
        setAll(updatedAll);
      };

      if (file) {
        reader.readAsDataURL(file);
      } else {
        setSelectedImages((prevImages) => {
          const updatedImages = { ...prevImages };
          delete updatedImages[item];
          return updatedImages;
        });

        setAll((prevAll) => {
          const updatedAll = { ...prevAll };
          delete updatedAll[item];
          return updatedAll;
        });
      }
    } else {
      setSelectedImages((prevImages) => {
        const updatedImages = { ...prevImages };
        delete updatedImages[item];
        return updatedImages;
      });

      setAll((prevAll) => {
        const updatedAll = { ...prevAll };
        delete updatedAll[item];
        return updatedAll;
      });
    }
  };

  const uploadDoc = (e, item) => {
    const path = upload_baseurl + upload_url;
    const axiosData = new FormData();
    axiosData.append("file", e.target.files[0]);
    axios
      .post(path, axiosData)
      .then((res) => {
        const updatedAll = {
          ...all,
          main_lead_id: data?.main_lead_id,
          [item]: res?.data?.results?.url,
        };

        setResurl(res?.data?.results?.url);

        setAll(updatedAll);
      })
      .catch((err) => console.log(err, "err"));
  };

  return (
    <>
      {selectedImages[item] && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: 10,
            border: "1px solid black",
            padding: 10,
            width: "-webkit-fill-available",
            border: "0.2px solid white",
            cursor: "pointer",
            width: 850,
          }}
        >
          <img
            src={selectedImages[item]?.url}
            alt="Selected"
            style={{ height: 20, marginRight: 5 }}
          />
          <span>{selectedImages[item].name}</span>
          <span
            onClick={() => {
              handleImageChange(null, item); // Reset the selected image
              setAll({ ...all, [item]: "" });
            }}
          >
            &#10006;
          </span>
        </div>
      )}
      {!selectedImages[item] && (
        <label>
          <input
            id="image-upload"
            type="file"
            accept="image/*"
            onChange={(e) => {
              handleImageChange(e, item);
              uploadDoc(e, item);
              console.log(resurl, "save");
            }}
            style={{
              margin: 10,
              border: "1px solid black",
              padding: 10,
              width: "-webkit-fill-available",
              border: "0.2px solid white",
            }}
          />
        </label>
      )}
    </>
  );
}

export default Imagesup;
