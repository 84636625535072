import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { scaleQuantile } from "d3-scale";
import ReactTooltip from "react-tooltip";
import testPin from "./csvjson.json";
import LinearGradient from "./LinearGradient.js";

import "./Maps.css";

const INDIA_TOPO_JSON = require("./blrrr.json");
// const PUNE_TOPO_JSON = require("./pune.json");
const PUNE_TOPO_JSON = require("./pinPune.json");
const MUMBAI_TOPO_JSON = require("./m.json");
const HYDERABAD_TOPO_JSON = require("./hyderabad.json");

// const INDIA_TOPO_JSON = require("./Blr.json");

const PROJECTION_CONFIG_PUNE = {
  // scale: 82850,
  // center: [73.8929, 18.4637],
  scale: 150000,
  center: [73.8929, 18.4637],
};

const PROJECTION_CONFIG_BANGLORE = {
  scale: 47850,
  center: [77.5929, 12.8737],
};

const PROJECTION_CONFIG_HYDERABAD = {
  scale: 50850,
  center: [78.4729, 17.4037],
};

const PROJECTION_CONFIG_MUMBAI = {
  scale: 60850,
  center: [72.8729, 19.0099],
};

const COLOR_RANGE = [
  //   "#ffedea",

  "#95d5b2",
  "#74c69d",
  "#40916c",

  //   "#d8f3dc",
];

const DEFAULT_COLOR = "#b7e4c7";

const getRandomInt = () => {
  return parseInt(Math.random() * 100);
};

const geographyStyle = {
  default: {
    // fill: "D6D6DA",
    outline: "none",
  },
  hover: {
    fill: "#ccc",
    transition: "all 250ms",
    outline: "black",
  },
  pressed: {
    outline: "none",
  },
};

// will generate random heatmap data on every call
const getHeatMapData = () => {
  return [
    { id: "750", state: "Telangana", value: getRandomInt() },
    { id: "AR", state: "Arunachal Pradesh", value: getRandomInt() },
    { id: "AS", state: "Assam", value: getRandomInt() },
    { id: "BR", state: "Bihar", value: getRandomInt() },
    { id: "CT", state: "Chhattisgarh", value: getRandomInt() },
    { id: "GA", state: "Goa", value: 21 },
    { id: "GJ", state: "Gujarat", value: 22 },
    { id: "HR", state: "Haryana", value: getRandomInt() },
    { id: "HP", state: "Himachal Pradesh", value: 24 },
    { id: "JH", state: "Jharkhand", value: 26 },
    { id: "KA", state: "Karnataka", value: 27 },
    { id: "KL", state: "Kerala", value: getRandomInt() },
    { id: "MP", state: "Madhya Pradesh", value: getRandomInt() },
    { id: "MH", state: "Maharashtra", value: getRandomInt() },
    { id: "MN", state: "Manipur", value: getRandomInt() },
    { id: "ML", state: "Meghalaya", value: 59 },
    { id: "MZ", state: "Mizoram", value: getRandomInt() },
    { id: "NL", state: "Nagaland", value: 59 },
    { id: "OR", state: "Odisha", value: 59 },
    { id: "PB", state: "Punjab", value: getRandomInt() },
    { id: "RJ", state: "Rajasthan", value: getRandomInt() },
    { id: "SK", state: "Sikkim", value: getRandomInt() },
    { id: "TN", state: "Tamil Nadu", value: getRandomInt() },
    { id: "TG", state: "Telangana", value: getRandomInt() },
    { id: "TR", state: "Tripura", value: 14 },
    { id: "UT", state: "Uttarakhand", value: getRandomInt() },
    { id: "UP", state: "Uttar Pradesh", value: 15 },
    { id: "WB", state: "West Bengal", value: 17 },
    { id: "WB", state: "West Bengal", value: 17 },
    { id: "AN", state: "Andaman and Nicobar Islands", value: getRandomInt() },
    { id: "CH", state: "Chandigarh", value: getRandomInt() },
    { id: "DN", state: "Dadra and Nagar Haveli", value: 19 },
    { id: "DD", state: "Daman and Diu", value: 20 },
    { id: "DL", state: "Delhi", value: 59 },
    { id: "JK", state: "Jammu and Kashmir", value: 25 },
    { id: "LA", state: "Ladakh", value: getRandomInt() },
    { id: "LD", state: "Lakshadweep", value: getRandomInt() },
    { id: "PY", state: "Puducherry", value: getRandomInt() },
  ];
};

function MapFalcon({
  data2,
  dataGrow,
  singleAreaData,
  setSingleAreaData,
  appdatafrom,
  mapCity,
}) {
  console.log(mapCity, "testing", "falconheavy", data2);
  const [tooltipContent, setTooltipContent] = useState("");
  const [data, setData] = useState(getHeatMapData());
  console.log(dataGrow ,'dg')
  const colorScale = scaleQuantile()
    .domain(data.map((d) => d.value))
    .range(COLOR_RANGE);
console.log(singleAreaData,'kk')
  const onMouseEnter = (geo, current = {}, currentGrow = {}) => {
    // setSingleAreaData(curre)

    return () => {
      // setTooltipContent(`${geo.properties.area_name}: ${current.lead_id ?? 0}`);
      //   setTooltipContent(
      //     `${geo.properties.tags.name}: ${current.lead_id ?? 222}`
      //   );
      //   console.log(geo, current, "checkononon");

      let obj = { ...current };
      obj.grow = currentGrow;

console.log(current,'jj')
      if (mapCity == "Bengaluru") {
        obj.name = geo.properties.officename;
        setTooltipContent(`${geo.properties.officename}`);
      } else if (mapCity == "Pune") {
        // obj.name = geo.properties.Name2.split("-")[0];
        // setTooltipContent(`${geo.properties.Name1}`);
      } else if (mapCity == "Hyderabad") {
        obj.name = geo.properties.area_name;
        setTooltipContent(`${geo.properties.area_name}`);
      } else if (mapCity == "Mumbai") {
        obj.name = geo.properties.area_name;
        setTooltipContent(`${geo.properties.area_name}`);
      }

      setSingleAreaData(obj);
    };
  };

  const onMouseLeave = () => {
    setTooltipContent("");
  };

  const gradientData = {
    fromColor: COLOR_RANGE[0],
    toColor: COLOR_RANGE[COLOR_RANGE.length - 1],
    min: 0,
    max: data2.reduce(
      (max, item) => (item.total_onboarded > max ? item.total_onboarded : max),
      0
    ),
  };

  return (
    <div>
      <LinearGradient data={gradientData} />
      <br />
      <ReactTooltip>{tooltipContent}</ReactTooltip>
      <ComposableMap
        projectionConfig={
          mapCity == "Pune"
            ? PROJECTION_CONFIG_PUNE
            : mapCity == "Bengaluru"
            ? PROJECTION_CONFIG_BANGLORE
            : mapCity == "Hyderabad"
            ? PROJECTION_CONFIG_HYDERABAD
            : mapCity == "Mumbai"
            ? PROJECTION_CONFIG_MUMBAI
            : ""
        }
        projection="geoMercator"
        // width={5500}
        // height={900}
        // height={5500}
        data-tip=""
      >
        <Geographies
          //   geography={INDIA_TOPO_JSON}
          geography={
            mapCity == "Pune"
              ? PUNE_TOPO_JSON
              : mapCity == "Bengaluru"
              ? INDIA_TOPO_JSON
              : mapCity == "Hyderabad"
              ? HYDERABAD_TOPO_JSON
              : mapCity == "Mumbai"
              ? MUMBAI_TOPO_JSON
              : ""
          }
          stroke="#fff"
          strokeWidth="0.015"
        >
          {({ geographies }) =>
            geographies.map((geo) => {
              console.log(geo, "geoid");
              let current;
              let currentGrow;
              if (mapCity == "Bengaluru") {
                current = data2.find(
                  (s) => s.pincode == geo.properties.pincode
                );
                currentGrow = dataGrow.find(
                  (s) => s.pincode == geo.properties.pincode
                );
              } else if (mapCity == "Pune") {
                current = data2.find(
                  (s) => s.pincode == geo.properties.pincode
                );
                currentGrow = dataGrow.find(
                  (s) => s.pincode == geo.properties.pincode
                );
              } else if (mapCity == "Hyderabad") {
                current = data2.find(
                  (s) => s.pincode == geo.properties.pin_code
                );
                console.log(data2, "testingcheck");
                currentGrow = dataGrow.find(
                  (s) => s.pincode == geo.properties.pin_code
                );
              } else if (mapCity == "Mumbai") {
                current = data2.find(
                  (s) => s.pincode == geo.properties.pin_code
                );
                currentGrow = dataGrow.find(
                  (s) => s.pincode == geo.properties.pin_code
                );
              }
              //   let current = data2.find(
              //     (s) => s.pincode === geo.properties.pincode
              //   );
              console.log("fasakinnn", current,data2);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={
                    current
                      ? colorScale(current.total_onboarded)
                      : DEFAULT_COLOR
                  }
                  style={geographyStyle}
                  onMouseEnter={onMouseEnter(geo, current, currentGrow)}
                  onMouseLeave={onMouseLeave}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
}

export default MapFalcon;
