import React, { useState } from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import three from "../Assets/three-dots-vertical.svg";
import EditModal from "./EditModal";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import N_storemodal from "./N_storemodal";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
      border: "none",
      backgroundColor: "#24304B",
    },
  },
  headCells: {
    style: {
      padding: 15,
      color: "#FFFFFF",
      backgroundColor: "#141D33",
      justifyContent: "center",
    },
  },
  cells: {
    style: {
      backgroundColor: "#24304B",
      color: "#ffff",
      justifyContent: "center",
      fill: "white",
    },
  },
  table: {
    style: {
      minHeight: "400px",
    },
  },
  pagination: {
    style: {
      backgroundColor: "#24304B",
      color: "#ffff",
    },
  },
};
const conditionalRowStyles = [
  {
    when: (row) => Number(row.id) % 2 === 0,
    style: {
      background: "#24304B",
      border: "none",
      color: "white",
      cursor: "pointer",
      fontSize: "12px",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  {
    when: (row) => Number(row.id) % 2 !== 0,
    style: {
      background: "#24304B",
      border: "none",
      color: "white",
      cursor: "pointer",
      fontSize: "12px",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
];

function DTable({ data, setFlag, flag }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedData, setSelectedData] = useState({});
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "Date",
      selector: (row) => row.merchant_name,
      width: "130px",
      sortable: true,
      cell: (d) => (
        <div style={{ cursor: "pointer" }} className="text-info">
          <u>
            <span style={{ padding: 5, fontSize: 12 }}>{d.created_on}</span>
          </u>
        </div>
      ),
    },

    {
      name: "Merchant Name",
      selector: (row) => row.merchant_name,
      width: "200px",
      sortable: true,
      cell: (d) => <span style={{ fontSize: 10 }}>{d?.merchant_name}</span>,
    },

    {
      name: "Merchant Number",
      selector: (row) => row.merchant_name,
      width: "180px",
      sortable: true,
      cell: (d) => <span style={{ fontSize: 15 }}> {d?.merchant_number}</span>,
    },

    {
      name: "Pincode",
      selector: (row) => row.merchant_name,
      width: "150px",
      sortable: true,
      cell: (d) => <div>{d?.pincode}</div>,
    },

    {
      name: "City",
      selector: (row) => row.merchant_name,
      width: "150px",
      sortable: true,
      cell: (d) => <p>{d?.city}</p>,
    },
    {
      name: "State",
      selector: (row) => row.merchant_name,
      width: "150px",
      sortable: true,
      cell: (d) => <p>{d?.state}</p>,
    },
    {
      name: "View Details",
      cell: (d) => (
        <div>
          <FileOpenIcon onClick={() => handleClickOpenView(d)} />
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.merchant_name,
      width: "150px",
      sortable: true,
      cell: (d) => (
        <span>
          <img
            src={three}
            onClick={(e) => {
              setSelectedData(d);
              handleClick(e);
            }}
          ></img>
        </span>
      ),
    },
  ];
  let tableData = {
    data: data,
    columns,
  };

  const [open1, setOpen1] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("xl");
  const [dataEach, setDataEach] = useState({});

  const handleClickOpenView = (d) => {
    console.log(d, "checkd");
    setDataEach(d);
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  return (
    <div>
      <Box>
        <Card
          variant="outlined"
          style={{
            backgroundColor: "#24304B",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <CardContent style={{ padding: "0" }}>
            <DataTableExtensions
              {...tableData}
              export={false}
              print={false}
              filterPlaceholder={`Search`}
              className="filter_text"
            >
              <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                highlightOnHover={true}
                conditionalRowStyles={conditionalRowStyles}
                pagination
              />
            </DataTableExtensions>
            <Dialog
              fullScreen
              maxWidth={maxWidth}
              open={open1}
              onClose={handleClose1}
            >
              <DialogTitle
                style={{
                  // boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                  backgroundColor: "#24304B",
                  color: "white",
                  width: "100%",
                  // borderRadius: "8px",
                  //   color: "white",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>View Details</div>
                  <div onClick={handleClose1}>
                    <HighlightOffOutlinedIcon style={{ cursor: "pointer" }} />
                  </div>
                </div>
              </DialogTitle>

              <DialogContent
                style={{
                  // boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                  backgroundColor: "#24304B",
                  // borderRadius: "8px",
                  //   color: "white",
                }}
              >
                <N_storemodal
                  data={dataEach}
                  closeMainModal={handleClose1}
                  setFlag={setFlag}
                  flag={flag}
                />
              </DialogContent>
            </Dialog>
          </CardContent>
        </Card>
        <EditModal
          open={open}
          handleClose={handleClose}
          anchorEl={anchorEl}
          tableData={selectedData}
          setFlag={setFlag}
          flag={flag}
        />
      </Box>
    </div>
  );
}

export default DTable;
