import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import image from "../components2/SVG/image1.svg";
import cred from "../components2/SVG/cred_pay.png";
import SleepWell from "../components2/SVG/sleepwell_logo.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useScrollTrigger } from "@mui/material";
import Project320 from "../Assets/Project320.jpeg";
import my_store_logo from "../Assets/my_store_logo.png";
import { base_url, checkProjects, verifyOtp } from "../utils/utils";
import axios from "axios";
function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 6 : 0,
  });
}

const Header = () => {
  const location = useLocation();
  const project = sessionStorage.getItem("projectid");

  //for switching
  const [accessProjects, setAccessProjects] = React.useState([]);
  const [isLoading, setisLoading] = React.useState(true);
  const [isErr, setIsErr] = React.useState(false);
  const tokenData = sessionStorage.getItem("token");
  //menu project
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const getProjectsData = (dataToken) => {
    axios
      .get(checkProjects, dataToken)
      .then((res) => {
        setAccessProjects(res.data.data);
        setisLoading(false);
        console.log(res.data.data, "checkprojres");
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setisLoading(false);
      });
  };

  // menu
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchProjects = (item) => {
    console.log(item, "tesingonit");
    let bodyData = {
      mobile_number: item.mobile_number,
      project_id: item.project_id,
      otp: item.otp,
      // email: item.email_id,
    };
    console.log(item, "checit");
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    let api = base_url + verifyOtp;
    axios
      .post(api, bodyData, dataToken)
      .then((res) => {
        console.log(res.data, "finaltoken");

        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("admin", res.data.admin);
        sessionStorage.setItem("fullName", res.data.full_name);
        sessionStorage.setItem("projectid", res.data.project_id);

        if (res.data.error === false) {
          navigate("/");
        } else {
          navigate("/error");
        }
        // window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    let api = checkProjects;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    getProjectsData(dataToken);
  }, []);
  return isLoading ? (
    <>...loading</>
  ) : (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <ElevationScroll>
          <AppBar
            position="fixed"
            sx={{
              backgroundColor: "#14192C",
              boxShadow: " 0px 4px 6px rgba(0, 0, 0, 0.3)",
              // boxShadow:
              //   project == "286" ? "0px 4px 6px rgba(0, 0, 0, 0.3)" : "none",
            }}
          >
            <Toolbar>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100vw",
                  padding: "0 75px 0 82px",
                  alignItems: "center",
                }}
              >
                <div>
                  {/* <img
                    src={project == "296"  ? image : SleepWell}
                    alt="taskmo"
                    width={project == "296" ? 100 : 60}
                    height={project == "296" ? 50 : 60}
                    style={{
                      margin: project == "296" ? 0 : 5,
                    }}
                  /> */}
                  <img
                    src={
                      project === "296"
                        ? image
                        : project === "253"
                        ? cred
                        : project == "320"
                        ? my_store_logo
                        : SleepWell
                    }
                    alt="Project_Image"
                    width={project == "296" ? 100 : 60}
                    height={project == "296" ? 50 : 60}
                  />
                </div>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <div>
                    {accessProjects.length > 0 && (
                      <div>
                        <Button variant="contained" onClick={handleClick2}>
                          switch Projects
                        </Button>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    <Avatar
                      alt="user"
                      onClick={handleClick}
                      style={{ cursor: "pointer", textTransform: "capitalize" }}
                    >
                      {sessionStorage?.getItem("fullName") &&
                        sessionStorage?.getItem("fullName")[0]}
                    </Avatar>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "#f9f9f9",
                        textTransform: "capitalize",
                      }}
                    >
                      {sessionStorage?.getItem("fullName") ?? ""}
                    </div>
                    <div
                      style={{
                        fontSize: "10px",
                        color: "#f9f9f9",
                        textTransform: "capitalize",
                      }}
                    >
                      {sessionStorage?.getItem("admin")}
                    </div>
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Toolbar></Toolbar>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            // vertical: "top",
            // horizontal: "left",
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("admin");
              sessionStorage.removeItem("fullName");
              sessionStorage.removeItem("projectid");
              navigate("/");
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Box>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl2}
        open={open2}
        onClose={handleClose2}
        anchorOrigin={{
          // vertical: "top",
          // horizontal: "left",
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {accessProjects.map((item) => (
          <MenuItem
            sx={{ fontSize: "12px", width: "fit-content" }}
            onClick={() => {
              // handleClickOpen3(item);

              switchProjects(item);

              handleClose2();
              // handleClose();
              // sessionStorage.removeItem('token');
              // sessionStorage.removeItem('admin');
              // sessionStorage.removeItem('fullName');
              // sessionStorage.removeItem('projectid');
              // navigate('/');
            }}
          >
            {item.project_title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default Header;
