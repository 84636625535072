import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ImageModal({
  handleImgClose,
  Imgopen,
  Imgdata,
  resType,
  Posimgdata,
  loadingModal,
  loadingModalp,
  err,
}) {
  console.log(resType === "pob_image", "posdata");

  return loadingModal && loadingModalp ? (
    <>...loading</>
  ) : err ? (
    <>something went wrong</>
  ) : (
    <div>
      <Modal
        open={Imgopen}
        onClose={handleImgClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {resType !== "pob_image" ? (
          <Box sx={style}>
            <ImageList sx={{ width: 800, height: 650 }}>
              {Imgdata?.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    src={`${item[`${resType}`]}?w=248&fit=crop&auto=format`}
                    srcSet={`${
                      item[`${resType}`]
                    }?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.shop_name}
                    loading="lazy"
                  />

                  <ImageListItemBar
                    title={item.shop_name}
                    subtitle={
                      <>
                        <span>Merchant Name: {item.merchant_name}</span>
                        <br />
                        <span>Merchant Number: {item.merchant_number}</span>
                        <br />
                        <span>lead Id: {item.lead_id}</span>
                        <br />
                        <span>Distance: {item.distance?.toFixed(2)}</span>
                      </>
                    }
                    description={<span>by: {item.merchant_number}</span>}
                    position="below"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        ) : resType === "pob_image" ? (
          <Box sx={style}>
            <ImageList sx={{ width: 800, height: 650 }}>
              {Posimgdata?.map((item) => (
                <>
                  <ImageListItem key={item.img}>
                    <img
                      src={`${item[`${resType}`]}?w=248&fit=crop&auto=format`}
                      // src={item?.pob_image}
                      // src={"/logo192.png"}
                      srcSet={`${
                        item[`${resType}`]
                      }?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={item?.shop_name}
                      loading="lazy"
                    />
                    <ImageListItemBar
                      title={item.shop_name}
                      subtitle={
                        <>
                          <span>Merchant Name: {item?.merchant_name}</span>
                          <br />
                          <span>Merchant Number: {item?.merchant_number}</span>
                          <br />
                          <span>lead Id: {item.lead_id}</span>
                          <br />
                          <span>Distance: {item?.distance?.toFixed(2)}</span>
                        </>
                      }
                      description={<span>by: {item?.merchant_number}</span>}
                      position="below"
                    />
                  </ImageListItem>
                </>
              ))}
            </ImageList>
          </Box>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
}
