import React, { useEffect, useState } from 'react';
import Header from '../components2/Header';
import Filters from '../components2/Filters';
import Seller from '../components2/Seller';
import SideBar from '../components2/SideBar';
import { CardContent, Grid } from '@mui/material';
import ClientTable from '../components2/ClientTable';
import { Card } from 'reactstrap';
import { CategoryProvider } from '../components2/CategoryContext';

export const Client1 = () => {
  const [ParentmonthFilter, setParentMonthFilter] = useState('all');
  const [parentcityFilter, setParentcityFilter] = useState('all');
  const token = sessionStorage.getItem('token');
  React.useEffect(() => {
    if (sessionStorage.getItem('projectid') !== '320') {
      window.location.reload();
    }
  }, []);
  return (
    <div>
      <Header />
      <CategoryProvider>
        <Filters
          token={token}
          setParentMonthFilter={setParentMonthFilter}
          setParentcityFilter={setParentcityFilter}
        />

        <Seller token={token} />

        <SideBar token={token} />

        <Grid container spacing={8} justifyContent={'center'}>
          <Grid item xs={11} md={11}>
            <ClientTable
              token={token}
              parentcityFilter={parentcityFilter}
              parentmonthFilter={ParentmonthFilter}
            />
          </Grid>
        </Grid>
      </CategoryProvider>
    </div>
  );
};
