import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import React, { useEffect, useState } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { upload_baseurl, upload_url } from "../utils/utils";
import { table_data } from "../utils/myStoreutils";
import axios from "axios";
import { CSVLink } from "react-csv";
import three from "../Assets/three-dots-vertical.svg";
import EditModal from "./EditModal";
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

import { Grid } from "rsuite";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import MyStore from "./MyStore";
import { useCategory } from "./CategoryContext";

const ClientTable = ({ token, parentcityFilter, parentmonthFilter }) => {
  const [tabledata, settable] = useState([]);
  const [flag, setFlag] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [maxWidth, setMaxWidth] = useState("xl");
  const [dataEach, setDataEach] = useState({});
  const [openSnack, setOpenSnack] = useState(false);
  const [statusSnack, setStatusSnack] = useState("success");
  const { monthWiseData, mapCity } = useCategory();
  const [rejectClicked, setRejectClicked] = useState(false);
  const handleOpenR = () => setRejectClicked(true);
  const handleCloseR = () => setRejectClicked(false);
  const [remark, setRemark] = useState("");

  const [openM, setOpenM] = useState(false);
  const handleOpenM = () => setOpenM(true);
  const handleCloseM = () => setOpenM(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState({});
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSnack = (val) => {
    setStatusSnack(val);
    setOpenSnack(true);
  };
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleClickOpenView = (d) => {
    setDataEach(d);
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const get_table_Data = () => {
    const tableurl = upload_baseurl + table_data;
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        city: parentcityFilter,
        month: parentmonthFilter,
      },
    };
    axios
      .get(tableurl, dataToken)
      .then((res) => {
        settable(res?.data?.project_data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(tabledata, "joo");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#24304B",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 4,
    px: 4,
    pb: 4,
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "42px", // override the row height
        backgroundColor: "#24314B",
        color: "#fff",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#24314B",
        color: "#fff",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#24314B",
        color: "#ffff",
      },
    },
  };

  const columns = [
    {
      name: "Main Lead Id",
      selector: (row) => row.main_lead_id,
      width: "150px",
      sortable: true,
      cell: (d) => (
        <div style={{ cursor: "pointer" }} className="text-info">
          <u>
            <span style={{ padding: 5, fontSize: 12 }}>{d.main_lead_id}</span>
          </u>
        </div>
      ),
    },
    {
      name: "Date",
      width: "150px",
      selector: (row) => row.date,
      // cellExport: (row) => String(row.date),
      cell: (d) => <>{d.date}</>,
    },
    {
      name: "Seller Name",
      width: "150px",
      selector: (row) => String(row.merchant_name),
      // cellExport: (row) => String(row.merchant_name),
      cell: (d) => <>{d.merchant_name}</>,
    },
    {
      name: "Seller Number",
      width: "150px",
      selector: (row) => row.merchant_number,
      // cellExport: (row) => String(row.rc_number),
      cell: (d) => <>{d.merchant_number}</>,
    },
    {
      name: "Shop Name",
      width: "200px",
      selector: (row) => String(row.shop_name),
      cell: (d) => <>{d.shop_name}</>,
    },
    {
      name: "City",
      width: "200px",
      selector: (row) => row.city,
      // cellExport: (row) => String(row.city),
      cell: (d) => <>{d.city}</>,
    },

    {
      name: "State",
      width: "200px",
      selector: (row) => row.state,
      // cellExport: (row) => String(row.state),
      cell: (d) => <>{d.state}</>,
    },

    {
      name: "KYC Status",
      width: "150px",
      omit: true,
      selector: (row) => row.client_qc_remark,
      cell: (d) => <>{d?.kyc_status}</>,
    },

    {
      name: "Test order status",
      width: "150px",
      omit: false,
      selector: (row) => row.test_order_status,
      cell: (d) => <>{d.test_order_status}</>,
    },
    {
      name: "Category",
      width: "200px",
      omit: false,
      selector: (row) => row.category,
      cell: (d) => <>{d.category}</>,
    },
    {
      name: "Sub Category",
      width: "200px",
      omit: false,
      selector: (row) => row.sub_category,
      cell: (d) => <>{d.sub_category}</>,
    },
    {
      name: "View Details",
      cell: (d) =>
        d.client_qc == "rejected" || d.client_qc == "approved" ? (
          <>
            <p>----</p>{" "}
          </>
        ) : (
          <>
            <div>
              <FileOpenIcon onClick={() => handleClickOpenView(d)} />
            </div>
          </>
        ),
    },
  ];

  const tableData = {
    columns: columns,
    data: tabledata,
  };
  const onSumbit = (e) => {
    // const link = upload_baseurl + sleepwell_reject_lead;
    // const reject_lead = {
    //   qc_remark: remark?.length > 0 ? remark : "reject",
    //   main_lead_id: selectedData,
    // };
    // axios
    //   .patch(link, reject_lead, dataToken)
    //   .then((res) => {
    //     if (res.data.error) {
    //       handleClickSnack("error");
    //     } else {
    //       handleClickSnack("success");
    //       setFlag(!flag);
    //       handleCloseM();
    //     }
    //   })
    //   .catch((err) => {
    //     handleClickSnack("error");
    //   });
    console.log("remark : ", remark, "kooo");
  };
  useEffect(() => {
    get_table_Data();
  }, [monthWiseData, parentmonthFilter, parentcityFilter]);
  return (
    <>
      <Box
        xs={4}
        md={4}
        variant="outlined"
        style={{
          backgroundColor: "#24304B",
          borderRadius: "10px",
          padding: "10px 15px",
        }}
      >
        <div
          // style={{  }}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            visibility:
              tableData?.length == 0 || tableData == undefined
                ? "hidden"
                : "show",
          }}
        >
          <CSVLink filename="Report.csv" data={tabledata ?? []}>
            <IconButton variant="text">
              <DownloadIcon style={{ color: "white" }} />
            </IconButton>
          </CSVLink>
        </div>
        <CardContent style={{ padding: "0" }}>
          <DataTableExtensions
            {...tableData}
            export={false}
            print={false}
            exportHeaders
            filterPlaceholder={`Search`}
            className="filter_text"
          >
            <DataTable
              columns={columns}
              data={tabledata}
              customStyles={customStyles}
              paginationRowsPerPageOptions={[10, 30, 50, 100]}
              fixedHeader={true}
              fixedHeaderScrollHeight={"430px"}
              pagination
            />
          </DataTableExtensions>
          <Dialog
            fullScreen
            maxWidth={maxWidth}
            open={open1}
            onClose={handleClose1}
          >
            <DialogTitle
              style={{
                // boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                backgroundColor: "#24304B",
                color: "white",

                // borderRadius: "8px",
                //   color: "white",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>View Details</div>
                <div onClick={handleClose1}>
                  <HighlightOffOutlinedIcon style={{ cursor: "pointer" }} />
                </div>
              </div>
            </DialogTitle>

            <DialogContent
              style={{
                // boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                backgroundColor: "#24304B",
                // borderRadius: "8px",
                //   color: "white",
              }}
            >
              <MyStore
                data={dataEach}
                closeMainModal={handleClose1}
                setFlag={setFlag}
                flag={flag}
                handleClose1={handleClose1}
                handleClickSnack={handleClickSnack}
              />
            </DialogContent>
          </Dialog>
        </CardContent>
      </Box>
    </>
  );
};

export default ClientTable;
