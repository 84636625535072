import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Piechart from "./Piechart";
import { Grid } from "@mui/material";
import { base_url, city_chart, pos_type_chart } from "../utils/utils";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
const SideRightCard = ({ name, city, selectedDateRange, monthFalcon }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isErr, setIsErr] = useState(false);
  const [data, setData] = useState([]);
  const tokenData = sessionStorage.getItem("token");
  const getCardData = () => {
    let apiData;
    if (name == "citychart") {
      apiData = base_url + city_chart;
    } else {
      apiData = base_url + pos_type_chart;
    }
    setIsLoading(true);

    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        city: city,
        // from: moment(selectedDateRange[0]).format("YYYY-MM-DD"),
        // to: moment(selectedDateRange[1]).format("YYYY-MM-DD"),
        month: monthFalcon,
        year: "2023",
      },
    };
    axios
      .get(apiData, dataToken)
      .then((res) => {
        setIsLoading(false);
        setData(res.data);
      })
      .catch((err) => setIsErr(true))
      .finally(() => setIsLoading(false));
  };

  React.useEffect(() => {
    getCardData();
  }, []);
  return isLoading ? (
    <>...loading</>
  ) : (
    <div>
      <Card
        variant="outlined"
        sx={{
          // width: 845,
          width: "100%",
          // height: 572,
          // minHeight: 572,
          // height: "72vh",
          backgroundColor: "#32355F",
          borderRadius: "14px",
          display: "flex",
          justifyItems: "around",
        }}
      >
        <CardContent sx={{ display: "flex", width: "100%" }}>
          <Grid
            container
            direction={"column"}
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={3}
          >
            <Grid item>
              <Typography sx={{ color: "#fff" }}>
                {name == "poschart" ? "POS Stats" : "Survey Stats"}
              </Typography>
            </Grid>
            {/* <Grid item>
              <div style={{ fontSize: "52px", fontWeight: 900, color: "#fff" }}>
                {data?.total}
              </div>
            </Grid> */}
            <Grid item>
              <Piechart data={data} name={name} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default SideRightCard;
