import React, { useState } from "react";
import backgroundImage from "../../src/Assets/background.svg";
import {
  Avatar,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import OtpInput from "react-otp-input";
import { base_url, getOtp, verifyOtp } from "../utils/utils";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginOtp = () => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  document.body.style.backgroundColor = "#32355F";
  let mbNumber = sessionStorage.getItem("mbnumber");
  let project = sessionStorage.getItem("projectid");
  const [code, setCode] = useState("");
  const [err, setErr] = useState(false);
  const navigate = useNavigate();

  const handleVerify = () => {
    let apiData = base_url + verifyOtp;
    setErr(false);
    axios
      .post(apiData, {
        mobile_number: mbNumber,
        otp: code,
        project_id: project,
      })
      .then((res) => {
        if (res.data.error) {
          setErr(true);
        } else {
          console.log(res.data.project_id, "check");
          // Cookies.set("token", res?.data?.token, { expires: 7 });
          // Cookies.set("fullName", res?.data?.full_name, { expires: 7 });
          // Cookies.set("admin", res?.data?.admin, { expires: 7 });

          sessionStorage.setItem("token", res?.data?.token);
          sessionStorage.setItem("fullName", res?.data?.full_name);
          sessionStorage.setItem("admin", res?.data?.admin);

          // if (res.data.project_id == "286") {
          //   navigate("/namma-yatri");
          // } else if (res.data.project_id == "259") {
          if (
            res.data.project_id == 286 ||
            res.data.project_id == 259 ||
            res.data.project_id == 296 ||
            res.data.project_id == 303 ||
            res.data.project_id == 253 ||
            res.data.project_id == 320 ||
            res.data.project_id == 326
          ) {
            navigate("/dashboard");
          } else {
            console.log("goind to else");
            handleClick();
          }
        }
      })
      .catch((err) => setErr(true));
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        // backgroundSize: "cover",
        // backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
      }}
    >
      <div style={{ position: "absolute", top: 220, right: 200 }}>
        <Grid
          container
          style={{ color: "#fff" }}
          direction={"column"}
          spacing={5}
        >
          <Grid item>
            <Grid container direction="column" alignItems={"center"}>
              <Grid item>
                <Typography variant="h5">Welcome Back</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption" display={"block"}>
                  {" "}
                  Sign in to continue with Taskmo
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <>
            {" "}
            <Grid item style={{ marginTop: "-10px" }}>
              <TextField
                type="number"
                size="small"
                style={{
                  backgroundColor: "#4F538B",
                  fontFamily: "Nunito",
                  border: "none",
                  boxShadow: "none",
                  borderRadius: "4px",
                  width: "244px",
                }}
                sx={{
                  "& fieldset": { border: "none" },
                  "& .MuiInputBase-root": {
                    color: "#ffffff",
                  },
                }}
                id="name"
                variant="outlined"
                placeholder="Please Enter OTP"
                //   error={
                //     !Mobile_Regex.test(loginData.merchant_number) ? true : false
                //   }
                name="merchant_number"
                fullWidth
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 4);
                }}
                onChange={(e) => setCode(e.target.value)}
              />
            </Grid>
            <Grid item>
              {err && (
                <div
                  style={{ color: "red", fontSize: "12px", marginTop: "-30px" }}
                >
                  Entered OTP is Incorrect
                </div>
              )}
            </Grid>
            <Grid item style={{ marginTop: "-40px" }}>
              <Button
                onClick={handleVerify}
                fullWidth
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  background: "#100B38",
                  boxShadow: "none",
                }}
              >
                Verify
              </Button>
            </Grid>
          </>
        </Grid>
      </div>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          No Active Projects Found !
        </Alert>
      </Snackbar>
    </div>
  );
};

export default LoginOtp;
