import React from "react";
import Chart from "react-apexcharts";
const Graph = ({ gdata, dataType }) => {
  const data = {
    series: [
      {
        name: "Digital",
        data: gdata?.direct ?? [],
      },
      {
        name: "Field",
        data: gdata?.filed ?? [],
      },
      {
        name: "Approve",
        data: gdata?.approve ?? [],
      },
    ],
    options: {
      colors: ["#b4ff69", "#beb5f4", "#f9b754"],
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 3,
        dashArray: 0,
      },

      legend: {
        position: "bottom",
        horizontalAlign: "right",
        // offsetX: 0,
        // offsetY: 10,
        labels: {
          colors: "#fff",
        },
      },
      tooltip: {
        enabled: true,
      },
      title: {
        // text: dataType == "one" ? "One Ride Chart" : "Three Ride Chart",
        style: {
          color: "#fff",
        },
      },

      chart: {
        toolbar: {
          show: false,
        },

        id: "line-chart",
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
      },
      xaxis: {
        categories: gdata?.date ?? [],
        labels: {
          style: {
            colors: "#fff",
          },
        },
      },

      yaxis: {
        title: {
          // text: "Sales (in USD)",
          style: {
            color: "#fff", // Set the color of the Y-axis title
          },
        },
        labels: {
          style: {
            colors: "#fff", // Set the color of the Y-axis labels
          },
        },
      },
    },
  };
  return (
    <div>
      <Chart
        options={data?.options}
        series={data?.series}
        type="line"
        height={400}
      />
    </div>
  );
};

export default Graph;
