import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MonthCalendar } from "@mui/x-date-pickers/MonthCalendar";
import moment from "moment";
import { YearCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const CalendarUi = ({ show, setDate, date }) => {
  return (
    <div
      style={{
        background: "#24304B",
        borderRadius: "8px",
        boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
      }}
    >
      {show && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["YearCalendar", "MonthCalendar"]}>
            <DemoItem label="Select Month">
              <MonthCalendar
                defaultValue={dayjs(date) ?? "all"}
                onChange={(e) => {
                  let d = moment(e.$d).format("YYYY-MM-DD");
                  setDate(d);
                }}
              />
              {/* <YearCalendar></YearCalendar> */}
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      )}
    </div>
  );
};

export default CalendarUi;
