import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import "./NammaYatriModalData.css";
import axios from "axios";
import Cookies from "js-cookie";
import Zoom from "react-medium-image-zoom";
import Snackbar from "@mui/material/Snackbar";
import "react-medium-image-zoom/dist/styles.css";
import { base_url } from "../utils/utils";
import { client_remark } from "../utils/namayatriUtils";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NammaYatriModalData = ({
  data,
  closeMainModal,
  changeOnUpdateNamma,
  setChangeOnUpdateNamma,
}) => {
  console.log(data?.client_status, "status of client");
  const tokenData = sessionStorage.getItem("token");
  const dataToken = {
    headers: {
      "x-access-token": `${tokenData}`,
    },
  };

  const [loading, setIsLoading] = React.useState(true);
  const [err, setIsErr] = React.useState(false);
  const [apiDataRes, setApiDataRes] = React.useState({});
  const [keyData, setKeyData] = React.useState([]);
  const [valuesData, setValuesData] = React.useState([]);
  const [stateData, setStateData] = React.useState("");
  const [remarkData, setRemarkData] = React.useState("");
  const navigate = useNavigate();

  //for modal
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (data) => {
    console.log(data, "datainit");
    setStateData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // for modal remark
  const [open1, setOpen1] = React.useState(false);
  const handleClickOpen1 = (data) => {
    setStateData(data);
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  //for snackbar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [statusSnack, setStatusSnack] = React.useState("success");

  const handleClickSnack = (val) => {
    setStatusSnack(val);
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const getModalApiData = () => {
    setIsLoading(true);
    axios
      .get(data?.lead_details, dataToken)
      .then((res) => {
        setApiDataRes(res?.data?.leads[0]);
        setIsLoading(false);
        console.log(res?.data?.leads, data?.lead_details, "jk");
        let keys = Object.keys(res?.data?.leads[0]);
        setKeyData(keys);
        let values = Object.values(res?.data?.leads[0]);
        setValuesData(values);
      })
      .catch((err) => setIsErr(true))
      .finally(() => setIsLoading(false));
  };

  React.useEffect(() => {
    getModalApiData();
  }, []);

  const sendStatusData = () => {
    let apiData = base_url + client_remark;
    let body =
      stateData == "Approve"
        ? {
            lead_id: apiDataRes?.main_lead_id,
            remark: "",
            status: "1",
          }
        : stateData == "Approve with Sticker"
        ? {
            lead_id: apiDataRes?.main_lead_id,
            remark: "",
            status: "4",
          }
        : {
            lead_id: apiDataRes?.main_lead_id,
            remark: remarkData,
            status: stateData == "Reject" ? "3" : "2",
          };
    axios
      .post(apiData, body, dataToken)
      .then((res) => {
        if (res.data.error) {
          handleClickSnack("error");
          handleClose();
          handleClose1();
          setRemarkData("");
          setTimeout(() => {
            closeMainModal();
            setChangeOnUpdateNamma(!changeOnUpdateNamma);
          }, 2000);
        } else {
          handleClickSnack("success");
          handleClose();
          handleClose1();
          setRemarkData("");
          setTimeout(() => {
            closeMainModal();
            setChangeOnUpdateNamma(!changeOnUpdateNamma);
          }, 2000);
        }
      })
      .catch((err) => console.log(err));
  };

  return loading ? (
    <>...loading</>
  ) : err ? (
    <>something went wrong</>
  ) : (
    <div style={{ color: "white" }}>
      {data?.client_status != "Pending" ? (
        <div
          style={{ color: "red", padding: "10px" }}
        >{`${data?.client_status} Status Changed by ${data?.client_name}`}</div>
      ) : (
        ""
      )}
      {/* <Card
        style={{
          boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
          backgroundColor: "#24304B",
          borderRadius: "8px",
          //   color: "white",
        }}
      >
        <CardContent>
         
        </CardContent>
      </Card> */}
      {keyData.map((item, i) => (
        <div
          key={i}
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              border: "1px solid black",
              borderRight: "0px solid black",
              borderBottom:
                i == keyData.length - 1 ? "1px solid black" : "0px solid black",
              width: "40%",
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "capitalize",
            }}
          >
            {item.includes("_") ? item.split("_").join(" ") : item}
          </div>
          <div
            style={{
              border: "1px solid black",
              borderBottom:
                i == keyData.length - 1 ? "1px solid black" : "0px solid black",
              width: "60%",
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            {String(valuesData[i])?.includes("https") ? (
              <Zoom>
                <div
                  role="img"
                  style={{
                    width: "100px",
                    height: "100px",
                    backgroundImage: "url(" + valuesData[i] + ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                ></div>
              </Zoom>
            ) : valuesData[i] == "" ? (
              "N/A"
            ) : (
              valuesData[i]
            )}
          </div>
        </div>
      ))}

      {data?.client_status == "Pending" || data?.client_status == "Rework" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
            gap: "20px",
          }}
        >
          <div>
            <Button
              variant="contained"
              // style={{ backgroundColor: "#2F7C31", color: "black" }}
              onClick={() => handleClickOpen("Approve")}
              color="success"
            >
              Approve
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              // style={{ backgroundColor: "#2F7C31", color: "black" }}
              onClick={() => handleClickOpen("Approve with Sticker")}
              color="success"
            >
              Approve with Sticker
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="error"
              // style={{ backgroundColor: "#D3302F", color: "black" }}
              onClick={() => {
                handleClickOpen1("Reject");
              }}
            >
              Reject
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="warning"
              // style={{ backgroundColor: "#ED6C02", color: "black" }}
              onClick={() => {
                handleClickOpen1("Re Work");
              }}

              //  onClick={() => handleClickOpen("Re Work")}
            >
              Re Work
            </Button>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Warning Alert!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to ${stateData}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              handleClose();
              handleClose1();
              setRemarkData("");
            }}
            // style={{ backgroundColor: "#D3302F", color: "black" }}
            variant="contained"
            color="error"
          >
            No
          </Button>
          <Button
            autoFocus
            // style={{ backgroundColor: "#2F7C31", color: "black" }}
            variant="contained"
            color="success"
            onClick={() => sendStatusData()}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* remark */}
      <Dialog
        open={open1}
        onClose={() => {
          handleClose1();
          setRemarkData("");
        }}
        fullWidth="true"
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{`Add ${stateData} Remark!`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              size="small"
              fullWidth
              placeholder="Plese Enter Remark"
              onChange={(e) => setRemarkData(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={remarkData == "" ? true : false}
            autoFocus
            // style={{ backgroundColor: "#2F7C31", color: "black" }}
            variant="contained"
            color="success"
            onClick={() => {
              stateData == "Reject"
                ? handleClickOpen("Reject")
                : handleClickOpen("Re Work");
            }}
          >
            Add Remark
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={statusSnack}
          sx={{ width: "100%" }}
        >
          {statusSnack == "success" ? "Success!" : "Remark already Exists"}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NammaYatriModalData;
