import Cookies from "js-cookie";
import { Navigate, Outlet } from "react-router-dom";

export const PrivateRoute3 = ({ children }) => {
  const auth = sessionStorage.getItem("token");
  let mbNumber = sessionStorage.getItem("mbnumber");

  return !auth ? (
    mbNumber ? (
      <Outlet />
    ) : (
      <Navigate to="/login" />
    )
  ) : (
    <Navigate to="/dashboard" />
  );
};
