import axios from "axios";
import React, { useEffect, useState } from "react";
import ImgRotModal from "./ImgRotModal";
import { Alert, Box, Button, Modal, Snackbar, Typography } from "@mui/material";
import { upload_baseurl } from "../utils/utils";

function ClientTable2({ data, handleClose1, setFlag, flag }) {
  console.log(data);
  const [keyData, setKeyData] = React.useState([]);
  const [loading, setIsLoading] = React.useState(true);
  const [valuesData, setValuesData] = React.useState([]);
  const [err, setIsErr] = React.useState(false);
  const [openImage, setOpenImage] = React.useState(false);
  const [selectedImages, setSelectedImages] = useState();
  const [openM, setOpenM] = React.useState(false);
  const [remark, setRemark] = useState("");
  const handleCloseM = () => setOpenM(false);
  const [rejectClicked, setRejectClicked] = useState(false);

  const [openSnack, setOpenSnack] = React.useState(false);
  const [statusSnack, setStatusSnack] = React.useState("success");

  const handleClickSnack = (val) => {
    setStatusSnack(val);
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleCloseR = () => setRejectClicked(false);

  const handleOpenM = () => {
    setOpenM(true);
    //setSelectedData(d?.main_lead_id);
  };

  const handleOpenR = () => {
    setRejectClicked(true);
    //setSelectedData(d?.main_lead_id);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#24304B",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 4,
    px: 4,
    pb: 4,
  };

  const handleOpenImage = () => {
    setOpenImage(true);
  };
  const handleCloseImage = () => setOpenImage(false);
  const tokenData = sessionStorage.getItem("token");
  const dataToken = {
    headers: {
      "x-access-token": `${tokenData}`,
    },
  };
  const ViewDetails = () => {
    setIsLoading(true);
    axios
      .get(data?.lead_details, dataToken)
      .then((res) => {
        setIsLoading(false);
        let keys = Object.keys(res?.data?.leads[0]);

        setKeyData(keys);
        let values = Object.values(res?.data?.leads[0]);
        setValuesData(values);
      })
      .catch((err) => setIsErr(true))
      .finally(() => setIsLoading(false));
  };
  const onSumbit = () => {
    const link = upload_baseurl + "/namma_yatri/v1/client_response";
    let sendData = {};
    if (remark) {
      sendData = {
        main_lead_id: data?.main_lead_id,
        remark: remark,
        status: "3",
      };
    } else {
      sendData = { main_lead_id: data?.main_lead_id, status: "1" };
    }

    axios
      .patch(link, sendData, dataToken)
      .then((res) => {
        if (res.data.error) {
          handleClickSnack("error");
        } else {
          handleClickSnack("success");
          setFlag(!flag);
          handleCloseM();
          handleClose1();
        }
      })
      .catch((err) => {
        handleClickSnack("error");
      });
    console.log(sendData, "send");
    handleCloseM();
  };

  useEffect(() => {
    ViewDetails();
  }, []);
  return (
    <div>
      {keyData.map((item, i) => (
        <div
          key={i}
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              border: "1px solid #a3b5d5",
              borderRight: "0px solid  #a3b5d5",
              borderBottom:
                i == keyData.length - 1
                  ? "1px solid  #a3b5d5"
                  : "0px solid  #a3b5d5",
              width: "40%",
              height: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "capitalize",
              fontSize: 16,
              fontWeight: 600,
              color: "white",
            }}
          >
            {item.includes("_") ? item.split("_").join(" ") : item}
          </div>
          <div
            style={{
              border: "1px solid  #a3b5d5",
              borderBottom:
                i == keyData.length - 1
                  ? "1px solid  #a3b5d5"
                  : "0px solid  #a3b5d5",
              width: "60%",
              height: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            {String(valuesData[i])?.includes("https") ? (
              // <Zoom>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  role="img"
                  style={{
                    width: "100px",
                    height: "120px",
                    backgroundImage: "url(" + valuesData[i] + ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                  onClick={() => {
                    handleOpenImage();
                    setSelectedImages(valuesData[i]);
                  }}
                />

                <ImgRotModal
                  openImage={openImage}
                  handleCloseImage={handleCloseImage}
                  imageurl={selectedImages}
                />
                {/* <span
                  style={{ cursor: "pointer", marginLeft: 15 }}
                  className="text-info"
                  onClick={() => {
                    sendType(item);
                    sendStatusData();
                    sendStatusDataPos();
                    handleImgOpen();
                  }}
                >
                  View Similar Images..
                </span> */}
              </div>
            ) : // </Zoom>
            valuesData[i] == "" ? (
              <span style={{ color: "white" }}> "N/A"</span>
            ) : (
              <span style={{ color: "white" }}>{valuesData[i]}</span>
            )}
          </div>
        </div>
      ))}
      {data.client_status == "Pending" ? (
        <div
          style={{
            margin: 10,

            display: "flex",
            width: 300,
            justifyContent: "space-evenly",
          }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => handleOpenM(true)}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleOpenR();
            }}
          >
            Reject
          </Button>
        </div>
      ) : (
        ""
      )}

      <Modal
        open={openM}
        onClose={handleCloseM}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color="#ffff"
            // textAlign="center"
          >
            Are you sure ?
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              sx={{ mx: 1, mt: 1 }}
              onClick={() => {
                onSumbit();
                handleCloseM();
              }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              sx={{ mx: 1, mt: 1 }}
              onClick={handleCloseM}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={rejectClicked}
        onClose={handleCloseR}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <div>
            <label
              style={{
                color: "#ffff ",
                fontWeight: 600,
                fontSize: 15,
                marginLeft: 10,
              }}
            >
              Remark
            </label>
            <textarea
              className="form-control"
              placeholder="Enter Remark"
              id="des-info-description-input"
              rows="5"
              style={{
                width: 500,
                margin: 10,
                padding: 10,
                borderRadius: 8,
                backgroundColor: "#e3ecff",
                border: "none",
                color: "#00000",
                outline: "none",
              }}
              onChange={(e) => {
                setRemark(e.target.value);
              }}
            ></textarea>
          </div>
          <Button
            variant="contained"
            sx={{
              mr: 3,
              mt: 2,
              width: 100,
            }}
            onClick={() => {
              setRejectClicked(false);
              handleOpenM(true);
            }}
            color="success"
          >
            Submit
          </Button>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={statusSnack}
          sx={{ width: "100%" }}
        >
          {statusSnack === "success" ? "Success!" : "Remark already Exists"}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ClientTable2;
