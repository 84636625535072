import React, { useState } from "react";
import Zoom from "react-medium-image-zoom";
import Snackbar from "@mui/material/Snackbar";
import "react-medium-image-zoom/dist/styles.css";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import MuiAlert from "@mui/material/Alert";
import { edit_leads } from "../utils/NstoreUtils";
import { upload_baseurl } from "../utils/utils";
import Imagesup from "./Imagesup";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function N_storemodal({ data, setFlag, flag }) {
  const tokenData = sessionStorage.getItem("token");
  const dataToken = {
    headers: {
      "x-access-token": `${tokenData}`,
    },
  };
  const [loading, setIsLoading] = React.useState(true);
  const [err, setIsErr] = React.useState(false);
  const [apiDataRes, setApiDataRes] = React.useState({});
  const [keyData, setKeyData] = React.useState([]);
  const [valuesData, setValuesData] = React.useState([]);
  const [openM, setOpenM] = React.useState(false);
  const handleOpenM = () => setOpenM(true);
  const handleCloseM = () => setOpenM(false);
  const [selectedImages, setSelectedImages] = useState({});
  const [resurl, setResurl] = React.useState("");

  const [all, setAll] = useState({});
  const [openSnack, setOpenSnack] = React.useState(false);
  const [statusSnack, setStatusSnack] = React.useState("success");

  const handleClickSnack = (val) => {
    setStatusSnack(val);
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const getModalApiData = () => {
    setIsLoading(true);
    axios
      .get(data?.leadDetails, dataToken)
      .then((res) => {
        setApiDataRes(res?.data?.leads[0]);
        setIsLoading(false);
        let keys = Object.keys(res?.data?.leads[0]);
        setKeyData(keys);
        let values = Object.values(res?.data?.leads[0]);
        setValuesData(values);
      })
      .catch((err) => setIsErr(true))
      .finally(() => setIsLoading(false));
  };

  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#24314B",
    color: "#ffff",
    boxShadow: 24,
    p: 4,
    zIndex: 100,
  };
  const inputStyle = {
    padding: 10,
    margin: 10,
    // width: "-webkit-fill-available",
    width: 850,
    backgroundColor: "#24314B",
    outline: "#ffff",
    border: "0.2px solid white",
  };

  const contentStyles = {
    maxHeight: 600, // Set a fixed height for the modal content
    overflow: "auto", // Enable scrolling
  };

  //   const Save = () => {
  //     console.log(all, "save");
  //     const tokenData = sessionStorage.getItem("token");
  //     const dataToken = {
  //       headers: {
  //         "x-access-token": `${tokenData}`,
  //       },
  //     };
  //     const link = upload_baseurl + edit_leads;
  //     axios
  //       .patch(link, all, dataToken)
  //       .then((res) => {
  //         handleClickSnack("success");
  //       })
  //       .catch((err) => handleClickSnack("something is wrong"));
  //   };

  const Save = () => {
    const tokenData = sessionStorage.getItem("token");
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    const link = upload_baseurl + edit_leads;
    axios
      .patch(link, all, dataToken)
      .then((res) => {
        if (res.data.error) {
          handleClickSnack("something is wrong");
          console.log(res.data.error, "ERR1");
        } else {
          handleClickSnack("success");
          setFlag(!flag);
          handleCloseM();
        }
      })
      .catch((err) => {
        handleClickSnack("error");
      });
  };

  React.useEffect(() => {
    getModalApiData();
  }, []);

  return loading ? (
    <>...loading</>
  ) : err ? (
    <>something went wrong</>
  ) : (
    <div style={{ color: "white" }}>
      <Button
        variant="contained"
        sx={{ my: 2, px: 2, float: "right" }}
        color="info"
        onClick={handleOpenM}
      >
        EDIT
      </Button>
      {keyData.map((item, i) => (
        <div
          key={i}
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              border: "1px solid black",
              borderRight: "0px solid black",
              borderBottom:
                i == keyData.length - 1 ? "1px solid black" : "0px solid black",
              width: "40%",
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "capitalize",
            }}
          >
            {item.includes("_") ? item.split("_").join(" ") : item}
          </div>
          <div
            style={{
              border: "1px solid black",
              borderBottom:
                i == keyData.length - 1 ? "1px solid black" : "0px solid black",
              width: "60%",
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            {String(valuesData[i])?.includes("https") ? (
              <Zoom>
                <div
                  role="img"
                  style={{
                    width: "100px",
                    height: "100px",
                    backgroundImage: "url(" + valuesData[i] + ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                ></div>
              </Zoom>
            ) : valuesData[i] == "" ? (
              "N/A"
            ) : (
              valuesData[i]
            )}
          </div>
        </div>
      ))}
      <Modal
        open={openM}
        onClose={handleCloseM}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <div style={contentStyles}>
            {keyData.map((item, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {valuesData[i] !== "" && (
                  <label
                    style={{
                      width: "-webkit-fill-available",
                      color:
                        item.includes("main_lead_id") ||
                        item.includes("customer_number") ||
                        item.includes("customer_name")
                          ? "gray"
                          : "inherit",
                    }}
                  >
                    {!item.endsWith("image") &&
                      (item.includes("_")
                        ? item.split("_").join(" ")
                        : item
                      )?.toUpperCase()}
                  </label>
                )}

                {item.includes("main_lead_id") ||
                item.includes("customer_number") ||
                item.includes("customer_name") ? (
                  <input
                    type="text"
                    defaultValue={valuesData[i]}
                    style={{ ...inputStyle, pointerEvents: "none" }}
                  />
                ) : (
                  <>
                    {String(valuesData[i])?.includes("https") ? (
                      <></>
                    ) : valuesData[i] === "" ? (
                      <>
                        {" "}
                        {/* <input
                          type="text"
                          defaultValue="N/A"
                          style={inputStyle}
                          onChange={(e) => {
                            setAll({
                              ...all,
                              main_lead_id: data?.main_lead_id,
                              [item]: e.target.value,
                            });
                          }}
                        /> */}
                      </>
                    ) : (
                      <input
                        type="text"
                        defaultValue={valuesData[i]}
                        style={inputStyle}
                        onChange={(e) => {
                          setAll({
                            ...all,
                            main_lead_id: data?.main_lead_id,
                            [item]: e.target.value,
                          });
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
          <Button
            variant="contained"
            sx={{ float: "right", mt: 4, px: 2 }}
            onClick={Save}
            color="info"
          >
            Save
          </Button>
        </Box>
      </Modal>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={statusSnack}
          sx={{ width: "100%" }}
        >
          {statusSnack == "success" ? "Success!" : "Remark already Exists"}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default N_storemodal;
