import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Avatar, CardHeader, Grid } from "@mui/material";

const MuiCard = ({ name, data }) => {
  return (
    <div>
      <Card
        style={{
          boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
          backgroundColor: "#24304B",
          borderRadius: "8px",
          height: "180px",
        }}
      >
        <CardHeader
          style={{
            backgroundColor: "#00091E",
            color:
              name == "1" ? "#FFB738" : name == "2" ? "#BB6BD9" : "#268C6E",
          }}
          title={
            <div>
              <Grid container justifyContent={"space-between"}>
                <Grid item>
                  <Grid container alignItems={"center"} gap={"10px"}>
                    <Grid item>
                      <div
                        style={{
                          background: "#fff",
                          width: "12px",
                          height: "12px",
                          borderRadius: "6px",
                        }}
                      ></div>
                    </Grid>
                    <Grid item style={{ fontSize: "22px", fontWeight: 500 }}>
                      {name == "1"
                        ? "Digital"
                        : name == "2"
                        ? "Field"
                        : "Client Data"}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <img
                    src={
                      name == "1"
                        ? "/eye.svg"
                        : name == "2"
                        ? "/ride.svg"
                        : "/auto.svg"
                    }
                    alt="eyeimage"
                  />
                </Grid>
              </Grid>
            </div>
          }
          // subheader="September 14, 2016"
        ></CardHeader>
        <CardContent>
          <Grid container alignItems={"center"} height={"100px"}>
            <Grid item>
              <Grid
                container
                direction="row"
                alignItems="center"
                gap="60px"
                style={{ width: "100%" }}
              >
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    spacing={2}
                    alignItems={"center"}
                  >
                    <Grid
                      item
                      style={{
                        fontSize: "26px",
                        fontWeight: 600,
                        color: "#fff",
                      }}
                    >
                      {name == "1"
                        ? data.digital_count
                        : name == "2"
                        ? data.field_count
                        : data.ride_count}
                    </Grid>
                    <Grid item style={{ color: "#6E7A97", fontSize: "13px" }}>
                      Onboard
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction="column"
                    spacing={2}
                    alignItems={"center"}
                  >
                    <Grid
                      item
                      style={{
                        fontSize: "26px",
                        fontWeight: 600,
                        color: "#fff",
                      }}
                    >
                      {name == "1"
                        ? data.digital_one_count
                        : name == "2"
                        ? data.field_one_count
                        : data.ride_one_count}
                    </Grid>
                    <Grid item style={{ color: "#6E7A97", fontSize: "13px" }}>
                      First Ride
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction="column"
                    spacing={2}
                    alignItems={"center"}
                  >
                    <Grid
                      item
                      style={{
                        fontSize: "26px",
                        fontWeight: 600,
                        color: "#fff",
                      }}
                    >
                      {name == "1"
                        ? data.digital_three_count
                        : name == "2"
                        ? data.field_three_count
                        : data.ride_three_count}
                    </Grid>
                    <Grid item style={{ color: "#6E7A97", fontSize: "13px" }}>
                      Third Ride
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default MuiCard;
