import React, { createContext, useContext, useState } from "react";

const CategoryContext = createContext();

export const CategoryProvider = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [mapCity, setMapCity] = useState("all");

  const [monthWiseData, setMonthWiseData] = useState("all");

  return (
    <CategoryContext.Provider
      value={{
        selectedCategory,
        setSelectedCategory,
        mapCity,
        setMapCity,
        monthWiseData,
        setMonthWiseData,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategory = () => useContext(CategoryContext);
