import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MainTableData from "./MainTableData";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { base_url, table_data } from "../utils/utils";
import axios from "axios";
import {
  namma_base_url,
  namma_yatri_table_data,
} from "../utils/namayatriUtils";
import Cookies from "js-cookie";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import moment from "moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TableCard = ({ name, dates, city, monthFalcon }) => {
  console.log(name, "testin");
  const location = useLocation();
  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [todayChart, setTodayChart] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [changeOnUpdateNamma, setChangeOnUpdateNamma] = useState(false);
  const [isErr, setIsErr] = useState(false);
  const token = sessionStorage.getItem("token");
  const getTableData = () => {
    let apiLink;
    if (name == "nammayatri") {
      apiLink = namma_base_url + namma_yatri_table_data;
    } else {
      apiLink = base_url + table_data;
    }

    setIsLoading(true);
    const tokenData = token;

    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };

    if (name == "nammayatri") {
      dataToken.params = {
        from: moment(dates[0]).format("YYYY-MM-DD"),
        to: moment(dates[1]).format("YYYY-MM-DD"),
        city: city,
      };
    } else {
      dataToken.params = {
        // from: moment(dates[0]).format("YYYY-MM-DD"),
        // to: moment(dates[1]).format("YYYY-MM-DD"),
        month: monthFalcon,
        year: "2023",
        city: city,
      };
    }
    axios
      .get(
        apiLink,

        dataToken
      )
      .then((res) => {
        setIsLoading(false);
        console.log(res.data.project_data, "testingininin");
        setTableData(res?.data?.project_data);
      })
      .catch((err) => setIsErr(true))
      .finally(() => setIsLoading(false));
  };
  React.useEffect(() => {
    console.log("insideee");
    getTableData();
  }, [changeOnUpdateNamma]);

  return isLoading ? (
    <>...loading</>
  ) : (
    <div>
      <Box>
        {name != "nammayatri" ? (
          <Card
            //   sx={{ width: "41.5rem", height: "56.125rem", borderRadius: "10px" }}
            variant="outlined"
            style={{
              backgroundColor: name == "nammayatri" ? "#24304B" : "#32355F",
              borderRadius: "10px",
              height: "940px !important",
              //height: "600px",
              padding: "10px",
            }}
          >
            <CardContent style={{ padding: "0" }}>
              <MainTableData data={tableData} name={name} />
            </CardContent>
          </Card>
        ) : (
          <MainTableData
            data={tableData}
            name={name}
            changeOnUpdateNamma={changeOnUpdateNamma}
            setChangeOnUpdateNamma={setChangeOnUpdateNamma}
          />
        )}
      </Box>
    </div>
  );
};

export default TableCard;
