const namma_base_url = "https://projectsbackend.taskmo.co";
const namma_yatri_table_data = "/namma_yatri/v1/table_data";

const search_city = "/namma_yatri/v1/search_city";

const card_data = "/namma_yatri/v1/card_data";

const one_ride_chart = "/namma_yatri/v1/one_ride_chart";

const map_pincode_namma = "/namma_yatri/v1/map_pincode";

const client_remark = "/namma_yatri/client_remark";

export {
  namma_yatri_table_data,
  namma_base_url,
  search_city,
  card_data,
  one_ride_chart,
  map_pincode_namma,
  client_remark,
};
