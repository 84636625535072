import React, { useEffect, useState } from "react";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { nStore_base_url, update_nstore_status } from "../utils/NstoreUtils";
import axios from "axios";
import { Label } from "@mui/icons-material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#24304B",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 4,
};

function ChildModal({ openC, handleCloseC, type, leadid, ui, setFlag, flag }) {
  const [rejectClicked, setRejectClicked] = useState(false);
  const handleOpenR = () => setRejectClicked(true);
  const handleCloseR = () => setRejectClicked(false);

  const [openM, setOpenM] = React.useState(false);
  const handleOpenM = () => setOpenM(true);
  const handleCloseM = () => setOpenM(false);

  const [remark, setRemark] = useState("");
  const [status, setStatus] = useState("");

  const token = sessionStorage.getItem("token");

  const updatedata = () => {
    let apiData = nStore_base_url + update_nstore_status;
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    let body = {
      status: status?.toString(),
      remarks: remark?.length > 0 ? remark : "NA",
      lead_id: leadid?.toString(),
      type: type,
    };
    axios
      .put(apiData, body, dataToken)
      .then((res) => {
        console.log(res, "save");
        setFlag(!flag);
      })
      .catch((err) => console.log(err, "err"));
  };

  const onSumbit = () => {
    //console.log(updates, status, "save");
    updatedata();
  };

  return (
    <React.Fragment>
      <Modal
        open={openC}
        onClose={handleCloseC}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <h5
            id="child-modal-title"
            style={{ textAlign: "center", color: "#fff" }}
          >
            {" "}
            Are you sure about approving this {ui} ?
          </h5>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                handleCloseC();
                handleOpenM();
                setStatus(1);
                setRemark("");
              }}
              sx={{ mx: 1, mt: 2 }}
              color="success"
            >
              Approved
            </Button>
            {/* <Button
              variant="contained"
              sx={{ mx: 1, mt: 2 }}
              onClick={() => {
                setStatus(2);
                handleCloseC();
                handleOpenM();
              }}
              color="warning"
            >
              Rework
            </Button> */}
            <Button
              variant="contained"
              sx={{ mx: 1, mt: 2 }}
              onClick={() => {
                handleOpenR(true);
                setStatus(2);
              }}
              color="warning"
            >
              Rework
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openM}
        onClose={handleCloseM}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color="#ffff"
            // textAlign="center"
          >
            Are you sure ?
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              sx={{ mx: 1, mt: 1 }}
              onClick={() => {
                onSumbit();
                handleCloseM();
              }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              sx={{ mx: 1, mt: 1 }}
              onClick={handleCloseM}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={rejectClicked}
        onClose={handleCloseR}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <div>
            <label
              style={{
                color: "#ffff ",
                fontWeight: 600,
                fontSize: 15,
                marginLeft: 10,
              }}
            >
              Remark
            </label>
            <textarea
              className="form-control"
              placeholder="Enter Remark"
              id="des-info-description-input"
              rows="5"
              style={{
                width: 500,
                margin: 10,
                padding: 10,
                borderRadius: 8,
                backgroundColor: "#e3ecff",
                border: "none",
                color: "#00000",
                outline: "none",
              }}
              onChange={(e) => {
                setRemark(e.target.value);
              }}
            ></textarea>
          </div>
          <Button
            variant="contained"
            sx={{
              mr: 3,
              mt: 2,
              width: 100,
            }}
            onClick={() => {
              handleCloseC();
              setRejectClicked(false);
              handleOpenM();
            }}
            color="success"
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default function EditModal({
  open,
  handleClose,
  anchorEl,
  tableData,
  setFlag,
  flag,
}) {
  const [openC, setOpenC] = React.useState(false);
  const handleOpenC = () => {
    setOpenC(true);
  };
  const handleCloseC = () => {
    setOpenC(false);
  };
  const [ui, setUi] = useState("");

  const [type, setType] = useState();
  return (
    <>
      <div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          aria-labelledby="basic-demo-button"
          sx={{ borderRadius: 0 }}
        >
          {tableData.kyc_status === "0" ? (
            <MenuItem
              onClick={() => {
                handleOpenC();
                setType(1);
                setUi("KYC Passed");
              }}
              sx={{ fontSize: 10, fontWeight: 600 }}
            >
              KYC Passed
            </MenuItem>
          ) : (
            ""
          )}
          {tableData.catalog_status === "0" && (
            <MenuItem
              onClick={() => {
                handleOpenC();
                setType(2);
                setUi("Catologing Passed");
              }}
              sx={{ fontSize: 10, fontWeight: 600 }}
            >
              Catologing Passed
            </MenuItem>
          )}
          {tableData.training_status === "0" && (
            <MenuItem
              onClick={() => {
                handleOpenC();
                setType(3);
                setUi("Training Passed");
              }}
              sx={{ fontSize: 10, fontWeight: 600 }}
            >
              Training Passed
            </MenuItem>
          )}
          {tableData.terms_status === "0" && (
            <MenuItem
              onClick={() => {
                handleOpenC();
                setType(4);
                setUi("T&C Accepted");
              }}
              sx={{ fontSize: 10, fontWeight: 600 }}
            >
              T&C Accepted
            </MenuItem>
          )}
        </Menu>

        <ChildModal
          openC={openC}
          handleCloseC={handleCloseC}
          leadid={tableData.lead_id}
          type={type}
          ui={ui}
          setFlag={setFlag}
          flag={flag}
        />
      </div>
    </>
  );
}
