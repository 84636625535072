import Charts from "react-apexcharts";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";

export default function PieChart({ pgraphData }) {
  const options = {
    chart: {
      type: "pie",
      foreColor: "#E1E1E1",
    },
    labels: ["Restaurant", "Grocery"],
    colors: ["#2D64A8", "#88B5ED", "#497FC1"],
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetY: 6,
      labels: {
        colors: ["#FFFF"],
      },
    },
    chart: {
      width: 380,
      type: "pie",
    },
  };
  const series = [pgraphData.restaurant, pgraphData.grocery];
  return (
    <Charts
      options={options}
      series={series}
      width={400}
      height={400}
      type="pie"
    />
  );
}
