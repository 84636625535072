import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import SideLeftCard from "./components/SideLeftCard";
import {
  Box,
  Card,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SideRightCard from "./components/SideRightCard";
import ApexGraph from "./components/ApexGraph";
import TableCard from "./components/TableCard";
import CircularProgress from "@mui/material/CircularProgress";
import {
  base_url,
  citydatas,
  map_pincode,
  ondc_cards,
  ondc_map_data_2,
  ondc_pincode,
  table_data,
  today_chart,
} from "./utils/utils";
import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { DateRangePicker } from "rsuite";
import MapCard from "./pages/MapCard";
import moment from "moment";
import { ThemeProvider, createTheme } from "@mui/system";
import WMap from "./pages/WMap";
import Gurgaon from "./pages/Gurugaon";
import Jaipur from "./pages/Jaipur";

const options = {
  shouldForwardProp: (prop) => prop !== "fontColor",
};
const StyledTextField = styled(
  TextField,
  options
)(({ fontColor }) => ({
  input: {
    color: fontColor,
  },
}));
const MontlyData = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "04",
    label: "April",
  },
  {
    value: "05",
    label: "May",
  },
  {
    value: "06",
    label: "June",
  },

  {
    value: "07",
    label: "July",
  },
  {
    value: "08",
    label: "August",
  },
  {
    value: "09",
    label: "September",
  },
  {
    value: "10",
    label: "October",
  },
  {
    value: "11",
    label: "November",
  },
  {
    value: "12",
    label: "December",
  },
];

function App() {
  document.body.style.backgroundColor = "#151038";

  const [mapData, setMapData] = React.useState([]);

  const [mapData2, setMapData2] = React.useState([]);
  const [loading3, setLoading3] = React.useState(false);
  const [loading4, setIsLoading4] = React.useState(false);
  const [loading5, setIsLoading5] = React.useState(false);
  const [citiesData, setcitiesData] = React.useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([
    new Date(),
    new Date(),
  ]);
  const [monthWiseData, setMonthWiseData] = React.useState("all");
  const handleDateRangeChange = (value) => {
    setSelectedDateRange(value);
  };
  const token = sessionStorage.getItem("token");

  const getCityData = () => {
    let apiData = base_url + citydatas;
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    setIsLoading5(true);
    axios
      .get(apiData, dataToken)
      .then((res) => {
        // console.log(res?.data?.city_list, "chekcingres");
        setcitiesData(res?.data?.city_list);
        console.log(res?.data?.city_list, "tseting");
        setIsLoading5(false);
      })
      .catch((err) => console.log(err, "err"))
      .finally(() => setIsLoading5(false));
  };

  const getMapData = () => {
    let apiData = base_url + ondc_pincode;
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        city: mapCity,
        // from: moment(selectedDateRange[0]).format("YYYY-MM-DD"),
        // to: moment(selectedDateRange[1]).format("YYYY-MM-DD"),
        month: monthWiseData,
        year: "2023",
      },
    };
    setLoading3(true);
    axios
      .get(apiData, dataToken)
      .then((res) => {
        setLoading3(false);

        setMapData(res.data?.city_data);
      })
      .catch((err) => console.log(err, "err"));
  };

  const getMapDataGroww = () => {
    let apiData = base_url + ondc_map_data_2;
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    setIsLoading4(true);
    axios
      .get(apiData, dataToken)
      .then((res) => {
        setIsLoading4(false);

        setMapData2(res?.data?.city_data);
      })
      .catch((err) => console.log(err, "err"))
      .finally(() => {
        setIsLoading4(false);
      });
  };

  const [cardData, setCardData] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isErr, setIsErr] = useState(false);

  const [mapCity, setMapCity] = React.useState("all");

  const getCardData = () => {
    let apiData = base_url + ondc_cards;
    setIsLoading(true);
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        city: mapCity,
        // from: moment(selectedDateRange[0]).format("YYYY-MM-DD"),
        // to: moment(selectedDateRange[1]).format("YYYY-MM-DD"),
        month: monthWiseData,
        year: "2023",
      },
    };
    axios
      .get(apiData, dataToken)
      .then((res) => {
        setIsLoading(false);
        setCardData(res.data.cards);
      })
      .catch((err) => setIsErr(true))
      .finally(() => setIsLoading(false));
  };
  React.useEffect(() => {
    if (sessionStorage.getItem("projectid") !== "259") {
      window.location.reload();
    }
  }, []);

  React.useEffect(() => {
    getCardData();
  }, [mapCity, monthWiseData]);

  React.useState(() => {
    getMapData();
    getMapDataGroww();
    getCityData();
  }, []);

  return isLoading || loading3 || loading4 || loading5 ? (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div className="App">
      <Header />
      <br />

      <div style={{ padding: "20px 92px" }}>
        <div>
          {
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch", color: "red" },
              }}
              noValidate
              autoComplete="off"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "white",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ paddingLeft: "10px" }}>
                  {monthWiseData == "all"
                    ? "Active Man Power (This Month)"
                    : "Active Man Power"}
                </div>
                <div>
                  <TextField
                    id="outlined-select-currency"
                    value={
                      monthWiseData == "04"
                        ? "25"
                        : monthWiseData == "05"
                        ? "30"
                        : monthWiseData == "all"
                        ? "30"
                        : "0"
                    }
                    style={{
                      backgroundColor: "#33355F",
                      width: "124px",
                      fontFamily: "Nunito",
                      border: "none",
                      boxShadow: "none",

                      borderRadius: "4px",
                    }}
                    size="small"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={"/userspeople.svg"}
                            alt=""
                            height={"15px"}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      color: "red",
                    }}
                  >
                    <div style={{ paddingLeft: "10px", color: "white" }}>
                      Date Filter
                    </div>
                    <TextField
                      style={{
                        // backgroundColor: "#f0f0f0",

                        backgroundColor: "#33355F",

                        fontFamily: "Nunito",
                        border: "none",
                        boxShadow: "none",

                        borderRadius: "4px",
                      }}
                      color="primary"
                      id="outlined-select-currency"
                      select
                      value={monthWiseData}
                      size="small"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      onChange={(e) => setMonthWiseData(e.target.value)}
                    >
                      {MontlyData.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ paddingLeft: "10px", color: "white" }}>
                    City Filter
                  </div>
                  <TextField
                    style={{
                      // backgroundColor: "#f0f0f0",
                      backgroundColor: "#33355F",
                      fontFamily: "Nunito",
                      border: "none",
                      boxShadow: "none",

                      borderRadius: "4px",
                    }}
                    color="primary"
                    id="outlined-select-currency"
                    select
                    value={mapCity}
                    size="small"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    onChange={(e) => setMapCity(e.target.value)}
                  >
                    {citiesData.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            </Box>
          }
          <br />
        </div>
        <div direction="col">
          <h3 style={{ color: "#fff" }}>Lead Generation</h3>
          <br />
          <Grid container direction="column">
            <Grid item xs={12} style={{ marginBottom: "30px" }}>
              <Grid container direction="row" spacing={3}>
                <Grid item lg={3} md={6} xs={12}>
                  <SideLeftCard
                    name={"Surveys"}
                    value={cardData?.total_survey}
                    today_value={cardData?.today_lead}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                  <SideLeftCard
                    name={"POS Match"}
                    value={cardData?.pos_count}
                    today_value={cardData?.pos_count_today}
                    percentage={cardData?.pos_percentage}
                    dataTotal={cardData}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                  <SideLeftCard
                    name={"Interested"}
                    value={cardData?.ondc_count}
                    today_value={cardData?.ondc_count_today}
                    percentage={cardData?.ondc_percentage}
                  />
                </Grid>

                <Grid item lg={3} md={6} xs={12}>
                  <SideLeftCard
                    name={"Proposal sent"}
                    value={cardData?.converted}
                    today_value={cardData?.converted_today}
                    percentage={cardData?.converted_percentage}
                  />
                </Grid>
              </Grid>

              <h3 style={{ color: "#fff", marginTop: "50px" }}>
                Lead Conversion -{" "}
                {/* {Number(cardData?.document) +
                  Number(cardData?.triggered) +
                  Number(cardData?.live)} */}
                {Number(cardData?.document)}
              </h3>
              <br />
              <Grid container direction="row" spacing={3}>
                {/* <Grid item lg={3} md={6} xs={12}>
                  <SideLeftCard
                    name={"Proposal sent"}
                    value={cardData?.converted}
                    today_value={cardData?.converted_today}
                    percentage={cardData?.converted_percentage}
                  />
                </Grid> */}
                {/* <Grid item lg={4} md={6} xs={12}>
                  <SideLeftCard
                    name={"Interested Data"}
                    value={"52"}
                    today_value={""}
                    percentage={""}
                  />
                </Grid> */}
                <Grid item lg={4} md={6} xs={12}>
                  <SideLeftCard
                    name={"Mapping Due"}
                    value={Math.max(
                      0,
                      Number(
                        Number(cardData?.document) -
                          (Number(cardData?.triggered) -
                            Number(cardData?.live)) -
                          Number(cardData?.live)
                      )
                    )}
                    today_value={Math.max(0, cardData?.document_today)}
                    percentage={Math.max(
                      0,
                      (
                        ((Number(cardData?.document) -
                          (Number(cardData?.triggered) -
                            Number(cardData?.live)) -
                          Number(cardData?.live)) /
                          Number(cardData?.document)) *
                        100
                      ).toFixed(2)
                    )}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <SideLeftCard
                    name={"Mapping Done"}
                    value={Math.max(
                      0,
                      Number(
                        Number(cardData?.triggered) - Number(cardData?.live)
                      )
                    )}
                    today_value={Math.max(0, Number(cardData?.triggered_today))}
                    percentage={Math.max(
                      0,
                      Number(
                        (
                          ((Number(cardData?.triggered) -
                            Number(cardData?.live)) /
                            Number(cardData?.document)) *
                          100
                        ).toFixed(2)
                      )
                    )}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <SideLeftCard
                    name={"Live"}
                    value={Math.max(0, Number(cardData?.live))}
                    today_value={Math.max(0, Number(cardData?.live_today))}
                    percentage={Math.max(
                      0,
                      Number(
                        (
                          (cardData?.live / Number(cardData?.document)) *
                          100
                        ).toFixed(2)
                      )
                    )}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid container direction="row" spacing={3}>
                {/* <Grid item lg={4} md={6} xs={12}>
                  <SideLeftCard
                    name={"Proposal sent"}
                    value={cardData?.converted}
                    today_value={cardData?.converted_today}
                    percentage={cardData?.converted_percentage}
                  />
                </Grid> */}
                {/* <Grid item lg={4} md={6} xs={12}>
                  <SideLeftCard
                    name={"Documentation completed"}
                    value={cardData?.document}
                    today_value={cardData?.document_today}
                    percentage={cardData?.document_percentage}
                  />
                </Grid> */}
                {/* <Grid item lg={4} md={6} xs={12}>
                  <SideLeftCard
                    name={"Live"}
                    value={cardData?.live}
                    today_value={cardData?.live_today}
                    percentage={cardData?.live_percentage}
                  />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={4}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item lg={6} xs={12}>
                {/* <SideRightCard name="citychart" /> */}
                <ApexGraph
                  op={"overall"}
                  city={mapCity}
                  // selectedDateRange={selectedDateRange}
                  monthFalcon={monthWiseData}
                />
              </Grid>

              <Grid item lg={6} xs={12}>
                <SideRightCard
                  name="poschart"
                  city={mapCity}
                  // selectedDateRange={selectedDateRange}
                  monthFalcon={monthWiseData}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Grid container style={{ marginTop: "54px" }} spacing={8}>
          <Grid item xs={12} md={12}>
            {/* <ApexGraph op={"overall"} /> */}
          </Grid>
          {/* commenting maps for now */}
          {mapCity == "all" ? (
            ""
          ) : (
            <Grid item xs={12} md={12}>
              {mapCity == "Pune" ? (
                <WMap
                  data={mapData}
                  dataGrow={mapData2}
                  appdatafrom="falcon"
                  mapCity={mapCity}
                />
              ) : mapCity == "Gurugram" ? (
                <Gurgaon
                  data={mapData}
                  dataGrow={mapData2}
                  appdatafrom="falcon"
                  mapCity={mapCity}
                />
              ) : mapCity == "Jaipur" ? (
                <Gurgaon
                  data={mapData}
                  dataGrow={mapData2}
                  appdatafrom="falcon"
                  mapCity={mapCity}
                />
              ) : (
                <MapCard
                  data={mapData}
                  dataGrow={mapData2}
                  appdatafrom="falcon"
                  mapCity={mapCity}
                  // selectedDateRange={selectedDateRange}
                />
              )}
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <TableCard
              name={"ondc"}
              city={mapCity}
              dates={selectedDateRange}
              monthFalcon={monthWiseData}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default App;
