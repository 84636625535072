import React, { useState } from "react";
import backgroundImage from "../../src/Assets/background.svg";
import {
  Avatar,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import OtpInput from "react-otp-input";
import { base_url, getOtp } from "../utils/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Login = () => {
  document.body.style.backgroundColor = "#32355F";
  const [showOtp, setShowOtp] = useState(true);
  const [mbNumber, setMbNumber] = useState("");
  const [code, setCode] = useState("");
  const [err, setErr] = useState("");
  const navigate = useNavigate();
  const handleChangeInput = (code) => setCode(code);

  const handleLoginEnter = () => {
    let apiData = base_url + getOtp;
    let body = { mobile_number: mbNumber };
    axios
      .post(apiData, body)
      .then((res) => {
        setErr(false);

        if (res.data.error == false) {
          setShowOtp(false);
          navigate("/otp");
          // Cookies.set("mbnumber", mbNumber, { expires: 7 });
          sessionStorage.setItem("mbnumber", mbNumber);
          sessionStorage.setItem("projectid", res.data.project_id);
          // localStorage.setItem("mbnumber", mbNumber);
        } else {
          setErr(true);
        }
      })
      .catch((err) => setErr(true))
      .finally(() => {});
  };
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        // backgroundSize: "cover",
        // backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
      }}
    >
      <div style={{ position: "absolute", top: 220, right: 200 }}>
        <Grid
          container
          style={{ color: "#fff" }}
          direction={"column"}
          spacing={5}
        >
          <Grid item>
            <Grid container direction="column" alignItems={"center"}>
              <Grid item>
                <Typography variant="h5">Welcome Back</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption" display={"block"}>
                  {" "}
                  Sign in to continue with Taskmo
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" gap="5px">
              <Grid item>
                <Typography variant="caption">Phone Number</Typography>
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  size="small"
                  style={{
                    backgroundColor: "#4F538B",
                    fontFamily: "Nunito",
                    border: "none",
                    boxShadow: "none",
                    borderRadius: "4px",
                  }}
                  sx={{
                    "& fieldset": { border: "none" },
                    "& .MuiInputBase-root": {
                      color: "#ffffff",
                    },
                  }}
                  id="name"
                  variant="outlined"
                  name="merchant_number"
                  InputProps={{
                    startAdornment: (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Avatar
                          src={"./indian_flag.svg"}
                          variant="square"
                          sx={{ width: "16px", height: "14px" }}
                        />
                        <div style={{ fontSize: "12px" }}>+91&nbsp;&nbsp;</div>
                      </div>
                    ),
                  }}
                  fullWidth
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(e) => setMbNumber(e.target.value)}
                />
                {err && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    Unathorized Access
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: "-10px" }}>
            <Button
              fullWidth
              variant="contained"
              style={{
                textTransform: "capitalize",
                background: "#100B38",
                boxShadow: "none",
              }}
              onClick={handleLoginEnter}
            >
              Get OTP
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Login;
