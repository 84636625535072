import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import PieChart from "./PieChart";
function PieCard({ pgraphData }) {
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        backgroundColor: "#24304B",
        display: "flex",
        justifyItems: "around",
        p: 1,
      }}
    >
      <CardContent sx={{ display: "flex", width: "100%" }}>
        <Grid
          container
          direction={"column"}
          alignContent={"center"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={3}
        >
          <Grid item></Grid>
          <Grid item>
            <PieChart pgraphData={pgraphData}></PieChart>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default PieCard;
