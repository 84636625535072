import React, { useState } from "react";
import Zoom from "react-medium-image-zoom";

import "react-medium-image-zoom/dist/styles.css";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import ImageModal from "./ImageModal";
import { edit_leads } from "../utils/NstoreUtils";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { upload_baseurl, image_display, farming_url } from "../utils/utils";
import ModalFooter from "rsuite/esm/Modal/ModalFooter";
import ImgRotModal from "./ImgRotModal";
import { similar_url } from "../utils/sleepwellUtils";
function MyStore({ data, setFlag, flag, handleClose1, handleClickSnack }) {
  console.log(data, "xz");
  const tokenData = sessionStorage.getItem("token");
  const dataToken = {
    headers: {
      "x-access-token": `${tokenData}`,
    },
  };
  console.log(tokenData);
  const [loading, setIsLoading] = React.useState(true);

  const [loadingModal, setIsLoadingModal] = React.useState(true);
  const [loadingModalp, setIsLoadingModalp] = React.useState(true);
  const [err, setIsErr] = React.useState(false);
  const [apiDataRes, setApiDataRes] = React.useState({});
  const [keyData, setKeyData] = React.useState([]);
  const [keyDataSimilar, setKeyDataSmiliar] = React.useState([]);
  const [valuesData, setValuesData] = React.useState([]);
  const [openM, setOpenM] = React.useState(false);
  const handleOpenM = () => setOpenM(true);
  const handleCloseM = () => setOpenM(false);
  const [openV, setOpenV] = React.useState(false);
  const handleOpenV = () => setOpenV(true);
  const handleCloseV = () => setOpenV(false);
  const [selectedImages, setSelectedImages] = useState();
  const [resType, setType] = React.useState("");
  const [Imgopen, ImgsetOpen] = React.useState(false);
  const [postdata, setpostdata] = useState();
  const [Imgdata, setImgdata] = React.useState([]);
  const [Posimgdata, setPosimgdata] = React.useState([]);
  const [openImage, setOpenImage] = React.useState(false);
  const [address, setAddress] = useState("");
  const handleOpenImage = () => {
    setOpenImage(true);
  };
  const handleCloseImage = () => setOpenImage(false);

  const handleImgOpen = (d) => {
    ImgsetOpen(true);
    setpostdata(data?.lead_id);
  };
  const handleImgClose = () => ImgsetOpen(false);

  const [all, setAll] = useState({});

  const getModalApiData = () => {
    setIsLoading(true);
    axios
      .get(data?.table_data, dataToken)
      .then((res) => {
        setAddress(res?.data?.leads[0].shop_address);
        setApiDataRes(res?.data?.leads[0]);
        setIsLoading(false);
        let keys = Object.keys(res?.data?.leads[0]);
        setKeyData(keys);
        let values = Object.values(res?.data?.leads[0]);
        setValuesData(values);
      })
      .catch((err) => setIsErr(true))
      .finally(() => setIsLoading(false));
  };

  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#24314B",
    color: "#ffff",
    boxShadow: 24,
    p: 4,
    zIndex: 100,
  };
  const inputStyle = {
    padding: 10,
    margin: 10,
    // width: "-webkit-fill-available",
    width: 850,
    backgroundColor: "#24314B",
    outline: "#ffff",
    border: "0.2px solid white",
  };

  const contentStyles = {
    maxHeight: 600, // Set a fixed height for the modal content
    overflow: "auto", // Enable scrolling
  };

  const sendType = (data) => {
    // console.log(data, "imgs");
    setType(data);
  };

  const Save = () => {
    const tokenData = sessionStorage.getItem("token");
    //console.log(all, "alls");
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    all["main_lead_id"] = data.main_lead_id;
    const link = upload_baseurl + edit_leads;
    axios
      .patch(link, all, dataToken)
      .then((res) => {
        if (res.data.error) {
          handleClickSnack("something is wrong");
        } else {
          handleClickSnack("success");
          setFlag(!flag);
          handleCloseM();
          handleClose1();
        }
      })
      .catch((err) => {
        handleClickSnack("error");
      });
  };
  const sendStatusData = () => {
    let apiData = farming_url + image_display;
    let body = {
      lead_id: data?.lead_id,
      distance: "1000",
    };

    console.log(body, "testingbody");
    setIsLoadingModal(true);
    axios
      .post(apiData, body)
      .then((res) => {
        if (res.data.error) {
          console.log("error");
        } else {
          setImgdata(res?.data?.data);
          setIsLoadingModal(false);
          console.log(res?.data?.data);
        }
      })
      .catch(() => setIsErr(true))
      .finally(() => setIsLoadingModal(false));
  };
  const similarDetails = () => {
    let apiData = upload_baseurl + similar_url;
    let body = {
      lead_id: data?.lead_id,
      shop_address: address,
      distance: "1000",
    };
    axios
      .post(apiData, body)
      .then((res) => {
        if (res.data.error) {
          console.log("error");
        } else {
          setKeyDataSmiliar(res?.data?.data);
        }
      })
      .catch(() => setIsErr(true))
      .finally(() => setIsLoadingModal(false));
  };
  const sendStatusDataPos = () => {
    let apiData = farming_url + "/project/distance_cal_city";
    let body = {
      lead_id: data?.lead_id,
    };
    setIsLoadingModalp(true);
    axios
      .post(apiData, body)
      .then((res) => {
        if (res.data.error) {
          console.log("error");
        } else {
          setPosimgdata(res?.data?.data);
          setIsLoadingModalp(false);
        }
      })
      .catch(() => setIsErr(true))
      .finally(() => setIsLoadingModalp(false));
  };
  React.useEffect(() => {
    getModalApiData();
  }, []);

  return loading ? (
    <>...loading</>
  ) : err ? (
    <>something went wrong</>
  ) : (
    <div style={{ color: "white" }}>
      <Button
        variant="contained"
        sx={{ my: 2, px: 2, float: "right" }}
        color="info"
        // onClick={handleOpenM}
      >
        EDIT
      </Button>
      {keyData.map((item, i) => (
        <div
          key={i}
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              border: "1px solid #a3b5d5",
              borderRight: "0px solid  #a3b5d5",
              borderBottom:
                i == keyData.length - 1
                  ? "1px solid  #a3b5d5"
                  : "0px solid  #a3b5d5",
              width: "40%",
              height: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "capitalize",
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            {item.includes("_") ? item.split("_").join(" ") : item}
          </div>
          <div
            style={{
              border: "1px solid  #a3b5d5",
              borderBottom:
                i == keyData.length - 1
                  ? "1px solid  #a3b5d5"
                  : "0px solid  #a3b5d5",
              width: "60%",
              height: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            {String(valuesData[i])?.includes("https") ? (
              // <Zoom>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  role="img"
                  style={{
                    width: "100px",
                    height: "120px",
                    backgroundImage: "url(" + valuesData[i] + ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                  onClick={() => {
                    handleOpenImage();
                    setSelectedImages(valuesData[i]);
                  }}
                />
                {console.log(
                  selectedImages?.target?.style?.backgroundImage,
                  "selectedImages"
                )}

                <ImgRotModal
                  openImage={openImage}
                  handleCloseImage={handleCloseImage}
                  imageurl={selectedImages}
                />
                <span
                  style={{ cursor: "pointer", marginLeft: 15 }}
                  className="text-info"
                  onClick={() => {
                    sendType(item);
                    sendStatusData();
                    sendStatusDataPos();
                    handleImgOpen();
                  }}
                >
                  View Similar Images..
                </span>
              </div>
            ) : // </Zoom>
            valuesData[i] == "" ? (
              "N/A"
            ) : (
              valuesData[i]
            )}
          </div>
        </div>
      ))}
      <Modal
        open={openM}
        onClose={handleCloseM}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <div style={contentStyles}>
            {keyData.map((item, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* {valuesData[i] !== "" && ( */}
                <label
                  style={{
                    width: "-webkit-fill-available",
                    color:
                      item.includes("main_lead_id") ||
                      item.includes("customer_number") ||
                      item.includes("customer_name")
                        ? "gray"
                        : "inherit",
                  }}
                >
                  {!item.endsWith("image") &&
                    (item.includes("_")
                      ? item.split("_").join(" ")
                      : item
                    )?.toUpperCase()}
                </label>
                {/* )} */}

                {item.includes("main_lead_id") ||
                item.includes("customer_number") ||
                item.includes("customer_name") ? (
                  <input
                    type="text"
                    defaultValue={valuesData[i]}
                    style={{ ...inputStyle, pointerEvents: "none" }}
                  />
                ) : (
                  <>
                    {String(valuesData[i])?.includes("https") ? (
                      <></>
                    ) : valuesData[i] === "" ? (
                      <>
                        {" "}
                        {/* <input
                          type="text"
                          defaultValue="N/A"
                          style={inputStyle}
                          onChange={(e) => {
                            setAll({
                              ...all,
                              main_lead_id: data?.main_lead_id,
                              [item]: e.target.value,
                            });
                          }}
                        /> */}
                        {!item.endsWith("image") && (
                          <>
                            {" "}
                            <input
                              type="text"
                              defaultValue="N/A"
                              style={inputStyle}
                              onChange={(e) => {
                                setAll({
                                  ...all,
                                  //  main_lead_id: data?.main_lead_id,
                                  [item]: e.target.value,
                                });
                              }}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <input
                        type="text"
                        defaultValue={valuesData[i]}
                        style={inputStyle}
                        onChange={(e) => {
                          setAll({
                            ...all,
                            // main_lead_id: data?.main_lead_id,
                            [item]: e.target.value,
                          });
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
          <Button
            variant="contained"
            sx={{ float: "right", mt: 4, px: 2 }}
            onClick={Save}
            color="info"
          >
            Save
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openV}
        onClose={handleCloseV}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          {/* <ImageList sx={{ width: 800, height: 650 }}>
            {keyDataSimilar?.map((item) => (
              <>
                <ImageListItem key={item.lead_id}>
                  <img
                    src={`${item?.showroom_front_image}?w=248&fit=crop&auto=format`}
                    srcSet={`${item?.showroom_front_image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.shop_name}
                    loading="lazy"
                  />

                  <ImageListItemBar
                    title={item.shop_name}
                    subtitle={
                      <>
                        <span>Merchant Name: {item.merchant_name}</span>
                        <br />
                        <span>Merchant Number: {item.merchant_number}</span>
                        <br />
                        <span>Distance: {item.distance?.toFixed(2)}</span>
                      </>
                    }
                    description={<span>by: {item.merchant_number}</span>}
                    position="below"
                  />
                </ImageListItem>
                <ImageListItem key={item.lead_id}>
                  <img
                    src={`${item?.pob_image}?w=248&fit=crop&auto=format`}
                    srcSet={`${item?.pob_image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.shop_name}
                    loading="lazy"
                  />

                  <ImageListItemBar
                    title={item.shop_name}
                    subtitle={
                      <>
                        <span>Merchant Name: {item.merchant_name}</span>
                        <br />
                        <span>Merchant Number: {item.merchant_number}</span>
                        <br />
                        <span>Distance: {item.distance?.toFixed(2)}</span>
                      </>
                    }
                    description={<span>by: {item.merchant_number}</span>}
                    position="below"
                  />
                </ImageListItem>
              </>
            ))}
          </ImageList> */}
          {keyDataSimilar && keyDataSimilar.length > 0 ? (
            <ImageList sx={{ width: 800, height: 650 }}>
              {keyDataSimilar.map((item) => (
                <>
                  <ImageListItem key={item.lead_id}>
                    <img
                      src={`${item?.showroom_front_image}?w=248&fit=crop&auto=format`}
                      srcSet={`${item?.showroom_front_image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.shop_name}
                      loading="lazy"
                    />

                    <ImageListItemBar
                      title={item.shop_name}
                      subtitle={
                        <>
                          <span>Merchant Name: {item.merchant_name}</span>
                          <br />
                          <span>Merchant Number: {item.merchant_number}</span>
                          <br />
                          <span>lead Id: {item.lead_id}</span>
                          <br />
                          <span>Distance: {item.distance?.toFixed(2)}</span>
                        </>
                      }
                      description={<span>by: {item.merchant_number}</span>}
                      position="below"
                    />
                  </ImageListItem>
                  <ImageListItem key={item.lead_id}>
                    <img
                      src={`${item?.pob_image}?w=248&fit=crop&auto=format`}
                      srcSet={`${item?.pob_image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.shop_name}
                      loading="lazy"
                    />

                    <ImageListItemBar
                      title={item.shop_name}
                      subtitle={
                        <>
                          <span>Merchant Name: {item.merchant_name}</span>
                          <br />
                          <span>Merchant Number: {item.merchant_number}</span>
                          <br />
                          <span>lead Id: {item.lead_id}</span>
                          <br />
                          <span>Distance: {item.distance?.toFixed(2)}</span>
                        </>
                      }
                      description={<span>by: {item.merchant_number}</span>}
                      position="below"
                    />
                  </ImageListItem>
                </>
              ))}
            </ImageList>
          ) : (
            <p>No data available.</p>
          )}
        </Box>
      </Modal>
      <ImageModal
        handleImgClose={handleImgClose}
        Imgopen={Imgopen}
        Imgdata={Imgdata}
        resType={resType}
        Posimgdata={Posimgdata}
        loadingModal={loadingModal}
        loadingModalp={loadingModalp}
        err={err}
      />
    </div>
  );
}

export default MyStore;
