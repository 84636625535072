const cred_table_data = "/cred/table_data";
const cred_reject_lead = "/cred/reject_lead";
const map_pincode = "/cred/map_pincode";
const cred_city_list = "/cred/search_city";
const base_url = "https://projectsbackend.taskmo.co";
const similar_url = "/cred/similar_shops";
export {
  cred_table_data,
  cred_reject_lead,
  map_pincode,
  cred_city_list,
  base_url,
  similar_url,
};
