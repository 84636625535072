import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Box, CardContent, Grid, MenuItem, TextField } from "@mui/material";
import { DateRangePicker } from "rsuite";
import ClientTable from "../components2/stickerTable";
import MapDATA from "../components2/MapData";
import { upload_baseurl } from "../utils/utils";
import axios from "axios";
import moment from "moment";
import { namma_base_url } from "../utils/namayatriUtils";
import { Card } from "reactstrap";

function NammaYatriStrickers() {
  const token = sessionStorage.getItem("token");
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [card, setCard] = useState({});
  const [citiesData, setcitiesData] = React.useState([]);
  const [mapCity, setMapCity] = React.useState("all");
  const [selectedDateRange, setSelectedDateRange] = useState([
    new Date(),
    new Date(),
  ]);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const getCityData = () => {
    const tokenData = token;

    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    let apiDatacity = namma_base_url + "/namma_yatri/v1/search_city_sticker";

    axios
      .get(apiDatacity, dataToken)
      .then((res) => {
        let ot = res.data.city_list.map((item) => ({
          label: item,
          value: item,
        }));

        setcitiesData(ot);
      })
      .catch((err) => console.log(err));
  };

  console.log("Selected Date Range:", selectedDateRange, "jk");

  const handleDateRangeChange = (value) => {
    setSelectedDateRange(value);
  };
  const cardData = () => {
    const url = upload_baseurl + "/namma_yatri/v1/card_data_sticker";
    const tokenData = token;

    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        city: mapCity,
        from: moment(selectedDateRange[0]).format("YYYY-MM-DD"),
        to: moment(selectedDateRange[1]).format("YYYY-MM-DD"),
      },
    };

    axios
      .get(url, dataToken)
      .then((res) => {
        setCard(res.data.cards_data);
      })
      .catch((err) => console.log(err));
  };
  React.useEffect(() => {
    if (sessionStorage.getItem("projectid") !== "326") {
      window.location.reload();
    }
  }, []);

  React.useEffect(() => {
    if (selectedDateRange?.length == 2) {
      cardData();
    }
  }, [date, endDate, startDate, selectedDateRange, mapCity]);

  useEffect(() => {
    getCityData();
  }, []);

  return (
    <div style={{ margin: 10 }}>
      <Header />
      <div>
        <Grid container direction="column" gap={2}>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
            style={{
              display: "flex",
              //flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end",
              color: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10px",
                color: "white",
              }}
            >
              City Filter
              <TextField
                style={{
                  backgroundColor: "#24314B",
                  fontFamily: "Nunito",
                  border: "none",
                  boxShadow: "none",
                  borderRadius: "4px",
                  margin: "10px",
                  width: "fit-content",
                  padding: "6px 10px",
                }}
                color="primary"
                id="outlined-select-currency"
                select
                value={mapCity}
                size="small"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                onChange={(e) => setMapCity(e.target.value)}
              >
                {citiesData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10px",
                color: "white",
              }}
            >
              Date Filter
              <DateRangePicker
                appearance="subtle"
                placeholder="Date Range Picker"
                value={selectedDateRange}
                onChange={handleDateRangeChange}
                style={{
                  textTransform: "unset",
                  boxShadow: "0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                  backgroundColor: "#24304B",
                  borderRadius: "16px",
                  margin: "10px",
                  padding: "6px 10px",
                }}
              />
            </div>
          </Box>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={4}>
                <MapDATA
                  hname="Total Leads"
                  Cardvalue={card?.total_leads}
                  percentage={card?.total_leads_today}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MapDATA
                  hname="Aprroved Leads"
                  Cardvalue={card?.approve_leads}
                  percentage={card?.approve_leads_today}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MapDATA
                  hname="Pending Leads"
                  Cardvalue={card?.pending_leads}
                  percentage={card?.pending_leads_today}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: "#24304B",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <ClientTable
                selectedDateRange={selectedDateRange}
                token={token}
                mapCity={mapCity}
                startDate={startDate}
                endDate={endDate}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default NammaYatriStrickers;
