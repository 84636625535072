import { Box, Card, CardContent, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import TableCard from "../components/TableCard";
import TableTasker from "./TableTasker";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TestCard = ({ name = "", dates, city }) => {
  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Card
        //   sx={{ width: "41.5rem", height: "56.125rem", borderRadius: "10px" }}
        variant="outlined"
        style={{
          backgroundColor: name == "nammayatri" ? "#24304B" : "#32355F",
          borderRadius: "10px",
          // height: "940px",
          height: "600px",
          padding: "10px",
        }}
      >
        <CardContent style={{ padding: "0" }}>
          <TableCard name={name} value={value} dates={dates} city={city} />

          {/* <TabPanel value={value} index={1}>
            <TableTasker />
          </TabPanel> */}
        </CardContent>
      </Card>
    </div>
  );
};

export default TestCard;
