import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import moment from "moment";

const SideLeftCard = ({ name, value, today_value, percentage, dataTotal }) => {
  let otSecondPercentage;

  const currentDate = new Date();
  const currentYear = moment(new Date()).format("YYYY");
  const currentMonth = moment(new Date()).format("MM");

  const dates = [
    { date: `${currentYear}-${currentMonth}-01`, data: 37 },
    { date: `${currentYear}-${currentMonth}-02`, data: 33 },
    { date: `${currentYear}-${currentMonth}-03`, data: 35 },
    { date: `${currentYear}-${currentMonth}-04`, data: 36 },
    { date: `${currentYear}-${currentMonth}-05`, data: 40 },
    { date: `${currentYear}-${currentMonth}-06`, data: 37 },
    { date: `${currentYear}-${currentMonth}-07`, data: 39 },
    { date: `${currentYear}-${currentMonth}-08`, data: 42 },
    { date: `${currentYear}-${currentMonth}-09`, data: 38 },
    { date: `${currentYear}-${currentMonth}-10`, data: 35 },
    { date: `${currentYear}-${currentMonth}-11`, data: 23 },
    { date: `${currentYear}-${currentMonth}-12`, data: 33 },
    { date: `${currentYear}-${currentMonth}-13`, data: 35 },
    { date: `${currentYear}-${currentMonth}-14`, data: 36 },
    { date: `${currentYear}-${currentMonth}-15`, data: 40 },
    { date: `${currentYear}-${currentMonth}-16`, data: 37 },
    { date: `${currentYear}-${currentMonth}-17`, data: 39 },
    { date: `${currentYear}-${currentMonth}-18`, data: 42 },
    { date: `${currentYear}-${currentMonth}-19`, data: 38 },
    { date: `${currentYear}-${currentMonth}-20`, data: 35 },
    { date: `${currentYear}-${currentMonth}-21`, data: 37 },
    { date: `${currentYear}-${currentMonth}-22`, data: 33 },
    { date: `${currentYear}-${currentMonth}-23`, data: 35 },
    { date: `${currentYear}-${currentMonth}-24`, data: 36 },
    { date: `${currentYear}-${currentMonth}-25`, data: 40 },
    { date: `${currentYear}-${currentMonth}-26`, data: 37 },
    { date: `${currentYear}-${currentMonth}-27`, data: 39 },
    { date: `${currentYear}-${currentMonth}-28`, data: 42 },
    { date: `${currentYear}-${currentMonth}-29`, data: 38 },
    { date: `${currentYear}-${currentMonth}-30`, data: 41 },
    { date: `${currentYear}-${currentMonth}-31`, data: 33 },
  ];

  if (name == "POS Match") {
    otSecondPercentage =
      Number(dataTotal?.pos_count) / Number(dataTotal?.total_survey);

    console.log(otSecondPercentage, "perdentaget");
  }

  return (
    <div>
      <Box>
        <Card
          variant="outlined"
          sx={{
            backgroundColor: "#32355F",
            borderRadius: "14px",
            // width: 373,
            // width: "90%",
            // height: 122,
            // height: "15.5vh",
            minHeight: 122,
            // width: "370px",
          }}
        >
          {" "}
          <CardContent>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ color: "#fff" }}
            >
              <Grid item>
                <Grid
                  container
                  direction="column"
                  alignItems={"start"}
                  justifyContent={"space-between"}
                  gap="10px"
                >
                  <Grid item>
                    <Typography variant="subtitle2" color={"#e6e6e6"}>
                      {name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "32px", fontWeight: "600" }}
                    >
                      {value}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        // marginLeft: "10px",
                        display: "flex",
                        alignItems: "flex-end",
                        // color: "#32355F",
                      }}
                    >
                      + {today_value}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction={"column"}
                  style={{ alignItems: "flex-end" }}
                >
                  {name == "Surveys" ? (
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "200",
                        // background: "#8280aa78",
                        padding: "5px 18px",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {/* <div style={{ fontSize: "16px" }}>Active  Power</div>
                        <div style={{ fontSize: "22px" }}>19</div> */}
                        <div style={{ fontSize: "16px" }}></div>
                        <div style={{ fontSize: "22px" }}></div>
                      </div>
                    </Typography>
                  ) : (
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "200",
                        background: "#8280aa78",
                        padding: "5px 18px",
                        borderRadius: "5px",
                        color: "#fff",
                      }}
                    >
                      {name == "POS Match"
                        ? (otSecondPercentage * 100).toFixed(2)
                        : percentage}{" "}
                      %
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default SideLeftCard;
