import React from "react";
import Chart from "react-apexcharts";

const Piechart = ({ data, name }) => {
  console.log(data, name, "data");
  const chartData = {
    // series: data.posArray : data.leadsArray,
    series: data.leadsArray,

    options: {
      // colors: ["#2D64A8", "#548cd1", "#B6BDFF"],
      //   legend: false,
      //   dataLabels: {
      //     enabled: true,
      //   },
      theme: {
        mode: "light",
        palette: "palette1",
        monochrome: {
          enabled: true,
          color: "#211d4c",
          shadeTo: "light",
          shadeIntensity: 0.25,
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
      plotOptions: {
        pie: {
          states: {
            hover: {
              color: "#red", // Set the hover color to #00bcd4
            },
          },
        },
      },
      legend: {
        show: false,
        position: "bottom",
        labels: {
          colors: "#fff",
        },
      },
      chart: {
        width: 380,
        type: "pie",
      },

      labels: name == "poschart" ? data.posArray : data.cityArray,
    },
  };

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type="pie"
      width={400}
      height={400}
    />
  );
};

export default Piechart;
