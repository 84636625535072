import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import React, { useEffect, useState } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { upload_baseurl, upload_url } from "../utils/utils";

import axios from "axios";
import { CSVLink } from "react-csv";
import moment from "moment";
import ClientTable2 from "./clientTable2";

import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

import { Grid } from "rsuite";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import MyStore from "./MyStore";
import { useCategory } from "./CategoryContext";
import { Card } from "reactstrap";

const ClientTable = ({
  token,
  selectedDateRange,
  mapCity,
  startDate,
  endDate,
}) => {
  const [tabledata, settable] = useState([]);

  const [open1, setOpen1] = useState(false);
  const [maxWidth, setMaxWidth] = useState("xl");
  const [flag, setFlag] = useState(false);
  const [openM, setOpenM] = useState(false);
  const [dataEach, setDataEach] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const handleClickOpenView = (d) => {
    setDataEach(d);
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const tokenData = token;

  const get_table_Data = () => {
    const tableurl = upload_baseurl + "/namma_yatri/v1/table_data_sticker";
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        city: mapCity,
        from: moment(selectedDateRange[0])?.format("YYYY-MM-DD"),
        to: moment(selectedDateRange[1])?.format("YYYY-MM-DD"),
      },
    };

    axios
      .get(tableurl, dataToken)
      .then((res) => {
        console.log(res.data.project_data, "rr");
        settable(res.data.project_data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "35px", // override the row height
        backgroundColor: "#24314B",
        color: "#fff",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#24314B",
        color: "#fff",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#24314B",
        color: "#ffff",
      },
    },
  };

  const columns = [
    {
      name: "Lead Id",
      selector: (row) => row.main_lead_id,
      width: "100px",
      sortable: true,
      cell: (d) => (
        <div style={{ cursor: "pointer" }} className="text-info">
          <u>
            <span style={{ padding: 5, fontSize: 12 }}>{d.main_lead_id}</span>
          </u>
        </div>
      ),
    },
    {
      name: "Date",
      width: "150px",
      selector: (row) => row.date,
      // cellExport: (row) => String(row.date),
      cell: (d) => <>{d.date}</>,
    },
    {
      name: "Customer Name",
      width: "150px",
      selector: (row) => String(row.merchant_name),
      // cellExport: (row) => String(row.merchant_name),
      cell: (d) => <>{d.merchant_name}</>,
    },
    {
      name: "Customer Number",
      width: "150px",
      selector: (row) => row.merchant_number,
      // cellExport: (row) => String(row.rc_number),
      cell: (d) => <>{d.merchant_number}</>,
    },
    {
      name: "Vehicle Number",
      width: "150px",
      selector: (row) => row.rc_number,
      // cellExport: (row) => String(row.rc_number),
      cell: (d) => <>{d.rc_number}</>,
    },

    {
      name: "City",
      width: "200px",
      selector: (row) => row.city,
      // cellExport: (row) => String(row.city),
      cell: (d) => <>{d.city}</>,
    },

    {
      name: "State",
      width: "200px",
      selector: (row) => row.state,
      // cellExport: (row) => String(row.state),
      cell: (d) => <>{d.state}</>,
    },

    {
      name: "KYC Status",
      width: "150px",
      omit: true,
      selector: (row) => row.client_qc_remark,
      cell: (d) => <>{d?.kyc_status}</>,
    },

    {
      name: "Client status",
      width: "150px",
      omit: false,
      selector: (row) => row.client_status,
      cell: (d) => <>{d.client_status}</>,
    },

    {
      name: "View Details",
      cell: (d) =>
        d.client_qc == "rejected" || d.client_qc == "approved" ? (
          <>
            <p>----</p>{" "}
          </>
        ) : (
          <>
            <div>
              <FileOpenIcon onClick={() => handleClickOpenView(d)} />
            </div>
          </>
        ),
    },
  ];

  const tableData = {
    columns: columns,
    data: tabledata,
  };

  useEffect(() => {
    if (selectedDateRange?.length == 2) {
      get_table_Data();
    }
  }, [startDate, endDate, selectedDateRange, mapCity, flag]);
  return (
    <>
      <Box
        sx={{
          overflow: "auto",
          flexGrow: 1,
          maxWidth: "100%",
          padding: "20px",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            backgroundColor: "#24304B",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              visibility:
                tableData?.length === 0 || tableData === undefined
                  ? "hidden"
                  : "visible",
              padding: "10px",
            }}
          >
            <CSVLink filename="Report.csv" data={tabledata ?? []}>
              <IconButton variant="text">
                <DownloadIcon style={{ color: "white" }} />
              </IconButton>
            </CSVLink>
          </div>
          <CardContent style={{ padding: "0" }}>
            <DataTableExtensions
              {...tableData}
              export={false}
              print={false}
              exportHeaders
              filterPlaceholder={`Search`}
              className="filter_text"
            >
              <DataTable
                columns={columns}
                data={tabledata}
                customStyles={customStyles}
                paginationRowsPerPageOptions={[10, 30, 50, 100]}
                fixedHeader={true}
                fixedHeaderScrollHeight={"calc(100vh - 370px)"}
                pagination
              />
            </DataTableExtensions>
            <Dialog
              fullScreen
              maxWidth="lg"
              open={open1}
              onClose={handleClose1}
            >
              <DialogTitle
                sx={{
                  backgroundColor: "#24304B",
                  color: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>View Details</div>
                  <div onClick={handleClose1}>
                    <HighlightOffOutlinedIcon style={{ cursor: "pointer" }} />
                  </div>
                </div>
              </DialogTitle>

              <DialogContent
                sx={{
                  backgroundColor: "#24304B",
                }}
              >
                <ClientTable2
                  data={dataEach}
                  handleClose1={handleClose1}
                  setFlag={setFlag}
                  flag={flag}
                />
              </DialogContent>
            </Dialog>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default ClientTable;
