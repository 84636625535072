// import React, { useEffect } from "react";
// import Card from "@mui/material/Card";
// import ApexCharts from "apexcharts/dist/apexcharts.min.js";
// function Linechart({ graphData }) {
//   useEffect(() => {
//     var optionsLine = {
//       chart: {
//         height: 400,
//         type: "line",
//         foreColor: "#FFFFFF",
//         zoom: {
//           enabled: false,
//         },
//         dropShadow: {
//           enabled: true,
//           top: 3,
//           left: 2,
//           blur: 4,
//           opacity: 1,
//         },
//         toolbar: {
//           show: false,
//         },
//       },

//       id: "line-chart",
//       animations: {
//         enabled: true,
//         easing: "linear",
//         dynamicAnimation: {
//           speed: 1000,
//         },
//       },
//       stroke: {
//         show: true,
//         curve: "smooth",
//         lineCap: "butt",
//         colors: undefined,
//         width: 3,
//         dashArray: 0,
//       },
//       colors: ["#b4ff69", "#beb5f4", "#f9b754"],
//       series: graphData.series,
//       markers: {
//         size: 6,
//         strokeWidth: 0,
//         hover: {
//           size: 9,
//         },
//       },
//       grid: {
//         show: true,
//         padding: {
//           bottom: 0,
//         },
//       },
//       xaxis: {
//         categories: graphData?.months,
//         labels: {
//           style: {
//             colors: "#fff",
//           },
//         },
//       },
//       legend: {
//         position: "bottom",
//         horizontalAlign: "right",
//         // offsetX: 0,
//         // offsetY: 10,
//         labels: {
//           colors: "#fff",
//         },
//       },
//       yaxis: {
//         title: {
//           // text: "Sales (in USD)",
//           style: {
//             color: "#fff", // Set the color of the Y-axis title
//           },
//         },
//         labels: {
//           style: {
//             colors: "#fff", // Set the color of the Y-axis labels
//           },
//         },
//       },
//     };

//     var chartLine1 = new ApexCharts(
//       document.querySelector("#line-adwords"),
//       optionsLine
//     );
//     chartLine1.render();

//     return () => {
//       chartLine1.destroy();
//     };
//   }, [graphData]);

//   return (
//     <div style={{ display: "flex", flexDirection: "column" }}>
//       <Card
//         xs={12}
//         md={5}
//         sx={{
//           background: "#24304B",
//           p: 2,
//         }}
//         variant="outlined"
//       >
//         <div id="line-adwords"></div>
//       </Card>
//     </div>
//   );
// }

// export default Linechart;

// import React, { useEffect, useState } from "react";
// import Chart from "react-apexcharts";
// import Card from "@mui/material/Card";

// function LineChart({ graphData }) {
//   const [options, setOptions] = useState({
//     chart: {
//       height: 400,
//       type: "line",
//       foreColor: "#FFFFFF",
//       zoom: {
//         enabled: false,
//       },
//       dropShadow: {
//         enabled: true,
//         top: 3,
//         left: 2,
//         blur: 4,
//         opacity: 1,
//       },
//       toolbar: {
//         show: false,
//       },
//     },
//     stroke: {
//       show: true,
//       curve: "smooth",
//       lineCap: "butt",
//       colors: undefined,
//       width: 3,
//       dashArray: 0,
//     },
//     colors: ["#b4ff69", "#beb5f4", "#f9b754"],
//     series: [],
//     markers: {
//       size: 6,
//       strokeWidth: 0,
//       hover: {
//         size: 9,
//       },
//     },
//     grid: {
//       show: true,
//       padding: {
//         bottom: 0,
//       },
//     },
//     xaxis: {
//       categories: [],
//       labels: {
//         style: {
//           colors: "#fff",
//         },
//       },
//     },
//     legend: {
//       position: "bottom",
//       horizontalAlign: "right",
//       labels: {
//         colors: "#fff",
//       },
//     },
//     yaxis: {
//       title: {
//         style: {
//           color: "#fff",
//         },
//       },
//       labels: {
//         style: {
//           colors: "#fff",
//         },
//       },
//     },
//   });

//   useEffect(() => {
//     if (graphData && graphData.series) {
//       setOptions((prevOptions) => ({
//         ...prevOptions,
//         series: graphData.series,
//         xaxis: {
//           ...prevOptions.xaxis,
//           categories: graphData?.months || [],
//         },
//       }));
//     }
//   }, [graphData]);

//   return (
//     <Card
//       xs={12}
//       md={5}
//       variant="outlined"
//       sx={{
//         background: "#24304B",
//         p: 2,
//       }}
//     >
//       {graphData && graphData.series ? (
//         <Chart
//           options={options}
//           series={options.series}
//           type="line"
//           height={400}
//         />
//       ) : (
//         <div>No data available</div>
//       )}
//     </Card>
//   );
// }

// export default LineChart;

import React from "react";
import Chart from "react-apexcharts";
import Card from "@mui/material/Card";

const LineChart = ({ graphData }) => {
  if (!graphData || !graphData.months) {
    return null; // Return null or handle the case when graphData or graphData.months is not available
  }

  const data = {
    series: [
      {
        name: "Survey",
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
      },
      {
        name: "Onboarding",
        data: [20, 45, 25, 57, 39, 65, 82, 71, 145],
      },
    ],
    options: {
      colors: ["#b4ff69", "#beb5f4", "#f9b754"],
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 3,
        dashArray: 0,
      },

      legend: {
        position: "bottom",
        horizontalAlign: "right",
        // offsetX: 0,
        // offsetY: 10,
        labels: {
          colors: "#fff",
        },
      },
      tooltip: {
        enabled: true,
      },
      // title: {
      //   text: name == "today" ? "Today's Chart" : "Overall Chart",
      //   style: {
      //     color: "#fff",
      //   },
      // },

      chart: {
        toolbar: {
          show: false,
        },

        id: "line-chart",
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
      },
      xaxis: {
        categories: graphData.months,
        labels: {
          style: {
            colors: "#fff",
          },
        },
      },

      yaxis: {
        title: {
          // text: "Sales (in USD)",
          style: {
            color: "#fff", // Set the color of the Y-axis title
          },
        },
        labels: {
          style: {
            colors: "#fff", // Set the color of the Y-axis labels
          },
        },
      },
    },
  };

  return (
    <Card
      xs={12}
      md={5}
      variant="outlined"
      sx={{
        background: "#24304B",
        p: 2,
      }}
    >
      <Chart
        options={data.options}
        series={graphData.series}
        type="line"
        height={400}
      />
    </Card>
  );
};

export default LineChart;
