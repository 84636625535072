import React, { useEffect, useState } from "react";
import Header from "../components2/Header";
import SleepTable from "../components2/SleepTable";
import Grid from "@mui/material/Grid";
import Example from "../components2/map/exaomle";
import { map_pincode, sleepwell_city_list } from "../utils/sleepwellUtils";
import { base_url } from "../utils/sleepwellUtils";
import { upload_baseurl } from "../utils/utils";
import axios from "axios";
import { Box, MenuItem, TextField } from "@mui/material";
import Jaipur from "../components2/map/Jaipur";
import Bengaluru from "../components2/map/bengaluru";
import Mysuru from "../components2/map/Mysuru";
import Mumbai from "../components2/map/Mumbai";
import Chennai from "../components2/map/Chennai";

function SleepWell() {
  document.body.style.backgroundColor = "#15192C";

  const [cityList, setlist] = useState([]);
  const [mapCity, setMapCity] = React.useState("all");
  const [mapData, setMapData] = useState([]);
  const tokenData = sessionStorage.getItem("token");

  const get_mapdata = async () => {
    const link = base_url + map_pincode;
    const dataToken = {
      headers: {
        "x-access-token": tokenData,
      },
      params: {
        city: mapCity,
      },
    };

    try {
      const response = await axios.get(link, dataToken);
      // console.log(response?.data?.city_data, "you");
      setMapData(response?.data?.city_data);
    } catch (error) {
      console.log(error, "err");
    }
  };

  const getList = () => {
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
    };
    let apiData = upload_baseurl + sleepwell_city_list;
    axios
      .get(apiData, dataToken)
      .then((res) => {
        console.log(res, "hii");
        let ot = res?.data?.city_list;
        let temp2 = ot.map((item) => ({
          label: item,
          value: item,
        }));
        setlist(temp2);
        console.log(ot, "hh");
      })
      .catch((err) => console.log(err, "err"));
  };

  useEffect(() => {
    get_mapdata();
    getList();
  }, [mapCity]);

  React.useEffect(() => {
    if (sessionStorage.getItem("projectid") !== "303") {
      window.location.reload();
    }
  }, []);
  return (
    <div style={{ padding: "20px 92px" }}>
      <Header />
      <div>
        {
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              color: "white",
            }}
          >
            <div style={{ display: "flex" }}>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "10px",
                    color: "white",
                  }}
                >
                  City Filter
                  <TextField
                    style={{
                      // backgroundColor: "#f0f0f0",
                      backgroundColor: "#24304B",
                      fontFamily: "Nunito",
                      border: "none",
                      boxShadow: "none",
                    }}
                    color="primary"
                    id="outlined-select-currency"
                    select
                    value={mapCity}
                    size="small"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    onChange={(e) => {
                      setMapCity(e.target.value);
                    }}
                  >
                    {cityList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label?.toUpperCase()}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            </div>
          </Box>
        }
        <br />
      </div>
      {/* <Example mapData={mapData} /> */}
      {mapCity !== "all" ? (
        <>
          <h3 style={{ color: "#fff", marginTop: "50px" }}>Map Data</h3>

          {mapCity === "Jaipur" ? (
            <Jaipur mapData={mapData} mapCity={mapCity} />
          ) : mapCity === "Pune" ? (
            <Example mapData={mapData} mapCity={mapCity} />
          ) : mapCity === "Bengaluru" ? (
            <Bengaluru mapData={mapData} mapCity={mapCity} />
          ) : mapCity === "Mysuru" ? (
            <Mysuru mapData={mapData} mapCity={mapCity} />
          ) : mapCity === "Mumbai" ? (
            <Mumbai mapData={mapData} mapCity={mapCity} />
          ) : mapCity === "Chennai" ? (
            <Chennai mapData={mapData} mapCity={mapCity} />
          ) : (
            <h2 style={{ color: "#ffff", maxWidth: "100%" }}>
              No map available for {mapCity}
            </h2>
          )}
        </>
      ) : (
        ""
      )}
      <Grid item xs={12} md={12} marginTop={10}>
        <SleepTable />
      </Grid>
    </div>
  );
}

export default SleepWell;
