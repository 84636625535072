import mapboxgl from 'mapbox-gl';
import geojson from '../pages/pinPune.json';
import React, { useRef,useEffect ,useState} from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid, TextField } from "@mui/material";
import LinearGradient from "./LinearGradient.js";
import ReactTooltip from 'react-tooltip';


mapboxgl.accessToken = 'pk.eyJ1Ijoia2FydGlra3NoZXR0YXIiLCJhIjoiY2xpMWNqdWFkMDd1eDNsbzNwM2MyemczaCJ9.1NnoucNrjFINkPyLPcpf8g';
function WMap({data,dataGrow,appdatafrom = "nothing"}) {
  console.log(mapboxgl.accessToken,'kk')
    const mapContainerRef = useRef(null);
    const [hoveredFeature, setHoveredFeature] = useState(null);
    const [tooltipContent, setTooltipContent] = useState("");
    const popup = new mapboxgl.Popup({
        closeButton: false
      });

      const [singleAreaData, setSingleAreaData] = React.useState({});
      
      let current ;
            let currentGrow ;
      let obj = { ...current };
      obj.grow = currentGrow;
      const COLOR_RANGE = [
        //   "#ffedea",
        //   "#ffcec5",
        //   "#ffad9f",
        //   "#ff8a75",
        //   "#ff5533",
        //   "#e2492d",
        //   "#be3d26",
        //   "#9a311f",
        //   "#782618",
        "#95d5b2",
        "#74c69d",
        "#40916c",
      
        //   "#d8f3dc",
      ];
    
      const gradientData = {
        fromColor: COLOR_RANGE[0],
        toColor: COLOR_RANGE[COLOR_RANGE.length - 1],
        min: 0,
        max: data.reduce(
          (max, item) => (item.total_onboarded > max ? item.total_onboarded : max),
          0
        ),
      };
    useEffect(() => {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
       style: 'mapbox://styles/mapbox/streets-v11',
        //center: turf.centroid(geojson).geometry.coordinates,
        //style:'mapbox://styles/kartikkshettar/clhlzwadq01jh01r02x7e4pj9',
        center:[74.2,18.68],
        zoom: 7.2,
        dragPan: false,
        doubleClickZoom: false,
        attributionControl: false // disable the default attribution control
      });
    
      
      map.on('load', () => {
        var style = map.getStyle(); 
        map.addLayer(
            {
              id: 'background-layer',
              type: 'background',
              paint: {
                'background-color':  "#33355F",
              }
            },
            'waterway-label'
          );
         map.addSource('my-data', {
           type: 'geojson',
           data: geojson,
        });

        map.addControl(new mapboxgl.AttributionControl({
            compact: true
          }));
  
        map.addLayer({
            id: 'my-layer',
            type: 'fill',
            source: 'my-data',
            paint: {
                'fill-color': '#b7e4c7',
                'fill-outline-color': '#fff',
                'fill-opacity': 1,
            },
          });

          map.on('mousemove', 'my-layer', (e) => {
              
          let obj = {...current} ;
          obj.grow = currentGrow;
          obj.name = e.features[0].properties.officename;
          setSingleAreaData(obj);

            current = data.find(
                (s) => s.pincode == e.features[0].properties.pincode);
                currentGrow = dataGrow.find(
                    (s) => s.pincode == e.features[0].properties.pincode
                  );
                  console.log(current ,'srh')
            map.getCanvas().style.cursor = 'pointer';
            if (e.features.length > 0) {
              if (hoveredFeature) {
                map.setFeatureState(
                  { source: 'my-data', id: hoveredFeature },
                  { hover: false }
                );
              }
              setHoveredFeature(e.features[0].properties.pincode);
              console.log(e.features[0],'pin')
              map.setFeatureState(
                { source: 'my-data', id: hoveredFeature },
                { hover: true }
              );
            }
            map.setPaintProperty('my-layer', 'fill-color', [
              'case',
              ['==', ['get', 'pincode'], e.features[0].properties.pincode],
              '#ccc',
              '#b7e4c7'
            ]);
            const coordinates = e.features[0].geometry.coordinates.slice();
            const lngLat = [coordinates[0][1][0], coordinates[0][1][1]];
            const name = e.features[0].properties.officename;
            console.log(lngLat,name,'la')
            popup.setLngLat(lngLat).setHTML(`<span  style="color: black">${name}</span>`).addTo(map);
          });
    
          map.on('mouseleave', 'my-layer', () => {
            if (hoveredFeature) {
              map.setFeatureState(
                { source: 'my-data', id: hoveredFeature},
                { hover: false }
              );
            }
            setHoveredFeature(null);
            map.setPaintProperty('my-layer', 'fill-color', '#b7e4c7');
            map.getCanvas().style.cursor = '';
            popup.remove();
          });


              // Find all layers that are labels
              style.layers.forEach(function(layer) {
                // Check if the layer is a label layer
                if (layer.type === 'symbol' && layer.layout['text-field']) {
                  // Hide the label layer
                  map.setLayoutProperty(layer.id, 'visibility', 'none');
                }
              });
       });     
          

      map.scrollZoom.disable(); // disable zoom on scroll
      return () => {
        map.remove();
      };
    }, []);
  
    return <>
    <Card
  style={{
    boxShadow: "0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
    backgroundColor:"#33355F",
    borderRadius: "16px",
    height: "680px",
    padding: "10px",
  }}
>
  <CardContent>
    <Grid container direction={"row"}>
      <Grid item xs={4}>
        {appdatafrom == "falcon" ? (
          <Grid
            container
            direction="column"
            spacing={4}
            justifyContent={"center"}
            alignItems={"center"}
            style={{ marginTop: "50px" }}
          >
            <Grid item>
              <Typography
                style={{
                  color: "white",
                  fontSize: "22px",
                  fontWeight: 600,
                }}
              >
                {singleAreaData?.name ?? "-"}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Card
                        style={{
                          background: "#212E46",
                          color: "#fff",
                          width: "200px",
                          boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                        }}
                      >
                        <CardContent>
                          <Grid
                            container
                            direction="column"
                            alignItems={"center"}
                          >
                            <Grid
                              item
                              style={{ fontSize: "24px", fontWeight: 600 }}
                            >
                              {singleAreaData?.total_onboarded ?? 0}
                            </Grid>
                            <Grid item style={{ fontSize: "12px" }}>
                              Surveys
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item>
                      <Card
                        style={{
                          background: "#212E46",
                          color: "#fff",
                          width: "200px",
                          boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                        }}
                      >
                        <CardContent>
                          <Grid
                            container
                            direction="column"
                            alignItems={"center"}
                          >
                            <Grid
                              item
                              style={{ fontSize: "24px", fontWeight: 600 }}
                            >
                              {singleAreaData?.interested ?? 0}
                            </Grid>
                            <Grid item style={{ fontSize: "12px" }}>
                              Interested
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Card
                        style={{
                          background: "#212E46",
                          color: "#fff",
                          width: "200px",
                          boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                        }}
                      >
                        <CardContent>
                          <Grid
                            container
                            direction="column"
                            alignItems={"center"}
                          >
                            <Grid
                              item
                              style={{ fontSize: "24px", fontWeight: 600 }}
                            >
                              {singleAreaData?.grow?.proposal ?? 0}
                            </Grid>
                            <Grid item style={{ fontSize: "12px" }}>
                              Proposal Sent
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item>
                      <Card
                        style={{
                          background: "#212E46",
                          color: "#fff",
                          width: "200px",
                          boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                        }}
                      >
                        <CardContent>
                          <Grid
                            container
                            direction="column"
                            alignItems={"center"}
                          >
                            <Grid
                              item
                              style={{ fontSize: "24px", fontWeight: 600 }}
                            >
                              {singleAreaData?.grow?.live ?? 0}
                            </Grid>
                            <Grid item style={{ fontSize: "12px" }}>
                              Restaurant Live
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            spacing={4}
            justifyContent={"center"}
            alignItems={"center"}
            style={{ marginTop: "50px" }}
          >
            <Grid item>
              <Typography
                style={{
                  color: "white",
                  fontSize: "22px",
                  fontWeight: 600,
                }}
              >
                {singleAreaData?.name ?? "-"}
              </Typography>
            </Grid>
            <Grid item>
              <Card
                style={{
                  background: "#212E46",
                  color: "#fff",
                  width: "200px",
                  boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                }}
              >
                <CardContent>
                  <Grid container direction="column" alignItems={"center"}>
                    <Grid
                      item
                      style={{ fontSize: "24px", fontWeight: 600 }}
                    >
                      {singleAreaData?.total_onboarded ?? 0}
                    </Grid>
                    <Grid item style={{ fontSize: "12px" }}>
                      OnBoarded
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              {appdatafrom == "nothing" ? (
                <Card
                  style={{
                    background: "#212E46",
                    color: "#fff",
                    width: "200px",
                    boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                  }}
                >
                  <CardContent>
                    <Grid
                      container
                      direction="column"
                      alignItems={"center"}
                    >
                      <Grid
                        item
                        style={{ fontSize: "24px", fontWeight: 600 }}
                      >
                        {singleAreaData?.one_ride_count ?? 0}
                      </Grid>
                      <Grid item style={{ fontSize: "12px" }}>
                        One Ride Count
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ) : (
                ""
              )}
            </Grid>
            <Grid item>
              {appdatafrom == "nothing" ? (
                <Card
                  style={{
                    background: "#212E46",
                    color: "#fff",
                    width: "200px",
                    boxShadow: " 0px 3px 8px 3px rgba(0, 0, 0, 0.19)",
                  }}
                >
                  <CardContent>
                    <Grid
                      container
                      direction="column"
                      alignItems={"center"}
                    >
                      <Grid
                        item
                        style={{ fontSize: "24px", fontWeight: 600 }}
                      >
                        {singleAreaData?.three_ride_count ?? 0}
                      </Grid>
                      <Grid item style={{ fontSize: "12px" }}>
                        Three Ride Count
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={8}>
      <>
      <LinearGradient data={gradientData} />
      <div ref={mapContainerRef} style={{ width: '400px', height: '500px',left: 220 }}></div>
      <ReactTooltip>{tooltipContent}</ReactTooltip>
      </>
      </Grid>
    </Grid>
  </CardContent>
</Card>

   
</>;
  }
  
  
  export default WMap;  

  