import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SplineLineChart from "./LineChart";
import axios from "axios";
import { base_url, overall_chart, today_chart } from "../utils/utils";
import Cookies from "js-cookie";
import moment from "moment";
const ApexGraph = ({ op, city, selectedDateRange, monthFalcon }) => {
  const [chart, setChart] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isErr, setIsErr] = useState(false);
  const token = sessionStorage.getItem("token");

  const getOverallData = () => {
    setIsLoading(true);
    let apiData;

    if (op == "overall") {
      apiData = base_url + overall_chart;
    } else if (op == "today") {
      apiData = base_url + today_chart;
    }
    const tokenData = token;
    const dataToken = {
      headers: {
        "x-access-token": `${tokenData}`,
      },
      params: {
        city: city,
        // from: moment(selectedDateRange[0]).format("YYYY-MM-DD"),
        // to: moment(selectedDateRange[1]).format("YYYY-MM-DD"),
        month: monthFalcon,
        year: "2023",
      },
    };

    axios
      .get(apiData, dataToken)
      .then((res) => {
        setChart(res.data);
        setIsLoading(false);
      })
      .catch((err) => setIsErr(true))
      .finally(() => setIsLoading(false));
  };

  React.useEffect(() => {
    getOverallData();
  }, []);
  return isLoading ? (
    <>...loading</>
  ) : (
    <div>
      <Box>
        <Card
          xs={12}
          md={5}
          //   sx={{ width: "561px", height: "435px", borderRadius: "10px" }}
          variant="outlined"
          style={{
            backgroundColor: "#32355F",
            borderRadius: "10px",
            // height: "570px",
          }}
        >
          <CardContent>
            {chart?.length != 0 && <SplineLineChart chart={chart} name={op} />}
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default ApexGraph;
